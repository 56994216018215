import Model, { attr, belongsTo } from '@ember-data/model';
import type WebhookNotificationModel from 'tnt-ui/models/webhook-notification';

export default class WebhookNotificationLogModel extends Model {
  @belongsTo('webhook-notification', { async: false }) declare webhookNotification: WebhookNotificationModel;

  @attr('string') declare url: string;
  @attr('string') declare succeeded: string;
  @attr('string') declare error: string;
  @attr('string') declare statusCode: string;
  @attr('string') declare requestBody: string;
  @attr('string') declare requestHeaders: string;
  @attr('string') declare responseBody: string;
  @attr('string') declare responseHeaders: string;
  @attr('date') declare createdAt: Date;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'webhook-notification-log': WebhookNotificationLogModel;
  }
}
