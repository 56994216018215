import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  refNumber: {
    validators: [validator('presence', { presence: true, description: 'This field' }), validator('ds-error')],
  },
  steamshipLineScac: {
    validators: [validator('presence', { presence: true, description: 'This field' }), validator('ds-error')],
  },
});
