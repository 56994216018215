import { helper } from '@ember/component/helper';

export function oxfordComma([array] /*, hash*/) {
  if (array === null || array === undefined || array.length === 0) {
    return '';
  }
  if (array.length === 1) {
    return array[0];
  }
  if (array.length === 2) {
    return array.join(' and ');
  }

  return array.slice(0, -1).join(', ') + ', and ' + array[array.length - 1];
}

export default helper(oxfordComma);
