import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import { dasherize } from '@ember/string';
import type AccountModel from 'tnt-ui/models/account';
import type MetabaseDashboardModel from 'tnt-ui/models/metabase-dashboard';
import { toJSON } from 'tnt-ui/utils/toJSON';

export default class AccountMetabaseDashboardModel extends Model {
  // Relationships
  @belongsTo('account') declare account: AccountModel;
  @belongsTo('metabase-dashboard') declare metabaseDashboard: AsyncBelongsTo<MetabaseDashboardModel>;

  @attr('string') declare iframeUrl: string;
  @attr('string') declare label: string;

  get path() {
    return dasherize(this.label).toLowerCase();
  }

  toJSON(): Record<string, any> {
    return toJSON(this);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'account-metabase-dashboard': AccountMetabaseDashboardModel;
  }
}
