import Model, { attr, hasMany, type AsyncHasMany } from '@ember-data/model';
import type PortModel from 'tnt-ui/models/port';

export default class RegionModel extends Model {
  @hasMany('port') declare ports: AsyncHasMany<PortModel>;

  @attr('string') declare name: string;
  @attr('string') declare displayName: string;
  @attr('string') declare location: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    region: RegionModel;
  }
}
