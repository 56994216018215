import Model, { attr, belongsTo } from '@ember-data/model';
import { dependentKeyCompat } from '@ember/object/compat';
import type AccountModel from 'tnt-ui/models/account';
import type ShipmentModel from 'tnt-ui/models/shipment';

export default class ShipmentParticipantModel extends Model {
  @belongsTo('shipment', { async: false }) declare shipment: ShipmentModel;
  @belongsTo('account', { async: false }) declare account: AccountModel;

  @attr('boolean') declare archivedShipment: boolean;
  @attr('date') declare createdAt: Date;

  @dependentKeyCompat
  get companyType() {
    return this.account?.companyType;
  }

  @dependentKeyCompat
  get isTrucker() {
    return this.account?.isTrucker;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'shipment-participant': ShipmentParticipantModel;
  }
}
