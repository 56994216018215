import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  number: {
    validators: [validator('presence', { presence: true }), validator('ds-error')],
  },
  shippingLine: {
    validators: [validator('presence', { presence: true }), validator('ds-error')],
  },
  customer: {
    validators: [validator('presence', { presence: true }), validator('ds-error')],
  },
});
