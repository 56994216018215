import type ModelRegistry from 'ember-data/types/registries/model';
import type Model from '@ember-data/model';
import type StoreService from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { all } from 'rsvp';

export default class CollectionService extends Service {
  @service declare store: StoreService;

  @tracked preloadedModels: (keyof ModelRegistry)[] = [];

  @action
  preloadAll(models: { model: keyof ModelRegistry; parent?: any; query?: any }[]) {
    return all(
      models.map(({ model, parent, query }) => {
        return this.preloadOne({ model, parent, query });
      }),
    );
  }

  @action
  preloadOne({ model, parent, query }: { model: keyof ModelRegistry; parent?: any; query?: any }) {
    this.preloadedModels.addObject(model);
    return parent ? parent.get(model) : this.store.query(model, { ...query });
  }

  @action
  query(model: keyof ModelRegistry, predicate: (model: Model) => boolean) {
    return this.getAll(model).filter(predicate);
  }

  @action
  getAll(model: keyof ModelRegistry) {
    return this.store.peekAll(model).toArray();
  }
}
