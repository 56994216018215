import { isPresent } from '@ember/utils';
import V2ApiSerializer from './v2-api';

export default class RouteSerializer extends V2ApiSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (isPresent(payload.included)) {
      payload.included.forEach((modelData) => {
        if (modelData.type === 'vessel') {
          modelData.type = 'vessel-v2';
        }

        if (modelData.type === 'route_location') {
          const inbound_vessel = modelData.relationships?.inbound_vessel;
          const outbound_vessel = modelData.relationships?.outbound_vessel;

          if (inbound_vessel?.data) {
            inbound_vessel.data.type = 'vessel-v2';
          }
          if (outbound_vessel?.data) {
            outbound_vessel.data.type = 'vessel-v2';
          }
        }
      });
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
