import { inject as service } from '@ember/service';
import SegmentAdapter from 'ember-metrics/metrics-adapters/segment';

export default class T49Segment extends SegmentAdapter {
  @service session;

  toStringExtension() {
    return 't49-segment';
  }

  /**
   * `trackEvent` is for sending group track event from segment to posthog.
   *
   * @param Object options - `event` is required
   **/
  trackEvent(options) {
    const currentAccount = this.session.currentUser?.currentAccount;
    const currentAccountId = currentAccount?.id;

    if (currentAccountId) {
      const $groups = {
        account: currentAccountId,
      };

      const billingAccount = currentAccount?.belongsTo('billingAccount');
      const billingAccountId = billingAccount?.id();

      if (billingAccountId) {
        $groups['billingAccount'] = billingAccountId;
      }

      options = Object.assign(options, { $groups });
    }

    super.trackEvent(options);
  }
}
