/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { filterBy, not, notEmpty } from '@ember/object/computed';
import moment from 'moment-timezone';
import { hasStatus, usedAs } from '../utils/decorators';
import Validations from '../utils/validations/booking-order';

export const PickAndDrop = 'pick_and_drop',
  LiveUnload = 'live_unload',
  deliveryTypes = [PickAndDrop, LiveUnload];

const statusNames = ['draft', 'submitted', 'accepted', 'rejected', 'in_progress', 'completed', 'delivered', 'canceled'];
const bookingTypes = ['import', 'export', 'landbridge'];

@usedAs(['subjectable', 'inviteable', 'attachable', 'messageable'])
@hasStatus(statusNames, 'currentStatus')
@hasStatus(bookingTypes, 'bookingType')
@hasStatus(deliveryTypes, 'deliveryType')
export default class BookingOrderModel extends Model.extend(Validations) {
  @belongsTo('account', { async: false }) creator;
  @belongsTo('account', { async: false }) customer;
  @belongsTo('port', { async: false }) port;
  @belongsTo('location', { polymorphic: true, async: false }) origin;
  @belongsTo('location', { polymorphic: true, async: false }) destination;
  @belongsTo('address', { async: false }) billTo;
  @belongsTo('rate', { async: true }) rate; // TODO see if I can make this false
  @belongsTo('shipment', { async: false }) shipment;

  @hasMany('activity') activities;
  @hasMany('document') documents;
  @hasMany('invite') invites;
  @hasMany('cargo') cargos;
  @hasMany('booking-participant') bookingParticipants;
  @hasMany('invoice') invoices;
  @hasMany('offer') offers;
  @hasMany('account') relatedTruckingCompanies;
  @hasMany('rate') relatedCustomerRates;
  @hasMany('rate') relatedPartnerRates;
  @hasMany('payout') payouts;
  @hasMany('charge') charges;

  @attr('string', { defaultValue: 'import' }) bookingType;
  @attr('string') refNumber;
  @attr('array', { defaultValue: () => [] }) referenceNumbers;
  @attr('string') deliveryType;
  @attr('string') currentStatus;
  @attr('string') currentStatusAt;
  @attr('string') notes;
  @attr('date') createdAt;
  @attr('string', { defaultValue: () => ({}) }) rateDetails;
  @attr('string') timeZone;
  @attr('boolean', { defaultValue: false }) assignContainers;

  @notEmpty('rate.id') hasRate;
  @not('isNew') isSaved;

  // Invoice Related CPs
  @notEmpty('invoices') hasInvoices;
  @filterBy('invoices', 'unPaid') unPaidInvoices;

  @computed('unPaidInvoices.[]')
  get someInvoicesUnPaid() {
    return this.unPaidInvoices.length > 0;
  }
  @computed('unPaidInvoices.[]', 'invoices.[]')
  get allInvoicesPaid() {
    return this.invoices.length > 0 && this.unPaidInvoices.length === 0;
  }

  @filterBy('bookingParticipants', 'isTrucker') assignedCarriers;

  @computed('isImport', 'origin', 'destination')
  get dispatchAddress() {
    return this.isImport ? this.destination : this.origin;
  }

  @computed('cargos.@each.isSaved')
  get hasCargo() {
    return this.cargos.isAny('isSaved');
  }

  @computed('createdAt')
  get isNewOrder() {
    let createdDate = moment(this.createdAt);
    let now = moment();
    return now.diff(createdDate, 'hours') < 48;
  }

  isArchived(account) {
    const accountId = account && account.id;
    return this.bookingParticipants.filterBy('account.id', accountId).isAny('archivedOrder');
  }
}
