/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import DatetimeComponent from 'tnt-ui/components/containers-new/datetime/index';

export default class LFDComponent extends DatetimeComponent {
  get isPast() {
    return this.parsedDatetime?.isBefore(this.today, 'day');
  }

  get isToday() {
    return this.parsedDatetime?.isSame(this.today, 'day');
  }

  get isFuture() {
    return this.parsedDatetime?.isAfter(this.today, 'day');
  }

  get diffDays() {
    return this.parsedDatetime?.diff(this.today, 'day', true);
  }

  get isWithinADay() {
    return this.diffDays > 0 && this.diffDays < 1;
  }

  get isTomorrow() {
    return this.isFuture && this.diffDays >= 1 && this.diffDays < 2;
  }

  get isAfterTomorrow() {
    return this.isFuture && this.diffDays >= 2;
  }

  // 1. If the date is Today or in the past — highlight cell red
  // 2. If the date is Tomorrow — Highlight lighter red
  // 3. If the date is > 2 days — highlight orange
  get styles() {
    if (this.isToday || this.isPast || this.isWithinADay) {
      return 'tw-bg-red-500 tw-text-white';
    }

    if (this.isTomorrow) {
      return 'tw-bg-red-100 tw-text-black';
    }

    if (this.isAfterTomorrow) {
      return 'tw-bg-yellow-500 tw-text-white';
    }

    return '';
  }
}
