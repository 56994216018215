/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { compare, isPresent } from '@ember/utils';

/**
 * @param {CargoModel} cargo
 * @param {Function} [canEditUpdates]
 * @param {Sting} [portTimeZone]
 */
export default class CargoAdditionalDetails extends Component {
  @service session;

  get isAdmin() {
    return this.session.currentUser?.isAdmin;
  }

  get cargoShipmentEvents() {
    const { cargo } = this.args;
    const cargoShipmentEvents = cargo.get('cargoShipmentEvents')?.toArray() ?? [];
    return cargoShipmentEvents.sort((a, b) => compare(b.get('index'), a.get('index')));
  }

  get shippingLineEvents() {
    return this.cargoShipmentEvents.filter((event) => event.dataSource === 'shipping_line');
  }

  get railEvents() {
    return this.cargoShipmentEvents.filter((event) => event.dataSource === 'rail');
  }

  get allContainerEvents() {
    const { cntnr: container } = this.args.cargo;
    const terminalContainerUpdates = container?.get('terminalContainerUpdates')?.toArray() ?? [];
    return terminalContainerUpdates.sort((a, b) => compare(b.get('eventTimestamp'), a.get('eventTimestamp')));
  }

  get containerEvents() {
    return this.allContainerEvents.filter((event) => !event.isNew);
  }

  get lastEvent() {
    return this.containerEvents.firstObject;
  }

  get tareWeight() {
    const { tareWeight } = this.args.cargo;
    if (isPresent(tareWeight)) {
      return `${tareWeight} lbs`;
    } else {
      return '-';
    }
  }

  get weight() {
    const { weight } = this.args.cargo;
    if (isPresent(weight)) {
      return `${weight} lbs`;
    } else {
      return '-';
    }
  }
}
