/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-computed-properties-in-native-classes, ember/no-get */
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { hasStatus } from '../utils/decorators';
import Validations from '../utils/validations/charge';

export const BaseRate = 'base_rate',
  RegularChassisRate = 'regular_chassis_rate',
  HeavyChassisRate = 'heavy_chassis_rate',
  PrePullRate = 'pre_pull_rate',
  rateCategories = [BaseRate, RegularChassisRate, HeavyChassisRate, PrePullRate],
  Draft = 'draft',
  UnderReview = 'under_review',
  Approved = 'approved',
  Rejected = 'rejected',
  statusNames = [Draft, UnderReview, Approved, Rejected];

@hasStatus(statusNames, 'currentStatus')
@hasStatus(rateCategories, 'rateCategory')
export default class ChargeModel extends Model.extend(Validations) {
  @attr('string') containerNumber;
  @attr('string') rateCategory;
  @attr('string') description;
  @attr('number') unitPrice;
  @attr('number') quantity;
  @attr('string') unit;
  @attr('number', { defaultValue: 0 }) amount;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('string', { defaultValue: Draft }) currentStatus;

  @belongsTo('cargo', { async: false }) cargo;
  @belongsTo('payout', { async: false }) payout;
  @belongsTo('user') user;

  @computed('currentStatus', 'isBaseRate', 'payout.unApproved')
  get editable() {
    if (this.isBaseRate) {
      return false;
    }
    return this.get('payout.unApproved') && [Rejected, UnderReview].includes(this.currentStatus);
  }

  @computed('isUnderReview', 'isBaseRate')
  get approvable() {
    return !this.isBaseRate && this.isUnderReview;
  }
}
