/* eslint-disable ember/no-computed-properties-in-native-classes */

import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { equal, not, notEmpty } from '@ember/object/computed';
import { underscore } from '@ember/string';
import { apiAction } from '@mainmatter/ember-api-actions';
import { TrackedObject } from 'tracked-built-ins';
import Validations from '../utils/validations/account';

export const Trucker = 'trucking_company',
  Broker = 'customs_broker',
  Forwarder = 'freight_forwarder',
  Shipper = 'shipper',
  Terminal49 = 'terminal_49',
  Contractor = 'contractor',
  SoftwareCompany = 'software_company',
  partyLogistic = 'party_logistic';

const flags = {
  apiAccessPaused: 'boolean',
  apiAccess: 'boolean',
  autoArchive: 'boolean',
  calculateLfd: 'boolean',
  containerAdmin: 'boolean',
  containerSerializerProvidedData: 'boolean',
  containerUpdatedIncludeLocation: 'boolean',
  containers: 'boolean',
  customRequestAwaitingManifestRetryInHours: 'number',
  customRequestSidekiqExpireIn: 'number',
  deliveryOrders: 'boolean',
  marineTrafficVesselEvents: 'boolean',
  needsAttention: 'boolean',
  overrideWhlcTracking: 'boolean',
  products: 'boolean',
  setupDelivery: 'boolean',
  strictBlLookup: 'boolean',
  trackAndTrace: 'boolean',
  userAccessPaused: 'boolean',
  verifiedAtdAtaOnly: 'boolean',
  railData: 'boolean',
};

export const AccountFlags = Object.keys(flags).map((key) => underscore(key));

export default class AccountModel extends Model.extend(Validations) {
  @belongsTo('payment-method') defaultPaymentMethod;
  @belongsTo('billing-account', { inverse: 'accounts' }) billingAccount;

  @hasMany('booking-order', { inverse: null }) bookingOrders;
  @hasMany('user') users;
  @hasMany('invite') invites;
  @hasMany('activity') activities;
  @hasMany('payment-method') paymentMethods;
  @hasMany('payout', { inverse: 'truckingAccount' }) payouts;
  @hasMany('rate') rates;
  @hasMany('address') addresses;
  @hasMany('requirement') requirements;
  @hasMany('product') products;
  @hasMany('product-dimension') productDimensions;
  @hasMany('region') regions;
  @hasMany('account') customers;
  @hasMany('api-key') apiKeys;
  @hasMany('account-metabase-dashboard') accountMetabaseDashboards;

  @attr('string') domain;
  @attr('string') companyName;
  @attr('string') companyType;
  @attr('boolean') primary;
  @attr('boolean') paidAccount;
  @attr('number') annualContainerVolume;

  @attr('string') scac;
  @attr('string') usdot;
  @attr('string') dba;

  @attr('string') street1;
  @attr('string') street2;
  @attr('string') city;
  @attr('string') state;
  @attr('string') stateAbbr;
  @attr('number') zip;
  @attr('string') country;
  @attr('string') contactName;
  @attr('string') contactPhone;
  @attr('string') abbrName;
  @attr('string') contactEmail;
  @attr('boolean') isPayable;
  @attr('boolean') optInAutomatically;
  @attr('date') createdAt;
  @attr('date') closedAt;
  @attr('boolean') grantedPoa;
  @attr('array', { defaultValue: () => [] }) shipmentTags;
  @attr('array', { defaultValue: () => [] }) embedDomains;

  // Meta fields
  @attr('date') billingStartedAt;
  @attr('string') additionalCharges;
  @attr('string') stripeChargeToken;

  @attr('tracked-object', { defaultValue: () => new TrackedObject({}) }) flags;

  @equal('companyType', Trucker) isTrucker;
  @equal('companyType', Forwarder) isForwarder;
  @equal('companyType', Broker) isBroker;
  @equal('companyType', Shipper) isShipper;
  @equal('companyType', Terminal49) isAdmin;
  @not('isTrucker') notTrucker;

  @notEmpty('account') isCustomer;
  @notEmpty('regions') hasRegions;
  @notEmpty('customers') hasCustomers;
  @notEmpty('rates') t49DrayageCustomer;

  async stopShipmentTracking() {
    return await apiAction(this, {
      method: 'POST',
      path: 'stop_shipment_tracking',
    });
  }

  async close() {
    return await apiAction(this, {
      method: 'POST',
      path: 'close',
    });
  }

  @computed('companyType')
  get canHaveContainerAdminFlag() {
    return [Contractor, Terminal49].includes(this.companyType);
  }

  get availableFlags() {
    return Object.entries(flags).reduce((acc, [name, type]) => {
      // Same attribute serialization as in app/utils/toJSON.ts
      let value = type === 'boolean' ? Boolean(this.flags[name]) : this.flags[name];
      acc[name] = value ?? null;

      return acc;
    }, {});
  }

  isFlagEnabled(flagName) {
    return this.availableFlags[flagName];
  }

  async queryCustomers(q) {
    let link = this.hasMany('customers').link();
    if (link) {
      return this.store.query('account', { accountId: this.id, ...q });
    } else {
      return [];
    }
  }

  async addCustomer(companyName) {
    const adapter = this.store.adapterFor('account');
    const payload = await adapter.addCustomer(this, companyName);
    this.store.pushPayload(payload);
    return this.store.peekRecord('account', payload.data.id);
  }
}
