import { isBlank } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

export default class Phone extends BaseValidator {
  validate(value, options) {
    if (options.allowBlank && isBlank(value)) {
      return true;
    } else {
      let regex =
        /^([+]?1\s*[-/.]?\s*)?(\((\d{3})\)|(\d{3}))\s*[-/.]?\s*(\d{3})\s*[-/.]?\s*(\d{4})\s*(([xX]|[eE][xX][tT]?[.]?|extension)\s*([#*\d]+))*$/;
      if (!regex.test(value)) {
        return 'Invalid phone number';
      }
      return true;
    }
  }
}
