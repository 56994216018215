/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import currentSession from 'tnt-ui/decorators/current-session';

@currentSession
export default class CargoActionNavComponent extends Component {
  @tracked copiedId = false;

  @action
  copyId() {
    navigator.clipboard.writeText(this.args.cargo.id);
    this.copiedId = true;
  }
}
