/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ContainersBolComponent extends Component {
  @service router;
  @service modals;

  get shipmentURL() {
    const { row } = this.args;
    return this.router.urlFor('shipments.details', row.get('shipment.id'));
  }

  @action
  onClick(e) {
    if (e.metaKey || e.ctrlKey) {
      return;
    }

    e.preventDefault();
    const { viewId, isAnonymized, row } = this.args;

    this.modals.open('container-details', {
      viewId: viewId,
      isAnonymized: isAnonymized,
      shipmentId: row.get('shipment.id'),
      selectedContainer: null,
      fromShipment: true,
    });

    this.args.trackEvent({
      event: 'shipment_details_open_from_shipment',
    });
  }

  @action
  addToSearch() {
    this.args.addToSearch(this.args.columnId, this.args.value);
  }

  @action
  copyToClipboard() {
    const { value } = this.args;

    navigator.clipboard.writeText(value);

    this.args.trackEvent({
      event: 'copy_to_clipboard',
      value: value,
    });
  }
}
