import { isArray } from '@ember/array';
import { isEmpty } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

class ArrayNotEmpty extends BaseValidator {
  validate(value, options) {
    if (isEmpty(value) || !isArray(value)) {
      if (options && options['description']) {
        return options['description'] + " can't be blank";
      }
      return 'Required Field';
    }
    return true;
  }
}

ArrayNotEmpty.reopenClass({
  getDependentsFor(attribute) {
    return [`model.${attribute}.[]`];
  },
});

export default ArrayNotEmpty;
