/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { equal, not } from '@ember/object/computed';
import { usedAs } from 'tnt-ui/utils/decorators';
import Validations from 'tnt-ui/utils/validations/invite';

export const TeamMember = 'team_member';
export const BookingParticipant = 'booking_participant';

@usedAs(['subjectable'])
export default class InviteModel extends Model.extend(Validations) {
  @belongsTo('account') account;
  @belongsTo('user') user;
  @belongsTo({ polymorphic: true }) inviteable;

  @attr('string') name;
  @attr('string') email;
  @attr('boolean') accepted;
  @attr('string') inviteType;
  @attr('string') context;

  @equal('inviteType', TeamMember) byTeam;
  @not('accepted') notAccepted;
}
