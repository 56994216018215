import { inject as service } from '@ember/service';
import BaseSessionService from 'ember-simple-auth/services/session';

export default class SessionService extends BaseSessionService {
  @service sessionSetup;
  @service metrics;

  async handleAuthentication() {
    try {
      await this.sessionSetup._loadCurrentUser();
      let currentUser = this.currentUser;
      if (currentUser) {
        this.metrics.trackEvent(Object.assign({ event: 'Sign In' }, currentUser.toJSON()));
      }
      if (this.currentAccount) {
        super.handleAuthentication(...arguments);
      }
    } catch (err) {
      await this.invalidate();
    }
  }
}
