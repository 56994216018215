import { InitSentryForEmber } from '@sentry/ember';

export function startSentry() {
  InitSentryForEmber({
    beforeSend(event, hint) {
      const error = hint.originalException;
      // ignore aborted route transitions from the Ember.js router
      if (error && error.name === 'TransitionAborted') {
        return null;
      }
      return event;
    },
  });
}
