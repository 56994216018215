/* import __COLOCATED_TEMPLATE__ from './container-details.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, setProperties } from '@ember/object';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { isTesting } from '@embroider/macros';
import { task } from 'ember-concurrency';
import { MapDataLoader } from 'tnt-map';
import config from 'tnt-ui/config/environment';
import currentSession from 'tnt-ui/decorators/current-session';

const SHIPMENT_INCLUDES =
  'pickup_rail_carrier,documents,documents.activities,customer.product_dimensions,cargos,cargos.cargo_products,cargos.cargo_shipment_events,cargos.cargo_shipment_events.vessel,cargos.cargo_shipment_events.location,cargos.cargo_products.product,cargos.cntnr,cargos.cntnr.container_updates,creator,customer,port_of_lading,port_of_discharge,pod_terminal,port_of_discharge.terminals,destination,destination_terminal,shipping_line,voyage,voyage.vessel,shipment_participants,shipment_participants.account,line_tracking_stopped_by_user';

@currentSession
export default class ModalsContainerDetailsComponent extends Component {
  @service store;
  @service modals;
  @service router;
  @service metrics;
  @service log;
  @service notify;
  @service session;
  @service featureFlags;
  @service localStorage;

  @tracked shipment;
  @tracked isMapExpanded = this.localStorage.readItem('containerDetails.isMapExpanded') ?? true;
  @tracked noteToRemove;
  @tracked noteToEdit;

  @tracked currentTab = 'overview';
  @tracked comment = '';

  mapDataLoader = new MapDataLoader(
    {
      apiHost: isTesting() ? location.origin : config.APP.apiHost,
      maptilerKey: config.APP.MAPTILER_MAP_KEY,
      authToken: `Bearer ${this.session.data.authenticated.access_token}`,
      accountId: this.session.data.current_account_id,
      viewSource: 'shipment_details_sidebar',
      showAttribution: false,
    },
    {
      modals: this.modals,
      metrics: this.metrics,
    },
  );

  get sortedShipmentContainerNotes() {
    return this.loadAllShipmentContainerNotesTask.lastSuccessful?.value.sortBy('createdAt') || [];
  }

  get sortedShipmentNotes() {
    return this.loadShipmentNotesTask.lastSuccessful?.value.sortBy('createdAt') || [];
  }

  get selectedContainerNotes() {
    const id = this.selectedContainer?.id;

    if (this.sortedShipmentContainerNotes) {
      return this.sortedShipmentContainerNotes.filter((note) => {
        return note.get('notable.id') === id;
      });
    } else {
      return [];
    }
  }

  get allNotesCount() {
    if (this.args.content.fromShipment) {
      return this.sortedShipmentNotes.length + this.sortedShipmentContainerNotes.length;
    } else {
      return this.sortedShipmentNotes.length + this.selectedContainerNotes.length;
    }
  }

  unknownProperty(key) {
    return this.args[key] || this.args.content?.[key];
  }

  @action
  onClose() {
    this.modals.close('container-details');
  }

  @action
  toggleMapExpanded() {
    this.isMapExpanded = !this.isMapExpanded;
    this.localStorage.writeItem('containerDetails.isMapExpanded', this.isMapExpanded);
  }

  @task
  *loadShipment() {
    const useAnonymousEndpoint = !this.currentUser;
    const { share_id } = this.router.currentRoute.params;
    const shipment = yield this.store.findRecord('shipment', this.args.content.shipmentId, {
      reload: true,
      include: SHIPMENT_INCLUDES,
      adapterOptions: { useAnonymousEndpoint, shareId: share_id },
    });
    const shipmentV2 = this.store.peekRecord('shipment-v2', this.args.content.shipmentId);
    const voyage = shipment.voyage;
    const userIsAdmin = this.currentUser?.isAdmin;
    const currentAccount = this.currentAccount;
    const userIsOwner = currentAccount == shipment.customer || currentAccount == shipment.creator;
    const selectedContainer = this.args.content.selectedContainer || shipmentV2.containers.firstObject;
    const viewId = this.args.content.viewId;

    setProperties(this, {
      shipment,
      shipmentV2,
      voyage,
      userIsAdmin,
      userIsOwner,
      currentAccount,
      selectedContainer,
    });

    try {
      yield this.mapDataLoader.loadShipmentDetailsTask.perform(shipment.id, {
        containerId: this.selectedContainer.id,
        viewSource: 'shipment_details_sidebar',
      });
    } catch (error) {
      console.error("Couldn't load shipmentDetails \n" + error);
    }

    if (this.args.content.fromContainer) {
      this.trackEvent({
        event: 'container_details_open',
        view_id: viewId,
        shipment_id: shipment.id,
        container_id: selectedContainer?.id,
      });
    } else if (this.args.content.fromShipment) {
      this.trackEvent({
        event: 'shipping_details_open',
        view_id: viewId,
        shipment_id: shipment.id,
        container_id: selectedContainer?.id,
      });
    }

    if (isPresent(this.session.data.current_account_id) && this.featureFlags.isEnabled('containerNotes')) {
      this.loadAllShipmentContainerNotesTask.perform(shipmentV2);
      this.loadShipmentNotesTask.perform(shipmentV2);
    }
  }

  @action
  selectTab(tab) {
    this.currentTab = tab.id;
  }

  @action
  trackEvent(attrs) {
    this.metrics.trackEvent({
      ...attrs,
      source: 'Container Dashboard',
    });
  }

  @task
  *loadAllShipmentContainerNotesTask(shipment) {
    let shipmentNotes = [];
    for (const id of shipment.containers.mapBy('id')) {
      try {
        const notes = yield this.store.query('note', {
          filter: { notable_id: id, notable_type: 'Cargo' },
        });
        notes.forEach((note) => shipmentNotes.push(note));
      } catch (error) {
        this.log.error(`Couldn't load Notes for Cargo: ${id} \n`, error);
      }
    }

    return shipmentNotes;
  }

  @task
  *loadShipmentNotesTask(shipment) {
    const id = shipment.id;
    try {
      const notes = yield this.store.query('note', {
        filter: { notable_id: id, notable_type: 'Shipment' },
      });

      return notes;
    } catch (error) {
      this.log.error(`Couldn't load Notes for Shipment: ${id} \n`, error);
      return [];
    }
  }

  @task
  *addNoteTask(notable, comment) {
    this.comment = comment?.trim();

    if (!this.comment) {
      this.notify.error('Note is too short.');
      return;
    }

    try {
      yield this.store
        .createRecord('note', {
          body: this.comment,
          notable,
        })
        .save();

      this.notify.success('Added note');
      this.loadAllShipmentContainerNotesTask.perform(this.shipmentV2);
      this.loadShipmentNotesTask.perform(this.shipmentV2);
    } catch (error) {
      this.log.error('Error while creating a note.', error);
      this.notify.error('Error while creating a note.');
    }

    this.comment = '';
  }

  @task
  *saveNoteTask(note, comment) {
    const commentTrimmed = comment?.trim();

    if (!commentTrimmed) {
      this.notify.error('Note is too short.');
      return;
    }

    try {
      note.body = commentTrimmed;
      yield note.save();

      this.notify.success('Saved note.');
    } catch (error) {
      this.log.error('Error while saving the note.', error);
      this.notify.error('Error while saving the note.');
      note.rollback();
    }

    this.noteToEdit = null;
  }

  @action
  openContainerDetailsModal(container, shipment) {
    this.modals.close('container-details');
    this.modals.open('container-details', {
      viewId: this.args.content.viewId,
      isAnonymized: this.args.content.isAnonymized,
      shipmentId: shipment.get('id'),
      selectedContainer: container,
      fromContainer: true,
    });
  }

  @action
  openShipmentDetailsModal(_container, shipment) {
    this.modals.close('container-details');
    this.modals.open('container-details', {
      viewId: this.args.content.viewId,
      isAnonymized: this.args.content.isAnonymized,
      shipmentId: shipment.get('id'),
      selectedContainer: null,
      fromShipment: true,
    });
  }

  @action
  openShipmentPage() {
    window.open(window.location.origin + '/shipments/' + this.shipment.id, '_blank');
  }

  @task
  *deleteNoteTask(note) {
    try {
      yield note.destroyRecord();
      this.noteToRemove = null;
      this.notify.success('Note deleted.');
      this.loadAllShipmentContainerNotesTask.perform(this.shipmentV2);
      this.loadShipmentNotesTask.perform(this.shipmentV2);
    } catch (error) {
      this.log.error('Error while deleting note.', error);
      this.notify.success('Error while deleting note.');
    }
  }
}
