import fetch from 'fetch';
import V2ApiAdapter from './v2-api';

export default class ShipmentV2Adapter extends V2ApiAdapter {
  buildURL(modelName, id, snapshot, requestType, query) {
    return super.buildURLV2(modelName, id, snapshot, requestType, query);
  }

  async stopTracking(shipment) {
    const response = await fetch(`${this.buildURL('shipment', shipment.id)}/stop_tracking`, {
      method: 'PATCH',
      headers: {
        ...this.headers,
      },
    });

    const data = await response.json();

    if (this.isSuccess(response.status)) {
      this.store.pushPayload('shipment', data);
    } else {
      throw data.errors[0]?.detail;
    }
  }

  async resumeTracking(shipment) {
    const response = await fetch(`${this.buildURL('shipment', shipment.id)}/resume_tracking`, {
      method: 'PATCH',
      headers: {
        ...this.headers,
      },
    });

    const data = await response.json();

    if (this.isSuccess(response.status)) {
      this.store.pushPayload('shipment', data);
    } else {
      throw data.errors[0]?.detail;
    }
  }
}
