import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import moment from 'moment-timezone';
import type PortModel from 'tnt-ui/models/port';
import type VesselModel from 'tnt-ui/models/vessel';

export default class VoyageModel extends Model {
  @belongsTo('vessel', { async: false }) declare vessel: VesselModel;
  @belongsTo('port', { async: false }) declare destination: PortModel;
  @hasMany('shipment') declare shipments: any;

  @attr('string') declare voyageNumber: string;
  @attr('date') declare polOriginalEtdAt: Date;
  @attr('date') declare polEtdAt: Date;
  @attr('date') declare polAtdAt: Date;
  @attr('date') declare podEtaAt: Date;
  @attr('date') declare podOriginalEtaAt: Date;
  @attr('date') declare podAtaAt: Date;
  @attr('date') declare destinationEtaAt: Date;
  @attr('date') declare destinationOriginalEtaAt: Date;
  @attr('date') declare destinationAtaAt: Date;
  @attr('date') declare lastStatusRefreshAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('date') declare createdAt: Date;

  get daysToETA() {
    if (this.podEtaAt) {
      const currentDate = moment().startOf('day');
      const etaDate = moment(this.podEtaAt).startOf('day');
      return etaDate.diff(currentDate, 'days');
    }
  }

  get destinationCurrentArrival() {
    return this.destinationAtaAt || this.destinationEtaAt;
  }

  get podCurrentArrival() {
    return this.podAtaAt || this.podEtaAt;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    voyage: VoyageModel;
  }
}
