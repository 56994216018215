/* eslint-disable ember/no-mixins */
import Helper from '@ember/component/helper';
import { humanize } from 'tnt-ui/helpers/humanize';
import { DocumentCategories } from 'tnt-ui/mixins/document-categories';

export default class DocumentCategoryLabel extends Helper {
  compute([category]) {
    const returnCategory = DocumentCategories.findBy('key', category);
    if (returnCategory !== undefined) {
      return returnCategory.label;
    }
    if (category !== undefined && category !== null && category !== '') {
      return humanize([category]);
    }
    return 'Other';
  }
}
