/* eslint-disable ember/no-computed-properties-in-native-classes, ember/require-computed-property-dependencies, ember/use-brace-expansion */

import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { service } from '@ember/service';
import { computed } from '@ember/object';
import ENV from 'tnt-ui/config/environment';

export default class Application extends JSONAPIAdapter {
  @service session;
  @service log;

  handleResponse(status, _headers, payload, _requestData) {
    if (status === 401 && this.session.get('isAuthenticated')) {
      // Throw an error to prevent the store from processing the response
      throw new Error('Unauthorized', payload);
    }
    return super.handleResponse(...arguments);
  }

  host = ENV.APP.apiHost;
  // @TODO apiNamespace has value `/api/v1` while api/v1 is expected.
  // This causes ember-data to make requests to //api/v1/users instead of /api/v1/users
  namespace = ENV.APP.apiNamespace.startsWith('/') ? ENV.APP.apiNamespace.substring(1) : ENV.APP.apiNamespace;

  @computed('session.data.authenticated.token', 'session.data.current_account_id')
  get headers() {
    const headers = {};
    if (this.session.isAuthenticated) {
      headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
      headers['X-Account-ID'] = this.session.get('data.current_account_id');
    }
    return headers;
  }
}
