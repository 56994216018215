/* import __COLOCATED_TEMPLATE__ from './shipment-item.hbs'; */
import Component from '@glimmer/component';
import { type AsyncHasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import type ShipmentModelV2 from 'tnt-ui/models/shipment-v2';

interface VesselMapShipmentItemComponentSignature {
  Element: HTMLDivElement;
  Args: {
    shipment: ShipmentModelV2;
    vesselName: string;
    onClickVesselName: (shipment: ShipmentModelV2) => void;
  };
}

// Container does not have typedef yet
type Container = ShipmentModelV2['containers'] extends AsyncHasMany<infer U> ? U : never;

export default class VesselMapShipmentItemComponent extends Component<VesselMapShipmentItemComponentSignature> {
  @tracked isContainersExpanded = false;

  get containers(): Container[] {
    return this.args.shipment.containers.toArray();
  }

  toggleContainers = () => {
    this.isContainersExpanded = !this.isContainersExpanded;
  };

  onClickVesselName = () => {
    this.args.onClickVesselName(this.args.shipment);
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'VesselMap::ShipmentItem': typeof VesselMapShipmentItemComponent;
  }
}
