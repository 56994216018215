import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  account: validator('presence', { presence: true, description: 'Trucker' }),
  address: validator('presence', { presence: true, description: 'Address' }),
  port: validator('presence', { presence: true, description: 'Port' }),
  customer: validator('presence', { presence: true, description: 'Customer' }),
  liveUnloadRate: validator('presence', {
    presence: true,
    description: 'Live Unload Rate',
  }),
  pickDropRate: validator('presence', {
    presence: true,
    description: 'Pick Drop Rate',
  }),
});
