/* eslint-disable prettier/prettier */
import Service, { service } from '@ember/service';
import ENV from 'tnt-ui/config/environment';
import FetchService from 'tnt-ui/services/fetch';

export default class HubspotService extends Service {
  @service declare fetch: FetchService;

  updateUser(userId: string) {
    const namespace = ENV.APP.apiNamespace;
    const url = `${namespace}/users/${userId}/update_hubspot`;
    return this.fetch.post(url);
  }
}
