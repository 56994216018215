/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import BaseInput from 'tnt-ui/components/forms/base-input';

export default class TextArea extends BaseInput {
  @action
  setTextAreaProperty(event) {
    this.setProperty(this.model, this.prop, event.target.value);
  }
}
