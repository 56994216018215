import Model, { attr, belongsTo } from '@ember-data/model';

export default class PaymentMethodModel extends Model {
  @belongsTo({ inverse: 'paymentMethods' }) account;
  @belongsTo user;

  @attr('string') token;
  @attr('string') paymentType;
  @attr('string') last4;
  @attr('boolean') validated;
  @attr({ defaultValue: () => ({}) }) details; // Could be credit card or bank account details
  @attr('date') createdAt;
}
