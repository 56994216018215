/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr, belongsTo, hasMany, type AsyncBelongsTo, type AsyncHasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';
import { mapBy, sum } from '@ember/object/computed';
import type CargoModel from 'tnt-ui/models/cargo';
import type TerminalModel from 'tnt-ui/models/terminal';
import { isEmptyObject } from 'tnt-ui/utils/object-functions';
// @ts-ignore
import { TrackedArray } from 'tracked-built-ins/.';

export type FeeObject = {
  type: (typeof FeeTypes)[keyof typeof FeeTypes];
  amount: number;
};

export const FeeTypes = {
  Total: 'total',
  Exam: 'exam',
  Demurrage: 'demurrage',
  Other: 'other',
};

export const CONTAINER_TYPES = [
  { v: 'Dry', k: 'dry' },
  { v: 'Reefer', k: 'reefer' },
  { v: 'Flat Rack', k: 'flat_rack' },
  { v: 'Open Top', k: 'open_top' },
  { v: 'Tank', k: 'tank' },
];

export const IMPORT_CONTAINER_HEIGHTS = [
  { v: 'Standard', k: 'standard' },
  { v: 'High Cube', k: 'high_cube' },
];

export const CONTAINER_LENGTHS = [
  { v: "20'", k: 20 },
  { v: "24'", k: 24 },
  { v: "40'", k: 40 },
  { v: "45'", k: 45 },
  { v: "48'", k: 48 },
  { v: "53'", k: 53 },
];

export const OnShip = 'On ship',
  NotAvailable = 'Not Available',
  Available = 'Available',
  OffDock = 'Off Dock',
  PickedUp = 'Picked up';

export default class CntnrModel extends Model {
  // Relationships
  @belongsTo('booking') declare booking: AsyncBelongsTo<any>;
  @belongsTo('terminal') declare terminal: AsyncBelongsTo<TerminalModel>;
  @hasMany('cargo') declare cargo: AsyncHasMany<CargoModel>;
  @hasMany('container-update') declare containerUpdates: AsyncHasMany<any>;

  @attr('tracked-array', { defaultValue: () => new TrackedArray([]) }) declare holds: TrackedArray<FeeObject>;
  @attr('tracked-array', { defaultValue: () => new TrackedArray([]) }) declare fees: TrackedArray<FeeObject>;

  @attr('string') declare status: string;
  @attr('date') declare lastRefresh: Date;
  @attr('string') declare number: string;
  @attr('date') declare lastFreeDayOn: Date;
  @attr() declare additionalData: any;

  @dependentKeyCompat
  get hasHolds() {
    return this.holds?.length > 0;
  }

  @dependentKeyCompat
  get hasFees() {
    return this.fees?.length > 0;
  }

  get hasAdditionalInformation() {
    return !isEmptyObject(this.additionalData);
  }

  @computed('containerUpdates.@each.source')
  get terminalContainerUpdates() {
    return this.containerUpdates.filter((update: any) =>
      ['terminal', 'emodal', 'User', 'Manual update'].includes(update.get('source')),
    );
  }

  @computed('containerUpdates.[]')
  get lastTerminalUpdateTimestampAt() {
    const terminalUpdates = this.containerUpdates.filter((update: any) =>
      [OnShip, NotAvailable, Available, OffDock, PickedUp].includes(update.get('status')),
    );
    const timestamps = terminalUpdates.map((update: any) => {
      return update.get('eventTimestamp');
    });
    const maxTimestamp = timestamps.reduce(function (acc, timestamp) {
      if (timestamp > acc) {
        return timestamp;
      }
      return acc;
    }, 0);
    if (maxTimestamp == 0) {
      return null;
    }
    return maxTimestamp;
  }

  get mayMoveOffDock() {
    return this.hasAdditionalInformation && this.additionalData['may-move-offdock'];
  }

  @mapBy('fees', 'amount') declare feeAmounts: number[];
  @sum('feeAmounts') declare totalFees: number;

  get isInTerminal() {
    return [OnShip, NotAvailable, Available].includes(this.status);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    cntnr: CntnrModel;
  }
}
