/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-get */
import Service, { inject as service } from '@ember/service';
import { debug } from '@ember/debug';
import { isPresent } from '@ember/utils';
import { Promise } from 'rsvp';

export default class TrackingRequestService extends Service {
  @service appSocket;
  @service session;
  @service store;

  channel = null;
  pendingTrackingRequests = [];

  subscribeToTrackingRequest(trackingRequestId) {
    let trackingRequest = this.buildTrackingRequestPromise(trackingRequestId);

    this.pendingTrackingRequests.pushObject(trackingRequest);

    if (!this.channel) {
      this.registerChannel();
    }

    return trackingRequest.promise;
  }

  buildTrackingRequestPromise(trackingRequestId) {
    let resolver, rejector;
    let promise = new Promise((resolve, reject) => {
      resolver = resolve;
      rejector = reject;
    });

    let trackingRequestPromise = {
      trackingRequestId: trackingRequestId,
      promise: promise,
      resolver: resolver,
      rejector: rejector,
    };

    return trackingRequestPromise;
  }

  receiveTrackingRequest(payload) {
    let parsedPayload = JSON.parse(payload.response);

    let trackingRequestPromise = this.pendingTrackingRequests.find(
      (promise) => promise.trackingRequestId === parsedPayload.data.id,
    );

    if (isPresent(trackingRequestPromise)) {
      let normalizedTrackingRequest = this.normalizeTrackingRequest(parsedPayload);
      this.store.push(normalizedTrackingRequest);

      this.pendingTrackingRequests.removeObject(trackingRequestPromise);

      const trackingRequest = this.store.peekRecord('tracking-request', parsedPayload.data.id);

      return trackingRequestPromise.resolver(trackingRequest);
    }
  }

  registerChannel() {
    const authToken = this.get('session.data.authenticated.access_token');
    let connection = this.appSocket.setupSocket(authToken);

    let channel = connection.subscriptions.create(
      {
        channel: 'TrackingRequestChannel',
        account_id: this.session.currentUser.currentAccount.id,
      },
      {
        connected: () => {
          debug('TrackingRequest:Connected');
        },
        received: (payload) => {
          if (isPresent(payload.response)) {
            this.receiveTrackingRequest(payload);
          }
          debug(payload);
        },
        disconnected: () => debug('TrackingRequest:disconnected'),
      },
    );
    this.set('channel', channel);
  }

  normalizeTrackingRequest(payload) {
    const serializer = this.store.serializerFor('tracking-request');
    const modelClass = this.store.modelFor('tracking-request');

    return serializer.normalizeResponse(this.store, modelClass, payload, payload.data.id, 'findRecord');
  }
}
