/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/no-classic-components, ember/require-tagless-components */
import Component from '@ember/component';
import { action } from '@ember/object';

export default class SelectAddress extends Component {
  showErrors = false;
  disabled = false;
  afterSet = null;

  @action
  addressChanged(value) {
    const { model, prop } = this;
    this.setProperty(model, prop, value);
    (this.afterSet || (() => {}))(model, prop, value);
  }
}
