export default {
  CONTAINER_LENGTHS: [20, 24, 40, 45, 48, 53],
  CONTAINER_TYPES: ['dry_standard', 'flat_rack', 'open_top'],
  CONTAINER_HEIGHTS: ['standard', 'high_cube'],
  DELIVERY_TYPES: ['live_unload', 'pick_and_drop'],
  RATE_DETAILS: [
    { key: 'liveUnloadRate', label: 'Live unload rate' },
    { key: 'pickDropRate', label: 'Pick and drop rate' },
    { key: 'regularChassisRate', label: 'Regular Chassis', unit: 'day' },
    { key: 'heavyChassisRate', label: 'Heavy Haul Chassis', unit: 'day' },
    { key: 'customExamRate', label: 'Custom Exam' },
    { key: 'waitTerminalRate', label: 'Terminal Wait Time', unit: 'hour' },
    { key: 'waitConsigneeRate', label: 'Consignee Wait Time', unit: 'hour' },
    { key: 'tollRate', label: 'Toll Fee' },
    { key: 'ssaUsoakFeeRate', label: 'SSA Gate Fee (Oakland, CA)' },
    { key: 'offOnHireRate', label: 'Off Hire / On Hire' },
    { key: 'residentialDeliveryRate', label: '' },
    { key: 'scaleTicketRate', label: 'Scale Ticket', unit: 'ticket' },
    { key: 'storageRate', label: 'Storage', unit: 'day' },
  ],
};
