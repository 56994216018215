import { set } from '@ember/object';
import CookieStore from 'ember-simple-auth/session-stores/cookie';

export default class ApplicationSessionStore extends CookieStore {
  constructor() {
    super(...arguments);

    set(this, 'cookieName', 'terminal49-session');
    set(this, 'cookieExpirationTime', 7776000);
  }
}
