import BaseValidator from 'ember-cp-validations/validators/base';

function allDependent(options, model) {
  if (options && (options.dependentKeys || []).length > 0) {
    return options.dependentKeys.every((x) => {
      return model.get(x.split('.').slice(1).join('.'));
    });
  } else {
    return true;
  }
}

export default class Boolean extends BaseValidator {
  validate(value, options = {}, model) {
    if (allDependent(options, model)) {
      if (typeof value === 'boolean') {
        return true;
      } else {
        return options.message || false;
      }
    }
    return true;
  }
}
