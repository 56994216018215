/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-get */
import Service, { inject as service } from '@ember/service';
import { debug } from '@ember/debug';
import { isBlank } from '@ember/utils';
import { Promise } from 'rsvp';

export default class ShipmentTrackerService extends Service {
  @service appSocket;
  @service session;
  channel = null;
  tnt_demo = false;
  searches = [];

  performSearch = (searchObject) => {
    this.channel.perform('search', {
      number: searchObject.number,
      scac: searchObject.scac,
      tnt_demo: this.tnt_demo,
      timestamp: searchObject.searchedAt,
    });
  };

  search = (number, scac) => {
    number = number.replace(/\s/g, '').toUpperCase();
    let resolver, rejector;
    let promise = new Promise((resolve, reject) => {
      resolver = resolve;
      rejector = reject;
    });
    let searchObject = {
      number: number,
      scac: scac,
      ran: false,
      promise: promise,
      resolver: resolver,
      rejector: rejector,
      searchedAt: null,
    };

    this.searches.pushObject(searchObject);

    if (!this.channel) {
      this.registerChannel();
    } else {
      searchObject.ran = true;
      searchObject.searchedAt = new Date().getTime();
      this.performSearch(searchObject);
    }

    return searchObject.promise;
  };

  registerChannel = () => {
    const authToken = this.get('session.data.authenticated.access_token');
    let connection = this.appSocket.setupSocket(authToken);

    let channel = connection.subscriptions.create('ShipmentTrackerChannel', {
      connected: () => {
        debug('ShipmentTracker:Connected');
        let searchObject = this.searches.find((search) => isBlank(search.searchedAt));
        // After registering, WS can reconnect in browser - so don't assume
        // there is a searchObject execute
        if (searchObject != null) {
          searchObject.searchedAt = new Date().getTime();
          this.performSearch(searchObject);
        }
      },
      received: (data) => {
        let query = this.searches.find(
          (search) => search.number === data.number && search.searchedAt == data.searched_at,
        );
        if (data.result) {
          query.resolver(data.result);
        } else {
          query.rejector(data.error);
        }
        debug(data);
      },
      disconnected: () => debug('ShipmentTracker:disconnected'),
    });
    this.set('channel', channel);
  };
  _search;
}
