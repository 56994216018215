/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-computed-properties-in-native-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, equal } from '@ember/object/computed';
import Validations from '../utils/validations/rate';

class RateModel extends Model.extend(Validations) {
  @belongsTo('account', { async: false }) account;
  @belongsTo('address', { async: false }) address;
  @belongsTo('port', { async: false }) port;
  @belongsTo('account', { async: false, inverse: null }) customer;
  @belongsTo('rate', { async: false, inverse: null }) nextRate;

  /* These 5 are will be deprecated soon (rateType will become a CP) */
  @attr('string') rateType;
  @attr('string') origin;
  @attr('string') originZip;
  @attr('string') destination;
  @attr('string') destinationZip;

  @attr('string') notes;

  @attr('date') createdAt;

  @attr('number') liveUnloadRate;
  @attr('number') pickDropRate;
  @alias('pickDropRate') pickAndDropRate;
  @attr('number') heavyLoadSurchargeRate;
  @attr('number') reeferRate;
  @attr('number') flatRackRate;
  @attr('number') openTopRat;
  @attr('number') tankRate;
  @attr('number') regularChassisRate;
  @attr('number') heavyChassisRate;
  @attr('number') chassisIncludedRate;
  @alias('chassisIncludedRate') heavyChassisIncludedRate;
  @alias('chassisIncludedRate') regularChassisIncludedRate;
  @attr('number') customExamRate;
  @attr('number') waitTerminalRate;
  @attr('number') waitConsigneeRate;
  @attr('number') waitTerminalIncludedRate;
  @attr('number') waitConsigneeIncludedRate;
  @attr('number') prePullRate;
  @attr('number') storageRate;
  @attr('number') storageIncludedRate;
  @attr('number') tollRate;
  @attr('number') ssaUsoakFeeRate;
  @attr('number') offOnHireRate;
  @attr('number') residentialDeliveryRate;
  @attr('number') scaleTicketRate;
  @attr('number') bobTailRate;
  @equal('rateType', 'custom_customer') editable;

  @computed('nextRate')
  get rateChanges() {
    const propertyList = [
      'liveUnloadRate',
      'pickDropRate',
      'heavyLoadSurchargeRate',
      'reeferRate',
      'flatRackRate',
      'openTopRate',
      'tankRate',
      'regularChassisRate',
      'heavyChassisRate',
      'chassisIncludedRate',
      'heavyChassisIncludedRate',
      'regularChassisIncludedRate',
      'customExamRate',
      'waitTerminalRate',
      'waitConsigneeRate',
      'waitTerminalIncludedRate',
      'waitConsigneeIncludedRate',
      'prePullRate',
      'storageRate',
      'storageIncludedRate',
      'tollRate',
      'ssaUsoakFeeRate',
      'offOnHireRate',
      'residentialDeliveryRate',
      'scaleTicketRate',
      'bobTailRate',
    ];
    if (this.nextRate == null) {
      return null;
    }
    let changedProperties = {};
    let currentProperties = this.getProperties(propertyList);
    let nextRateProperties = this.nextRate.getProperties(propertyList);
    propertyList.forEach((key) => {
      let [before, after] = [currentProperties[key], nextRateProperties[key]];
      if (this.ratesDiffer(before, after) && !this.ratesEmpty(before, after)) {
        changedProperties[key] = { previous: before, current: after };
      }
    });
    return changedProperties;
  }

  ratesDiffer = function (before, after) {
    return before != after;
  };
  ratesEmpty = function (before, after) {
    return (before == null || before == 0) && (after == null || after == 0);
  };
}
// TODO, should be refactored as static methods on Rate
RateModel.reopenClass({
  allRateAttrs() {
    let keys = [],
      isRateKey = /Rate$/;
    this.attributes.forEach(({ name }) => {
      if (isRateKey.test(name)) {
        keys.push(name);
      }
    });
    return keys;
  },
  includedRateAttrs() {
    let keys = this.allRateAttrs().filter((key) => key.match(/IncludedRate$/));
    keys.pushObject('heavyChassisIncludedRate');
    keys.pushObject('regularChassisIncludedRate');
    return keys;
  },
  rateDetails() {
    return [
      { name: 'Live Unload Rate', prefix: '$', attrName: 'liveUnloadRate' },
      { name: 'Pick and Drop rate', prefix: '$', attrName: 'pickDropRate' },
      {
        name: 'Heavy Load Surcharge',
        prefix: '$',
        attrName: 'heavyLoadSurchargeRate',
      },
      { name: 'Regular Chassis', prefix: '$', attrName: 'regularChassisRate' },
      { name: 'Heavy Haul Chassis', prefix: '$', attrName: 'heavyChassisRate' },
      {
        name: 'Chassis Days Included',
        postfix: 'days',
        attrName: 'chassisIncludedRate',
      },
      {
        name: 'Terminal Wait Time Rate',
        prefix: '$',
        postfix: 'per hour',
        attrName: 'waitTerminalRate',
      },
      {
        name: 'Wait Time Included',
        postfix: 'hours',
        attrName: 'waitTerminalIncludedRate',
      },
      {
        name: 'Consignee Wait Time Rate',
        prefix: '$',
        postfix: 'per hour',
        attrName: 'waitConsigneeRate',
      },
      {
        name: 'Wait Time Included',
        postfix: 'hours',
        attrName: 'waitConsigneeIncludedRate',
      },
      { name: 'Custom Exam Rate', prefix: '$', attrName: 'customExamRate' },
      {
        name: 'Residential Delivery Rate',
        prefix: '$',
        attrName: 'residentialDeliveryRate',
      },
      {
        name: 'Storage Rate',
        prefix: '$',
        postfix: 'per day',
        attrName: 'storageRate',
      },
      {
        name: 'Storage Included',
        postfix: 'days',
        attrName: 'storageIncludedRate',
      },
      {
        name: 'Scale Ticket Fee',
        prefix: '$',
        postfix: 'per ticket',
        attrName: 'scaleTicketRate',
      },
      { name: 'Pre-pull Rate', prefix: '$', attrName: 'prePullRate' },
      { name: 'Bob-tail Rate', prefix: '$', attrName: 'bobTailRate' },
      { name: 'On/Off Hire Rate', prefix: '$', attrName: 'offOnHireRate' },
      { name: 'SSA Gate Fee (Oak)', prefix: '$', attrName: 'ssaUsoakFeeRate' },
      { name: 'Toll Fee', prefix: '$', attrName: 'tollRate' },
    ];
  },
});

export default RateModel;
