import { helper } from '@ember/component/helper';

export function tntIncludes([left, right]) {
  if (!(left && right)) {
    return false;
  }
  return left.includes(right);
}

export default helper(tntIncludes);
