import { alias, bool, not } from '@ember/object/computed';
import { buildValidations, validator } from 'ember-cp-validations';
import { validate } from 'ember-validators';

// Massive hack to get rid of ember-data-model-fragments
export const contacts = validator('inline', {
  dependentKeys: ['model.contacts.@each.email', 'model.contacts.@each.phone'],
  validate(value, _options, _model, _attribute) {
    for (const contact of value) {
      let emailValidationResult = validate('format', contact.email, {
        type: 'email',
        allowBlank: true,
        message: 'Invalid email',
      });

      let phoneValidationResult = validate('format', contact.phone, {
        type: 'phone',
        allowBlank: true,
      });

      if (emailValidationResult !== true || phoneValidationResult !== true) {
        contact.errors = {
          validations: {
            attrs: {},
          },
        };

        contact.errors.errorsFor = (attr) => {
          return contact.errors.validations.attrs[attr]?.messages.map((message) => ({ message }));
        };

        if (emailValidationResult !== true) {
          contact.errors.validations.attrs.email = {
            messages: [emailValidationResult.context.message],
          };
        }

        if (phoneValidationResult !== true) {
          contact.errors.validations.attrs.phone = {
            messages: ['Invalid phone number'],
          };
        }
      } else {
        delete contact.errors;
      }
    }

    return value.map((contact) => contact.errors).filter(Boolean).length === 0;
  },
});
export const companyName = validator('presence', {
  presence: true,
  description: 'Company name',
});
export const line1 = validator('presence', {
  presence: true,
  description: 'Line1',
});
export const city = validator('presence', {
  presence: true,
  description: 'City',
});
export const stateAbbr = validator('presence', {
  presence: true,
  description: 'State',
  disabled: not('model.isDomestic'),
});
export const state = validator('presence', {
  presence: true,
  description: 'State',
  disabled: bool('model.isDomestic'),
});
export const zip = [
  validator('presence', {
    presence: true,
    domestic: alias('model.isDomestic'),
    get description() {
      return this.domestic ? 'Zip' : 'Post code';
    },
  }),
  validator('format', {
    value: (model, attr) => String(model.get(attr)),
    regex: /^\d{5}$/,
    message: 'Zip code must be 5 digits',
    disabled: not('model.isDomestic'),
  }),
];
export const deliveryHoursTo = validator('time', {
  allowBlank: true,
});
export const deliveryHoursFrom = [
  validator('time', {
    allowBlank: true,
  }),
  validator('time-range', {
    allowBlank: true,
    dependentKeys: ['model.deliveryHoursTo'],
    message: 'Delivery hours must be an earlier time to a later one',
  }),
];
export default buildValidations({
  contacts,
  companyName,
  line1,
  city,
  state,
  stateAbbr,
  zip,
  deliveryHoursFrom,
  deliveryHoursTo,
});
