import ApplicationAdapter from './application';

export default class CargoAdapter extends ApplicationAdapter {
  urlForQuery(query) {
    if (query.requiring_attention) {
      delete query.requiring_attention;
      return `${this.urlPrefix()}/cargos/requiring_attention?per_page=${query.per_page}&page=${query.page}`;
    }

    return super.urlForQuery(...arguments);
  }
}
