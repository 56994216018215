/* import __COLOCATED_TEMPLATE__ from './vessel-map.hbs'; */
import type RouterService from '@ember/routing/router-service';
import Component from '@glimmer/component';
import type StoreService from '@ember-data/store';
import { service } from '@ember/service';
import { next } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { isTesting } from '@embroider/macros';
import { didCancel, task } from 'ember-concurrency';
import type MetricsService from 'ember-metrics/services/metrics';
import L from 'leaflet';
import { Journey, MapDataLoader, type Shipment, type Universe } from 'tnt-map';
import { completedVesselJourney, projectedFutureJourneyPath } from 'tnt-map/native/geojson/vessel-journey';
import config from 'tnt-ui/config/environment';
import type ShipmentModelV2 from 'tnt-ui/models/shipment-v2';
import type VesselV2Model from 'tnt-ui/models/vessel-v2';
import type ModalsService from 'tnt-ui/services/modals';
import type SessionService from 'tnt-ui/services/session';

const { APP } = config;

interface VesselMapComponentSignature {
  Element: HTMLDivElement;
  Args: {
    selectedVesselId: string | null;
    selectedShipmentId: string | null;
    onChangeSelection: (selection: { vesselId: string | null; shipmentId: string | null }) => void;
  };
  Blocks: {};
}

export default class VesselMapComponent extends Component<VesselMapComponentSignature> {
  @service declare store: StoreService;
  @service declare session: SessionService;
  @service declare modals: ModalsService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;

  @tracked isExpanded: boolean = true;
  @tracked vessels: VesselV2Model[] = [];
  @tracked shipments: ShipmentModelV2[] = [];
  @tracked multiverse: Array<{
    shipment: Shipment;
    journey: Journey;
    universe: Universe;
  }> = [];

  config = {
    showAttribution: false,
    maptilerKey: APP.MAPTILER_MAP_KEY,
  };

  leafletMap: L.Map | null = null;

  didInitMap = (map: L.Map) => {
    this.leafletMap = map;

    // @ts-ignore
    window.leafletMap = map; // debug
    // @ts-ignore
    window.addDebugMarker = ({ latitude, longitude, positions }) => {
      const _posistions = positions ?? [{ latitude, longitude }];
      for (const { latitude, longitude } of _posistions) {
        // L.circle([latitude, longitude], { radius: 20000, pane: 'tooltipPane' }).addTo(map);
        L.marker([latitude, longitude]).addTo(map);
      }
    };
  };

  didResizeMap = () => this.leafletMap?.invalidateSize();

  get mapDataLoaderConfig() {
    return {
      disableAnalytics: true,
      apiHost: isTesting() ? location.origin : APP.apiHost,
      maptilerKey: APP.MAPTILER_MAP_KEY,
      authToken: `Bearer ${this.session.data.authenticated.access_token}`,
      accountId: this.session.data.current_account_id,
      showAttribution: false,
    };
  }

  get selectedVessel(): VesselV2Model | void {
    return this.vessels.find((v) => v.id === this.args.selectedVesselId);
  }

  get shipmentsForSelectedVessel(): ShipmentModelV2[] {
    if (!this.selectedVessel) {
      return this.shipments;
    }

    return this.shipments.filter((shipment) => {
      // We know that we have this.selectedVessel, and we don't mutate its state in the `filter`
      // function call, but TS doesn't.
      if (this.selectedVessel) {
        return shipment.podVesselImo === this.selectedVessel.get('imo');
      }
    });
  }

  get vesselsWithShipments(): VesselV2Model[] {
    return this.vessels.filter((vessel) => {
      return this.shipments.toArray().some((shipment) => {
        return shipment.podVesselImo === vessel.get('imo');
      });
    });
  }

  get isLoading(): boolean {
    return this.loadVesselsTask.isRunning || this.loadShipmentTask.isRunning;
  }

  constructor(owner: unknown, args: VesselMapComponentSignature['Args']) {
    super(owner, args);

    this.loadVesselsTask
      .perform()
      .then(() => {
        const { selectedVesselId, selectedShipmentId } = this.args;
        this.handleSelectionChange(null, [selectedVesselId, selectedShipmentId]);
      })
      .catch((error) => {
        if (!didCancel(error)) {
          throw error;
        }
      });
  }

  handleSelectionChange = (_element: HTMLElement | null, [vesselId, shipmentId]: [string | null, string | null]) => {
    this.loadShipmentTask.cancelAll();

    this.multiverse = [];

    if (this.selectedVessel) {
      const shipment =
        this.shipmentsForSelectedVessel.find((s) => s.id === shipmentId) ?? this.shipmentsForSelectedVessel[0];

      if (shipment) {
        // Update the URL with the shipmentId if missing
        if (!this.args.selectedShipmentId) {
          // While the expectation here is to wire up the selection to query params with
          // `transitionTo`, which doesn't require runloop scheduling, if we test the component in
          // isolation, we need to schedule to the next runloop to avoid the following error:
          //
          // > You attempted to update `selectedShipmentId` on `(unknown object)`, but it had
          // > already been used previously in the same computation.  Attempting to update a value
          // > after using it in a computation can cause logical errors, infinite revalidation bugs,
          // > and performance issues, and is not supported.
          next(() => {
            this.args.onChangeSelection({
              vesselId,
              shipmentId: shipment.id,
            });
          });

          return;
        }

        this.loadShipmentTask.perform(shipment);
      } else {
        this.fitBounds([[this.selectedVessel.latitude, this.selectedVessel.longitude]]);
      }
    } else {
      this.fitBoundsForAllVessels();
    }
  };

  selectVessel = (vessel: VesselV2Model) => {
    const { id } = vessel;
    const shipmentId = this.getShipmentsForVessel(vessel.id)?.[0]?.id ?? null;

    this.args.onChangeSelection({
      vesselId: id,
      shipmentId,
    });
  };

  clearVessel = () => {
    this.args.onChangeSelection({
      vesselId: null,
      shipmentId: null,
    });
  };

  selectShipment = (shipment: ShipmentModelV2) => {
    let vesselId = this.args.selectedVesselId;

    if (this.selectedVessel?.imo !== shipment.podVesselImo) {
      const vessel = this.getVesselByImo(shipment.podVesselImo);
      // We should have the vessel, because we only show shipments for vessels that we have (TS doesn't know that though)
      if (vessel) {
        vesselId = vessel.id;
      }
    }

    this.args.onChangeSelection({
      vesselId,
      shipmentId: shipment.id,
    });
  };

  loadVesselsTask = task(async () => {
    const shipmentsQuery = this.store.query('shipment-v2', {
      include: 'containers',
      filter: {
        actively_tracking: true,
      },
    });

    const vesselsQuery = this.store.query('vessel-v2', {
      filter: {
        account_id: this.session.data.current_account_id,
        actively_tracking: true,
      },
    });

    try {
      const [shipments, vessels] = await Promise.all([shipmentsQuery, vesselsQuery]);

      this.shipments = shipments.toArray();

      // This is poor man's SQL query to filter down to vessels that have shipments
      this.vessels = vessels.filter((vessel) => {
        return this.shipments.some((shipment) => shipment.podVesselImo === vessel.get('imo'));
      });

      this.fitBoundsForAllVessels();
    } catch (error) {
      console.trace(error);
    }
  });

  loadShipmentTask = task({ restartable: true }, async (shipmentV2: ShipmentModelV2) => {
    const containerId = shipmentV2.containers.firstObject?.id;

    if (!containerId) {
      console.error(
        'Could not find container ID for shipment, which is required for MapDataLoader. Shipment ID: ',
        shipmentV2.id,
      );

      return;
    }

    const mapDataLoader = new MapDataLoader(this.mapDataLoaderConfig as any, {
      modals: this.modals,
      metrics: this.metrics,
    });

    const mapDataLoaderResult = await mapDataLoader.loadShipmentDetailsTask.perform(shipmentV2.id, {
      containerId,
    });

    if (!mapDataLoaderResult) {
      console.error('Could not load data for shipment using MapDataLoader. Shipment ID: ', shipmentV2.id);
      return;
    }

    // This isn't a shipment-v2 model, but a simple object the map dels with, hence the variable
    // name. Follow the type declaration for details.
    const { shipmentV2: shipmentData, journey, universe } = mapDataLoaderResult;

    universe.distributeJourneyPointsBetweenWorlds();

    if (universe.journeyPointCrossing?.positionsData.isCrossingAntiMeridian) {
      universe.worlds.forEach((world) => (world.offset -= 360));
    }

    const pathCoordinates: L.LatLngTuple[] = universe.journey.journeyPoints
      .reduce((acc: L.LatLngTuple[], journeyPoint) => {
        if (journeyPoint.isLast) {
          return acc;
        }

        const completedTracks = journeyPoint.vesselJourney?.hasPositions
          ? completedVesselJourney(journeyPoint).geojson.geometry.coordinates
          : [];

        const projectedPathBetweenPoints = projectedFutureJourneyPath(journeyPoint).geojson.geometry.coordinates;

        return [...acc, ...completedTracks, ...projectedPathBetweenPoints] as L.LatLngTuple[];
      }, [])
      .map(([latitude, longitude]) => [longitude, latitude]);

    this.multiverse = [
      {
        shipment: shipmentData,
        journey,
        universe,
      },
    ];

    this.fitBounds(pathCoordinates);
  });

  // TODO: we can't get vessels by their ID, because we can't use `include=voyage.vessel` for a
  // `shipment-v2`, even though it's a relationship
  getVesselByImo = (imo: string) => {
    return this.vessels.find((v) => v.imo === imo);
  };

  getShipmentsForVessel(vesselId: string): ShipmentModelV2[] {
    const vessel = this.vessels.find((v) => v.id === vesselId);
    return this.shipments.toArray().filter((shipment) => {
      return shipment.podVesselImo === vessel!.get('imo');
    });
  }

  fitBoundsForAllVessels = () => {
    const latitudes = this.vessels.map((v) => v.latitude);
    const longitudes = this.vessels.map((v) => v.longitude);

    if (latitudes.length && longitudes.length) {
      const bounds: L.LatLngTuple[] = [
        [Math.min(...latitudes), Math.min(...longitudes)],
        [Math.max(...latitudes), Math.max(...longitudes)],
      ];

      this.fitBounds(bounds);
    }
  };

  fitBounds = (coordinates: L.LatLngTuple[]) => {
    const topBound = Math.max(...coordinates.map((c) => c[0]));
    const leftBound = Math.min(...coordinates.map((c) => c[1]));
    const bottomBound = Math.min(...coordinates.map((c) => c[0]));
    const rightBound = Math.max(...coordinates.map((c) => c[1]));

    const bounds: L.LatLngTuple[] = [
      [topBound, leftBound],
      [bottomBound, rightBound],
    ];

    const padding = 40;

    // TODO: this should be based on the current viewport, because VesselDetails can span from 176 to 288px
    const paddingRight = this.selectedVessel ? 290 + padding * 2 : padding;

    this.leafletMap?.flyToBounds(bounds, {
      maxZoom: 4,
      paddingTopLeft: [padding, padding],
      paddingBottomRight: [paddingRight, padding],
    });
  };
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    VesselMap: typeof VesselMapComponent;
  }
}
