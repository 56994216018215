/* import __COLOCATED_TEMPLATE__ from './shipments-table.hbs'; */
import Component from '@glimmer/component';
import type ShipmentModelV2 from 'tnt-ui/models/shipment-v2';

interface VesselMapShipmentsTableComponentSignature {
  Element: HTMLDivElement;
  Args: {
    shipments: ShipmentModelV2[];
    onClickVesselName: (shipment: ShipmentModelV2) => void;
  };
}

export default class VesselMapShipmentsTableComponent extends Component<VesselMapShipmentsTableComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'VesselMap::ShipmentsTable': typeof VesselMapShipmentsTableComponent;
  }
}
