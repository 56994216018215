/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import moment from 'moment-timezone';

export default class DateTime extends Component {
  get today() {
    const { row, meta } = this.args;

    if (isPresent(meta?.timezone)) {
      return moment.tz(row.get(meta.timezone));
    } else {
      return moment();
    }
  }

  get parsedDatetime() {
    const { row, value, meta } = this.args;

    if (isPresent(value)) {
      let date;

      if (isPresent(meta?.timezone)) {
        date = moment.tz(value, row.get(meta.timezone));
      } else {
        date = moment(value);
      }

      return date;
    } else {
      return null;
    }
  }

  get formattedDatetime() {
    if (this.parsedDatetime) {
      const { meta } = this.args;

      if (meta?.format) {
        return this.parsedDatetime.format(meta.format);
      }

      return this.parsedDatetime.format('M/D/YYYY');
    } else {
      return null;
    }
  }
}
