import { isEmpty } from '@ember/utils';

export default {
  ISOCheck(cntrNum) {
    var num = 0;
    var charCode = '0123456789A?BCDEFGHIJK?LMNOPQRSTU?VWXYZ';
    if (!cntrNum || cntrNum.length !== 11) {
      return false;
    }
    cntrNum = cntrNum.toUpperCase();
    for (var i = 0; i < 10; i++) {
      var chr = cntrNum.substring(i, i + 1);
      var idx = chr === '?' ? -1 : charCode.indexOf(chr);
      if (idx < 0) {
        return false;
      }
      idx = idx * Math.pow(2, i);
      num += idx;
    }
    num = (num % 11) % 10;
    return parseInt(cntrNum.substring(10, 11)) === num;
  },

  parseIds(query, uniqueOnly = true) {
    if (isEmpty(query)) {
      return [];
    }
    let spacedQuery = query
      .trim()
      .toUpperCase()
      .replace(/(\r\n|\n|\r)/gm, ' ');
    const splitRegex = /[ ,|\s]+/;
    let idList = uniqueOnly ? spacedQuery.split(splitRegex).uniq() : spacedQuery.split(splitRegex);
    let parsedIdList = idList.map(function (id) {
      return id.replace(/[^A-Z0-9]+/g, '');
    });
    return parsedIdList.filter(Boolean);
  },
};
