import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class SignOutRoute extends Route {
  @service session;

  beforeModel() {
    localStorage.removeItem('signedInAs');
    this.session.set('data.current_account_id', null);
    return this.session.invalidate();
  }
}
