import { attr, belongsTo, hasMany } from '@ember-data/model';
import { isEqual, isPresent } from '@ember/utils';
import LocationModel from 'tnt-ui/models/location';
import type PortModel from 'tnt-ui/models/port';

export default class TerminalV2Model extends LocationModel {
  @hasMany('booking-order') declare bookingOrders: any;
  @belongsTo('port', { async: false }) declare port: PortModel;

  @attr('string') declare nickname: string;
  @attr('string') declare name: string;
  @attr('string') declare firmsCode: string;
  @attr('string') declare facilityType: string;

  get fullName() {
    return `${this.name} (${this.nickname}) [${this.firmsCode}]`.trim();
  }

  get timeZone() {
    return this.port.timeZone;
  }

  get searchName() {
    const parts = [];

    if (isPresent(this.nickname)) {
      parts.push(this.nickname);
    } else if (isPresent(this.name)) {
      parts.push(this.name);
    }

    if (isPresent(this.firmsCode)) {
      parts.push(this.firmsCode);
    }

    if (isPresent(this.port?.code)) {
      parts.push(this.port?.code);
    }

    return parts.join(' | ');
  }

  get isRail() {
    return isEqual(this.facilityType, 'rail');
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'terminal-v2': TerminalV2Model;
  }
}
