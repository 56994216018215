import Model, { attr, hasMany } from '@ember-data/model';
import { dasherize } from '@ember/string';

export default class ShippingLineModel extends Model {
  @hasMany('shipment') declare shipments: any;

  @attr('string') declare name: string;
  @attr('string') declare bolPrefix: string;
  @attr('string') declare scac: string;
  @attr('string') declare nickname?: string;
  @attr('string') declare trackingUrl: string;
  @attr('string') declare contactUrl: string;
  @attr('boolean') declare isTrackable: string;

  get urlPath() {
    return `${this.scac}-${dasherize(this.nickname || '')}`.toLowerCase();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'shipping-line': ShippingLineModel;
  }
}
