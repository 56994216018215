import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  url: {
    validators: [
      validator('presence', {
        presence: true,
        ignoreBlank: true,
        description: 'URL',
      }),
      validator('url-scheme', {
        message: 'URL must start with https or arn:aws:sns',
      }),
    ],
  },
  events: {
    validators: [
      validator('collection', true),
      validator('length', {
        min: 1,
        message: 'Please select at least one event',
      }),
    ],
  },
});
