import { attr, belongsTo, hasMany } from '@ember-data/model';
import LocationModel from 'tnt-ui/models/location';
import type MetroAreaModel from 'tnt-ui/models/metro-area';
import type PortModel from 'tnt-ui/models/port';

export default class RailTerminalModel extends LocationModel {
  @hasMany('booking-order') declare bookingOrders: any;
  @belongsTo('port', { async: false }) declare port: PortModel;
  @belongsTo('metro-area', { async: false }) declare metroArea: MetroAreaModel;

  @attr('string') declare nickname: string;
  @attr('string') declare name: string;
  @attr('string') declare firmsCode: string;
  @attr('string') declare splc: string;
  @attr('string') declare address: string;
  @attr('string') declare city: string;
  @attr('string') declare zip: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'rail-terminal': RailTerminalModel;
  }
}
