/* import __COLOCATED_TEMPLATE__ from './container-save-form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DialogsContainerSaveFormComponent extends Component {
  @service dialogs;

  get iconClasses() {
    return ['tw-w-5', 'tw-text-gray-300', 'fa-solid', `fa-${this.args.content.containerView.icon ?? 'ship'}`].join(' ');
  }

  get icons() {
    return [
      'location-pin',
      'clock',
      'circle-exclamation',
      'grid',
      'container-storage',
      'ship',
      'train',
      'anchor',
      'clock-rotate-left',
      'backward',
      'circle-check',
      'circle-user',
    ];
  }

  unknownProperty(key) {
    return this.args[key] || this.args?.content[key];
  }

  @action
  resolveAndClose() {
    this.args.content.promise.resolve();
    this.closeDialog();
  }

  @action
  rejectAndClose() {
    this.args.content.promise.reject();
    this.closeDialog();
  }

  @action
  onSave() {
    this.args.content.onSave.perform();
    this.rejectAndClose();
  }

  @action
  loadIcon(element) {
    element.innerHTML = `<i class='${this.iconClasses}' />`;
  }

  @action
  onRollback() {
    const containerView = this.args.content.containerView;

    if (!containerView.isNew) {
      containerView.rollbackAttributes();
    }
    this.resolveAndClose();
  }

  @action
  closeDialog() {
    this.dialogs.close('container-save-form');
  }
}
