/* eslint-disable prettier/prettier */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import AccessService from 'tnt-ui/services/access';

export default class IsVisibleOnlyByAdminHelper extends Helper {
  @service declare access: AccessService;

  compute() {
    return this.access.isAdminOrImpersonating;
  }
}
