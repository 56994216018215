/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/no-classic-components, ember/require-tagless-components */

import Component from '@ember/component';
import { attribute, classNames, tagName } from '@ember-decorators/component';
import { humanize } from 'tnt-ui/helpers/humanize';
import { displayName as terminalDisplayName } from 'tnt-ui/helpers/terminal/display-name';
import { upcase } from 'tnt-ui/helpers/upcase';

const HELPERS = {
  humanize: humanize,
  'terminal/display-name': terminalDisplayName,
  upcase: upcase,
};

/**
 * Had to do my own select box because ember-power-select does not respond to ipad pencil
 * and emberx-select just plain does not work with ember 2.13 and was junk anyway
 *
 * Need custom-select class for bootstrap errors to show properly
 */

@tagName('select')
@classNames('form-control')
export default class SelectBox extends Component {
  @attribute required = false;

  classNameBindings = ['isDisabled:disabled'];

  // If the labelPath happens to be a helper, then setup that helper function in the actions hash to
  // be used in creating the option labels. Please be sure to import the helper and add it to the
  // `HELPER` object, because the lookup is done through imports and isn't dynamic!
  get labelHelper() {
    return HELPERS[this.labelPath];
  }

  // In order to be able to return an actual model as the value, which
  // you can't normally do in option value, be more over the top about
  // getting the value, since you could otherwise do e.target.value and be done
  change() {
    let selectedIndex = this.element.selectedIndex,
      options = this.options.toArray(),
      hasPrompt = !!this.prompt,
      valuePath = this.valuePath,
      actualIndex = hasPrompt ? selectedIndex - 1 : selectedIndex,
      selection = options[actualIndex],
      value = selection;

    if (valuePath && selection) {
      value = selection.get ? selection.get(valuePath) : selection[valuePath];
    }

    return this.onChange(value);
  }
}
