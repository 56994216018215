/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import currentSession from 'tnt-ui/decorators/current-session';
import { Customs, Freight, Other, Tmf, Usda } from 'tnt-ui/schemas/hold';
import { TrackedObject } from 'tracked-built-ins';

const emptyHold = { name: null, status: null };
const emptyFee = { type: null, amount: null };

@currentSession
export default class CargoContainerUpdateFormComponent extends Component {
  @service store;
  @service fetch;
  @service notify;

  currentTime = moment().toDate();
  containerStates = ['Available', 'Not Available', 'On Ship', 'Picked Up', 'Delivered', 'Off Dock', 'Empty Returned'];
  holdNameOptions = [Customs, Usda, Freight, Tmf, Other];
  feeTypeOptions = ['gate fee', 'customs', 'total', 'exam', 'demurrage', 'other'];

  @tracked modalIsOpen = false;
  @tracked containerUpdate;
  @tracked pickedTimestamp;
  @tracked pickedLastFreeDayOn;
  @tracked formError = false;
  @tracked newHoldError = false;
  @tracked newFeeError = false;

  get newUpdate() {
    return this.args.newUpdate ?? true;
  }

  buildNewContainerUpdate() {
    let initialData = { source: 'Manual update' };

    if (this.args.lastEvent) {
      let copiedData = this.args.lastEvent.toJSON();

      initialData.holds = copiedData.holds;
      initialData.fees = copiedData.fees;

      initialData.location = this.args.lastEvent.location;
      initialData.lastFreeDayOn = this.args.lastEvent.lastFreeDayOn;
      initialData.status = this.args.lastEvent.status;
    }

    initialData.cargoId = this.args.cargo.id;

    return this.store.createRecord('containerUpdate', initialData);
  }

  get modalTitle() {
    if (this.newUpdate) {
      return 'Add manual container event';
    }
    return 'Edit container event';
  }

  get buttonLabel() {
    if (this.newUpdate) {
      return 'Add Event';
    }
    return 'Edit';
  }

  get successMessage() {
    if (this.newUpdate) {
      return 'Event created successfully';
    }
    return 'Event saved successfully';
  }

  get portOfDelivery() {
    return this.args.cargo.get('shipment.portOfDischarge.name');
  }

  get terminalOfDelivery() {
    return this.args.cargo.get('shipment.podTerminal.name');
  }

  get validHolds() {
    return this.containerUpdate.holds.every((hold) => hold.name && hold.status);
  }

  get validFees() {
    return this.containerUpdate.fees.every((fee) => fee.type && fee.amount);
  }

  @action
  selectStatus(selection) {
    this.containerUpdate.set('status', selection);
  }

  _reparseTime(dateTime) {
    const timeZone = this.args.cargo.get('podTerminal.timeZone');

    if (timeZone) {
      const timeString = moment(dateTime).format('YYYY-MM-DDTHH:mm');

      return moment.tz(timeString, timeZone).toDate();
    } else {
      return dateTime;
    }
  }

  @action
  setTimestamp(params) {
    this.pickedTimestamp = params[0];
    this.containerUpdate.set('timestamp', this._reparseTime(params[0]));
  }

  @action
  setLastFreeDayOn(params) {
    this.pickedLastFreeDayOn = params[0];
    this.containerUpdate.set('lastFreeDayOn', this._reparseTime(params[0]));
  }

  @action
  selectHoldName(hold, name) {
    hold.name = name;
  }

  @action
  selectFeeType(fee, type) {
    fee.type = type;
  }

  @action
  addHold() {
    let hold = new TrackedObject({ ...emptyHold });
    this.containerUpdate.holds.push(hold);
  }

  @action
  addFee() {
    let fee = new TrackedObject({ ...emptyFee });
    this.containerUpdate.fees.push(fee);
  }

  @action
  removeHold(holdObject) {
    this.containerUpdate.holds.splice(this.containerUpdate.holds.indexOf(holdObject), 1);
  }

  @action
  removeFee(feeObject) {
    this.containerUpdate.fees.splice(this.containerUpdate.fees.indexOf(feeObject), 1);
  }

  @action
  openModal() {
    if (!this.containerUpdate) {
      this.containerUpdate = this.buildNewContainerUpdate();
    } else {
      if (this.containerUpdate.timestamp) {
        this.pickedTimestamp = this.containerUpdate.timestamp;
      } else {
        this.pickedTimestamp = this.containerUpdate.createdAt;
      }
    }
    this.modalIsOpen = true;
  }

  @action
  closeModal(doSave = false) {
    if (doSave) {
      if (this.containerUpdate.get('validations.isValid')) {
        this.formError = false;
      } else {
        this.formError = true;
        return;
      }

      if (this.validHolds) {
        this.newHoldError = false;
      } else {
        this.newHoldError = true;
        return;
      }

      if (this.validFees) {
        this.newFeeError = false;
      } else {
        this.newFeeError = true;
        return;
      }

      this.submitForm();
    }

    this.modalIsOpen = false;
  }

  @action
  async markAsNoChanges() {
    this.args.cargo.set('lastStatusRefreshAt', 'NOW');

    try {
      await this.args.cargo.save();
      this.notify.success('Marked as no changes to cargo status.');
      this.containerUpdate = null;
    } catch (error) {
      this.notify.error('Unable to save event.');
    }

    this.modalIsOpen = false;
  }

  @action
  async submitForm() {
    try {
      await this.containerUpdate.save();
      this.store.findRecord('cargo', this.args.cargo.id, {
        reload: true,
        include: 'cntnr',
      });
      this.notify.success(this.successMessage);
      this.containerUpdate = null;
    } catch (error) {
      this.notify.error('Unable to save event.');
    }
  }
}
