/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import currentSession from 'tnt-ui/decorators/current-session';

@currentSession
export default class PlatformNotificationNavbarComponent extends Component {
  @service sessionSetup;
  @service router;

  @tracked showActivityBar = false;
  @tracked isLoading = false;
  @tracked recentActivities = null;

  get isMobileNavbarView() {
    return this.args.isMobileNavbarView ?? false;
  }

  get unreadActivitiesCount() {
    return this.currentUser?.unreadActivitiesCount;
  }

  @action
  async toggleActivityBar() {
    if (this.showActivityBar) {
      this.showActivityBar = false;
    } else {
      this.showActivityBar = true;
      this.isLoading = true;
      this.recentActivities = await this.sessionSetup.recentActivity();
      this.isLoading = false;
    }
  }

  @action
  markAsRead() {
    this.args.updateUserActivityFlag();
  }

  @action
  redirectToSubject(subjectId, subjectType) {
    if (subjectType === 'account') {
      this.router.transitionTo('settings.company');
    } else if (subjectType === 'shipment') {
      this.router.transitionTo('shipments.details', subjectId);
    }
  }
}
