import { getOwner } from '@ember/application';
import EmberObject from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import { dasherize } from '@ember/string';
import require from 'require';

const fakeModelProps = { isNew: true };

/**
 * Setup plain Ember.Object with validator ( if used )
 * Set isNew: true to mimic a new DS.Model
 *
 * Allows you to create objects that validate like models
 * but are not stored in the store
 *
 * @param {Object} scope Model/Component/Controller
 * @param {Object} validations EmberCpValidation
 */
export function setupObject(scope, modelName, { addValidations = true, props = {} }) {
  let container = getOwner(scope).ownerInjection(),
    modelProps = Object.assign({}, fakeModelProps, props),
    validations = getValidations(modelName, addValidations);
  return EmberObject.extend(validations).create(container, modelProps);
}

function getValidations(modelName, lookup) {
  if (!lookup) {
    return {};
  }
  const fileName = dasherize(modelName);
  let validations;
  try {
    validations = require(`tnt-ui/utils/validations/${fileName}`).default;
  } catch (e) {
    //    console.error(`Can't find validations for model: ${modelName}`);
  }
  return validations || {};
}

export function createBuffer(model) {
  return ObjectProxy.create({
    content: model,
  });
}

/**
 * Get any null object properties that are attributes or
 * relationships on the model
 *
 * @param {Ember.Object} obj
 */
export function getProps(store, modelName, obj) {
  let props = {};
  store.modelFor(modelName).eachAttribute((key) => {
    if (obj[key]) {
      props[key] = obj[key];
    }
  });
  store.modelFor(modelName).eachRelationship((key) => {
    if (obj[key]) {
      props[key] = obj[key];
    }
  });
  return props;
}
