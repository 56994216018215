/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import moment from 'moment-timezone';

export default class TimeInZoneComponent extends Component {
  @service timezone;
  dateTimeFormat = 'MMM D HH:mm';

  get noTime() {
    return isEmpty(this.args.timestamp);
  }

  get title() {
    if (this.noTime) {
      return 'Not available';
    } else {
      return `${moment(this.timeInTZ).format('LL [at] HH:mm')} `;
    }
  }

  get timeInTZ() {
    return this.timezone.dateInTz(this.args.timestamp, this.args.timeZone);
  }

  get timeZoneAbbreviation() {
    return this.timezone.timeZoneAbbr(this.args.timestamp, this.args.timeZone);
  }
}
