/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import moment from 'moment-timezone';

export default class ShipmentArrivalTimeComponent extends Component {
  @service timezone;

  get dateTimeFormat() {
    return this.args.dateTimeFormat ?? 'MMM D';
  }

  get timeLabel() {
    return this.args.timeLabel ?? 'ETA';
  }

  get isDelayed() {
    return this.daysDelayed > 0;
  }

  get isEarly() {
    return this.daysDelayed < 0;
  }

  get noTime() {
    return isEmpty(this.dateTime);
  }

  get dateTime() {
    return this.actualTimeInTZ || this.currentEstimateInTZ || this.originalEstimateInTZ;
  }

  get title() {
    if (this.noTime) {
      return `Vessel ${this.presentEvent} info is not available`;
    } else if (this.args.actualTime) {
      return `Vessel ${this.pastEvent} on ${moment(this.actualTimeInTZ).format('LL [at] HH:mm')}`;
    } else {
      let titleText = `Vessel ${this.futureEvent} on ${moment(this.currentEstimateInTZ).format('LL')}`;
      if (this.daysDelayed) {
        titleText += `, original ${this.timeLabel} was ${moment(this.originalEstimateInTZ).format('LL')}`;
      }
      return titleText;
    }
  }

  get noActual() {
    return isEmpty(this.args.actualTime);
  }

  get actualTimeInTZ() {
    return this.timezone.dateInTz(this.args.actualTime, this.args.timeZone);
  }

  get originalEstimateInTZ() {
    return this.timezone.dateInTz(this.args.originalEstimate, this.args.timeZone);
  }

  get currentEstimateInTZ() {
    return this.timezone.dateInTz(this.args.currentEstimate, this.args.timeZone);
  }

  get timeZoneAbbreviation() {
    let testDate = this.args.actualTime ?? this.args.currentEstimate;
    return this.timezone.timeZoneAbbr(testDate, this.args.timeZone);
  }

  get daysDelayed() {
    if (this.args.actualTime) {
      return undefined;
    }
    if (!(this.args.originalEstimate && this.args.currentEstimate)) {
      return undefined;
    }
    let originalEta = moment(this.args.originalEstimate),
      currentEstimate = moment(this.args.currentEstimate);
    return currentEstimate.diff(originalEta, 'days');
  }

  get futureEvent() {
    return this.timeLabel == 'ETD' ? 'departing' : 'arriving';
  }

  get presentEvent() {
    return this.timeLabel == 'ETD' ? 'departure' : 'arrival';
  }

  get pastEvent() {
    return this.timeLabel == 'ETD' ? 'departed' : 'arrived';
  }
}
