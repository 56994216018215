/* import __COLOCATED_TEMPLATE__ from './view-delete.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class ModalsViewDeleteComponent extends Component {
  @service modals;
  @service router;
  @service notify;

  get containerView() {
    return this.args.content.containerView;
  }

  @task
  *deleteViewTask() {
    const currentViewId = this.args.content.currentViewId;
    const isCurrentView = currentViewId === this.containerView.id;

    if (isCurrentView) {
      this.router.transitionTo('/containers');
    }

    this.containerView.pull();
    yield this.containerView.destroyRecord();

    this.notify.success('View deleted successfully');
    this.closeModal();
  }

  @action
  closeModal() {
    this.modals.close('view-delete');
  }
}
