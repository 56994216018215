/* eslint-disable ember/no-get */

import { get, set } from '@ember/object';
import { isPresent } from '@ember/utils';
import V2ApiSerializer from './v2-api';

export default class ContainerSerializer extends V2ApiSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    const containers = Array.isArray(payload.data) ? payload.data : [payload.data];

    containers.forEach((container) => {
      if (isPresent(get(container, 'relationships.shipment.data'))) {
        container.relationships.shipment.data.type = 'shipment-v2';
      }
      ['pod_terminal', 'pickup_facility'].forEach((terminal) => {
        if (isPresent(get(container, `relationships.${terminal}.data`))) {
          set(container, `relationships.${terminal}.data.type`, 'terminal-v2');
        }
      });
    });

    if (isPresent(payload.included)) {
      payload.included.forEach((modelData) => {
        if (modelData.type === 'shipment') {
          modelData.type = 'shipment-v2';
        }
        if (modelData.type === 'terminal') {
          modelData.type = 'terminal-v2';
        }
      });
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
