import { underscore } from '@ember/string';
import { pluralize } from 'ember-inflector';
import ApplicationAdapter from './application';

export default class V2ApiAdapter extends ApplicationAdapter {
  namespace = 'v2';

  pathForType(type) {
    let underscored = underscore(type);
    return pluralize(underscored);
  }

  // translates cuts out -v2 suffix from the model name when making requests
  // Once all models are migrated to v2 thsi could be renamed to buildURL
  // in order to handle this in generic way without the need for base classes
  // to call this method explicitly.
  // v2 model types exists only client-side, backend still uses e.g. `/v2/shipments` path instead
  buildURLV2(modelName, id, snapshot, requestType, query) {
    return super.buildURL(modelName.replace('-v2', ''), id, snapshot, requestType, query);
  }
}
