import Service, { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { Promise as EmberPromise } from 'rsvp';

export default class DialogsService extends Service {
  @service router;

  // Properties
  @tracked pool = A([]);

  constructor() {
    super(...arguments);
    window.addEventListener('popstate', () => this._onRouteChange());
  }

  // Methods

  open(name, content = {}) {
    const promise = this._deferredObject();

    this.pool.pushObject({
      name: name,
      content: { ...content, promise },
    });

    return promise;
  }

  close(name, timeout = 0) {
    if (name) {
      const dialog = this.pool.find((dialog) => dialog.name === name);

      if (dialog) {
        later(
          this,
          () => {
            dialog.content.modalClose?.();
            this.pool.removeObject(dialog);
          },
          timeout,
        );
      }
    } else {
      later(
        this,
        () => {
          this.pool.popObject();
        },
        timeout,
      );
    }
  }

  // Private Methods

  _deferredObject() {
    let _resolve, _reject;
    const promise = new EmberPromise((resolve, reject) => {
      _reject = reject;
      _resolve = resolve;
    });

    promise.resolve = (value) => _resolve(value);
    promise.reject = (value) => _reject(value);

    return promise;
  }

  _resetAll() {
    this.pool.forEach((dialog) => dialog.content.modalClose?.());
    this.pool = A([]);
  }

  _onRouteChange() {
    if (this.pool.length) {
      this._resetAll();
    }
  }
}
