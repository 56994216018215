/* eslint-disable ember/avoid-leaking-state-in-ember-objects, ember/no-classic-classes, ember/no-get, ember/no-mixins, ember/use-ember-data-rfc-395-imports */

import DS from 'ember-data';
import Service, { inject as service } from '@ember/service';
import { debug } from '@ember/debug';
import { oneWay } from '@ember/object/computed';
import Evented from '@ember/object/evented';
import { run } from '@ember/runloop';
import { isBlank } from '@ember/utils';
import RSVP from 'rsvp';
import CurrentSession from 'tnt-ui/mixins/current-session';

export default Service.extend(Evented, CurrentSession, {
  appSocket: service('app-socket'),
  store: service('store'),
  socket: null,
  searches: [],
  isConnecting: false,
  isConnected: oneWay('socket.connection.connected'),
  searchTimeout: 120000, // 120 seconds
  _promises: {},
  _timeouts: {},
  isSupported: WebSocket !== undefined,

  connect() {
    if (isBlank(this.socket) && isBlank(this.channel)) {
      run(() => {
        const authToken = this.get('session.data.authenticated.access_token');
        let connection = this.appSocket.setupSocket(authToken);
        this.registerChannel(connection);
        this.set('socket', connection);
      });
    }
  },

  registerChannel(connection) {
    let channel = connection.subscriptions.create('SearchChannel', {
      connected: () => {
        this.performSearches();
        debug('SearchChannel:Connected');
      },
      received: (data) => {
        const defer = this.get(`_promises.${data.number}`);
        if (defer) {
          if (data.success) {
            let model = this.storeData(data);
            defer.resolve(model);
          } else {
            defer.reject(new DS.AdapterError(this.errorPayload('404', 'Not Found', data.results)));
          }
          run.cancel(this.get(`_timeouts.${data.number}`));
          this.set(`_promises.${data.number}`, null);
          this.set(`_timeouts.${data.number}`, null);
        }
      },
      disconnected() {
        debug('SearchChannel:disconnected');
      },
    });
    this.set('channel', channel);
  },

  search(query, activity, user_id = null, search_all_active = false) {
    debug(`${query} ${activity} | uid:${user_id}`);
    this.searches.pushObject({
      query: query,
      activity: activity,
      user_id: user_id,
      search_all_active: search_all_active,
    });
    let defer = new RSVP.defer();
    this.set(`_promises.${query}`, defer);
    if (this.isConnected) {
      this.performSearches();
    } else {
      this.connect();
    }
    return defer.promise;
  },

  cleanupSearch(query) {
    let newSearches = this.searches.filter((item) => item.query !== query);
    this.set(`_promises.${query}`, null);
    this.set('searches', newSearches);
  },

  performSearches() {
    this.searches.forEach((search) => {
      this.channel.perform('search', search);
      let searchTimeout = run.later(
        this,
        () => {
          this.sendSearchTimeout(search.query);
        },
        this.searchTimeout,
      );

      this.set(`_timeouts.${search.query}`, searchTimeout);
    });
    this.set('searches', []);
  },

  sendSearchTimeout(query) {
    const defer = this.get(`_promises.${query}`);
    defer.reject(new DS.AdapterError(this.errorPayload('503', 'Timeout Error', [])));
    run.cancel(this.get(`_timeouts.${query}`));
    this.set(`_promises.${query}`, null);
    this.set(`_timeouts.${query}`, null);
  },

  storeData(data) {
    let store = this.store;
    let modelName = data.object.data.type;
    let modelClass = store.modelFor(modelName);
    let serializer = store.serializerFor(modelName);
    let normalizedPayload = serializer.normalizeSingleResponse(store, modelClass, data.object, null, true);
    return store.push(normalizedPayload);
  },

  errorPayload(status, text, results = []) {
    return [
      {
        status: status,
        title: text,
        meta: {
          timeoutLength: this.searchTimeout,
          results: results,
        },
      },
    ];
  },
});
