/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

interface CargoFormSignature {
  Element: HTMLDivElement;
  Args: {
    railCarrierScac?: string;
    name?: string;
  };
}

export default class CargoFormComponent extends Component<CargoFormSignature> {}
