/* eslint-disable prettier/prettier */
import Service, { inject as service } from '@ember/service';
import NotifyService from 'ember-notify/services/notify';

export default class MessageService extends Service {
  @service declare notify: NotifyService;

  info(message: string, type: 'success' | 'error' | 'info' = 'success', extraParams = {}) {
    this.notify[type](message, extraParams);
  }

  error(message: string, extraParams = {}) {
    this.info(message, 'error', extraParams);
  }
}
