import { addSuccessHandler } from 'tnt-ui/initializers/service-worker';

export function startServiceWorker() {
  window.isUpdateAvailable =
    'Promise' in window
      ? new Promise((resolve) => {
          addSuccessHandler((registration) => {
            registration.onupdatefound = () => {
              const { installing } = registration;

              installing.onstatechange = () => {
                if (installing.state === 'activated') {
                  if (localStorage.getItem('sw_registered') === 'ok') {
                    resolve(navigator.serviceWorker.controller !== null);
                  } else {
                    localStorage.setItem('sw_registered', 'ok');
                    resolve(false);
                  }
                }
              };
            };
          });
        })
      : {
          then: () => {
            return false;
          },
        };
}

export default {
  startServiceWorker,
};
