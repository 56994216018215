import V2ApiAdapter from './v2-api';

export default class ContainerAdapter extends V2ApiAdapter {
  urlForQuery(query) {
    if (query.shareId) {
      return `${this.urlPrefix()}/containers/share/${query.shareId}`;
    }

    return super.urlForQuery(...arguments);
  }
}
