import { buildValidations, validator } from 'ember-cp-validations';
import { city, line1 } from './address';

export const country = validator('presence', {
  presence: true,
  description: 'Country',
});

export default buildValidations({
  line1,
  city,
  country,
});
