/* import __COLOCATED_TEMPLATE__ from './vessel-details.hbs'; */
import Component from '@glimmer/component';
import type { AsyncHasMany } from '@ember-data/model';
import type ShipmentModelV2 from 'tnt-ui/models/shipment-v2';
import type VesselV2Model from 'tnt-ui/models/vessel-v2';

interface VesselMapVesselDetailsComponentSignature {
  Element: HTMLDivElement;
  Args: {
    vessel: VesselV2Model;
    shipments: ShipmentModelV2[];
    onSelectShipment: (shipment: ShipmentModelV2) => void;
    isLoadingShipment: boolean;
    selectedShipmentId: string | null;
    onClose: () => void;
  };
}

// Container does not have typedef yet
type Container = ShipmentModelV2['containers'] extends AsyncHasMany<infer U> ? U : never;

export default class VesselMapVesselDetailsComponent extends Component<VesselMapVesselDetailsComponentSignature> {
  get containers(): Container[] | undefined {
    return this.args.shipments.reduce((acc, shipment) => {
      return [...acc, ...shipment.containers.toArray()];
    }, [] as Container[]);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'VesselMap::VesselDetails': typeof VesselMapVesselDetailsComponent;
  }
}
