/* eslint-disable @typescript-eslint/no-unsafe-declaration-merging */
import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import { isEqual } from '@ember/utils';
import { modelValidator, type ValidatedModel, type ValidationsConfig } from 'ember-model-validator';
import type IntegrationModel from './integration';

@modelValidator
class CredentialModel extends Model {
  @attr('string') declare username: string;
  @attr('string') declare password: string;
  @attr('string') declare status: string;
  @belongsTo('integration') declare integration: AsyncBelongsTo<IntegrationModel>;

  validations: ValidationsConfig = {
    username: { presence: true },
    password: { presence: true },
  };

  get valid() {
    return isEqual(this.status, 'valid');
  }

  get invalid() {
    return isEqual(this.status, 'invalid');
  }

  async check() {
    const adapter = this.store.adapterFor('credential' as never) as any;
    const payload = await adapter.check(this);
    return payload;
  }
}

interface CredentialModel extends ValidatedModel, Model {}

export default CredentialModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    credential: CredentialModel;
  }
}
