import { attr, hasMany, type AsyncHasMany } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import LocationModel from 'tnt-ui/models/location';
import type TerminalModel from 'tnt-ui/models/terminal';
import { COUNTRIES } from 'tnt-ui/utils/fixtures/countries';

export default class PortModel extends LocationModel {
  @hasMany('terminal') declare terminals: AsyncHasMany<TerminalModel>;

  @attr('string') declare name: string;
  @attr('string') declare code: string;
  @attr('string') declare stateAbbr: string;
  @attr('string') declare city: string;
  @attr('string') declare state: string;
  @attr('string') declare countryCode: string;
  @attr('string') declare timeZone: string;

  @attr('number') declare longitude: number;
  @attr('number') declare latitude: number;

  get countryName() {
    const country = COUNTRIES.findBy('v', this.countryCode);
    if (country !== undefined) {
      return country.k;
    }
    return country;
  }

  get hasCoordinates() {
    return isPresent(this.latitude) && isPresent(this.longitude);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    port: PortModel;
  }
}
