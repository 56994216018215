import { service } from '@ember/service';
import { reads } from '@ember/object/computed';

export default function currentSession(Class) {
  return class CurrentSessionClass extends Class {
    @service session;
    @reads('session.currentUser') currentUser;
    @reads('currentUser.currentAccount') currentAccount;
    @reads('session.isAuthenticated') userIsAuthenticated;
  };
}
