import Model, { attr, belongsTo } from '@ember-data/model';

const isNoteFor = (model, modelName) => {
  const name = model.get('_internalModel.modelName') || model.get('content.constructor').toString();
  return name && name.includes(modelName);
};

export default class NoteModel extends Model {
  @belongsTo('user') author;
  @belongsTo('notable', { polymorphic: true }) notable;

  @attr('date') createdAt;
  @attr('string') body;

  get isContainer() {
    return isNoteFor(this.notable, 'container');
  }

  get isShipment() {
    return isNoteFor(this.notable, 'shipment-v2');
  }
}
