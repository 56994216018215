/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { and, not } from '@ember/object/computed';
import { usedAs } from '../utils/decorators';

export const ACH = 'ACH',
  Check = 'Check',
  Quickbooks = 'Quickbooks',
  Other = 'Other',
  PaymentTypes = [ACH, Check, Quickbooks, Other];

@usedAs(['attachable'])
export default class InvoiceModel extends Model {
  @belongsTo('user') paidBy;
  @belongsTo('user') createdBy;
  @belongsTo('booking-order') bookingOrder;

  @attr('number', { default: 0 }) amount;
  @attr('string') displayId;
  @attr('string') refNumber;
  @attr('string') paymentType;

  @attr('date') paidAt;
  @attr('date') createdAt;
  @attr('date') invoicedOn;

  @not('isNew') isSaved;

  @and('paidAt', 'paidBy', 'paymentType') isPaid;
  @not('isPaid') unPaid;
}
