import moment from 'moment-timezone';
import ApplicationAdapter from './application';

export default class TerminalAdapter extends ApplicationAdapter {
  constructor() {
    super(...arguments);
    this.lastReloaded = moment();
  }
  shouldBackgroundReloadAll() {
    let dayAgo = moment().subtract(1, 'day');
    if (this.lastReloaded.isBefore(dayAgo)) {
      this.lastReloaded = moment();
      return true;
    }
    return false;
  }
}
