import Model, { attr, belongsTo } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { buildValidations, validator } from 'ember-cp-validations';

const REQUIRED_FIELD_CATEGORIES = ['shipment_map', 'other'];

const Validations = buildValidations({
  issueCategory: validator('presence', true),
  ctaSource: validator('presence', true),
  issueType: validator('inline', {
    validate(value, options, model) {
      if (isPresent(model.issueCategory) && !REQUIRED_FIELD_CATEGORIES.includes(model.issueCategory)) {
        return isPresent(model.issueType);
      } else {
        return true;
      }
    },
  }),
  issueAttribute: validator('inline', {
    validate(value, options, model) {
      if (isPresent(model.issueCategory) && !REQUIRED_FIELD_CATEGORIES.includes(model.issueCategory)) {
        return isPresent(model.issueAttribute);
      } else {
        return true;
      }
    },
  }),
  additionalDetails: validator('inline', {
    validate(value, options, model) {
      if (isPresent(model.issueCategory) && REQUIRED_FIELD_CATEGORIES.includes(model.issueCategory)) {
        return isPresent(model.additionalDetails);
      } else {
        return true;
      }
    },
  }),
});

export default class ReportedIssueModel extends Model.extend(Validations) {
  @belongsTo('account') account;
  @belongsTo('user') user;
  @belongsTo('shipment') shipment;
  @belongsTo('cargo') cargo;

  @attr('string') issueCategory;
  @attr('string') issueType;
  @attr('string') issueAttribute;
  @attr('string') additionalDetails;
  @attr('string') status;
  @attr('string') ctaSource;
  @attr('string') screenshotPath;
}
