import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { later, next } from '@ember/runloop';
import { isEqual, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { isTesting } from '@embroider/macros';
import { createPopper } from '@popperjs/core';
import ENV from 'tnt-ui/config/environment';
import currentSession from 'tnt-ui/decorators/current-session';

const signOutMessage = `Are you sure you want to sign out?
      \nTip: Your login is valid several weeks. No need to signout every time.`;

@currentSession
export default class Application extends Controller {
  @service metrics;
  @service session;
  @service router;
  @service routeLayout;
  @service featureFlags;
  @service onboarding;

  applicationEnv = ENV.APP.APPENV;
  notifyCloseAfter = isTesting() ? 5 : 3000;

  @tracked showAccountMenu = false;
  @tracked showChildAccounts = false;
  @tracked showChildNotifications = false;
  @tracked showChildHelp = false;
  @tracked showChildUser = false;
  @tracked expandNavbar = true;

  get flags() {
    return this.currentAccount?.flags ?? {};
  }

  get showDevelopers() {
    return this.flags.apiAccess;
  }

  get showReports() {
    return isPresent(this.currentAccount?.accountMetabaseDashboards);
  }

  get showGlobalMap() {
    return this.featureFlags.isEnabled('globalMap');
  }

  get showVesselReport() {
    return isPresent(
      this.currentAccount?.accountMetabaseDashboards.find(({ path }) => isEqual(path, 'vessel-arrivals-beta')),
    );
  }

  get showGettingStarted() {
    // New users will have create-account attribute filled automatically.  Older users will not, and thus they will not see the gettingStarted page
    return this.onboarding.isComplete('create-account');
  }

  get isAdmin() {
    return this.currentUser?.admin;
  }

  get signedInAs() {
    return JSON.parse(localStorage.getItem('signedInAs'));
  }

  get containerClass() {
    if (
      [
        'share',
        'containers',
        'shipments',
        'shipments.index',
        'shipments.details',
        'containers.index',
        'containers.show',
        'global-map',
        'vessels',
        'track.new',
        'dashboard.containers-at-risk',
        'dashboard.arriving-today',
        'dashboard.eta-changed',
      ].includes(this.router.currentRouteName)
    ) {
      return 'container-fluid';
    } else if (
      ['containers.new', 'containers.new.index', 'containers.new.manage-views'].includes(this.router.currentRouteName)
    ) {
      return 'container-fluid-new';
    } else if (['shipments.index'].includes(this.router.currentRouteName)) {
      return 'container tw-pl-8';
    } else {
      return 'container-xl';
    }
  }

  get removeHeaderFooterAndPadding() {
    return ['register', 'sign-in', 'account-locked'].includes(this.router.currentRouteName);
  }

  get removeFooter() {
    return ['containers.new', 'containers.new.index', 'containers.new.manage-views'].includes(
      this.router.currentRouteName,
    );
  }

  // This method checks if user have more than 1 account if so adds role='button' attribute and click event to show child and expand navbar
  @action
  setChildProfile(element) {
    next(this, () => {
      if (this.currentUser.accounts.length > 1) {
        element.setAttribute('role', 'button');
        element.addEventListener('click', (e) => {
          this.showChildAccounts = !this.showChildAccounts;
          this.onExpandNavbar(e);
        });
      }

      this.childProfileElement = element;
    });
  }

  // This method calculates the account switcher block when becomes visible after user clicks to show
  @action
  calculateChildProfilePosition(element) {
    createPopper(this.childProfileElement, element, {
      placement: 'bottom-start',
      modifiers: [
        {
          name: 'offset',
          options: { offset: [0, 4] },
        },
      ],
    });
  }

  @action
  setChildNotifications(element) {
    this.childNotificationsElement = element;
  }

  @action
  calculateChildNotificationsPosition(element) {
    createPopper(this.childNotificationsElement, element, {
      placement: 'right-start',
    });
  }

  @action
  setChildAdmin(element) {
    this.childAdminElement = element;
  }

  @action
  calculateChildAdminPosition(element) {
    createPopper(this.childAdminElement, element, {
      placement: 'right-end',
    });
  }

  @action
  setChildHelp(element) {
    this.childHelpElement = element;
  }

  @action
  calculateChildHelpPosition(element) {
    createPopper(this.childHelpElement, element, {
      placement: 'right-end',
    });
  }

  @action
  setChildUser(element) {
    this.childUserElement = element;
  }

  @action
  calculateChildUserPosition(element) {
    createPopper(this.childUserElement, element, {
      placement: 'right-end',
    });
  }

  @action
  scrollToView(element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  @action
  toggleNavbar(e) {
    e.preventDefault();
    this.expandNavbar = !this.expandNavbar;

    if (this.expandNavbar) {
      this.routeLayout.removeBodyClass('new-nav-collapsed');
    } else {
      this.routeLayout.setBodyClass('new-nav-collapsed');

      later(
        this,
        () => {
          const navbar = document.querySelector('.left-nav-collapsed');
          if (navbar) {
            navbar.classList.add('left-nav-collapsed_done');
          }
        },
        450,
      );
    }
  }

  @action
  onExpandNavbar(e) {
    e.preventDefault();
    this.expandNavbar = true;
    this.routeLayout.removeBodyClass('new-nav-collapsed');
  }

  @action
  onChangeAccount(current, account_id) {
    if (current) {
      return;
    }

    this.session.set('data.current_account_id', account_id);
    location.reload();
  }

  @action
  onToggleNotifications(e) {
    e.stopPropagation();

    this.showChildNotifications = !this.showChildNotifications;
  }

  @action
  async signOutAs() {
    await this.session.authenticate(
      'authenticator:oauth2-revert-session',
      this.signedInAs.adminAccessToken,
      this.signedInAs.adminUserId,
    );
    this.session.set('data.current_account_id', null);

    const previousURL = this.signedInAs.previousURL;

    if (isPresent(previousURL)) {
      location.replace(previousURL);
    } else {
      location.reload();
    }

    localStorage.removeItem('signedInAs');
  }

  @action
  toggleAccountMenu() {
    this.showAccountMenu = !this.showAccountMenu;
    if (this.showAccountMenu) {
      this.metrics.trackEvent({ event: 'Show Activities DropDown' });
    }
  }

  @action
  reloadApp() {
    window.location.reload();
    this.showNewVersionBanner = false;
  }

  @action
  async invalidateSession() {
    let shouldSignOut = confirm(signOutMessage);
    if (shouldSignOut === true) {
      localStorage.removeItem('signedInAs');
      this.metrics.trackEvent({ event: 'Sign Out', source: 'navbar' });
      this.session.set('data.current_account_id', null);
      await this.session.invalidate();
    }
  }

  @action
  async updateUserActivityFlag() {
    let user = this.currentUser;
    user.set('activitiesLastSeenAt', new Date());
    await user.save();
    this.metrics.trackEvent({ event: 'Activities Marked As Read' });
  }
}
