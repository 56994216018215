import Model, { attr, belongsTo, hasMany, type SyncHasMany } from '@ember-data/model';
//@ts-ignore - not yet typed 🫠
import { cached } from '@glimmer/tracking';
import type RouteLocationModel from 'tnt-ui/models/route-location';
import type VesselV2Model from 'tnt-ui/models/vessel-v2';

export default class RouteModel extends Model {
  @belongsTo('container', { async: false }) declare cargo: any;
  @hasMany('route-location', { async: false }) declare routeLocations: SyncHasMany<RouteLocationModel>;

  @attr('string') declare createdAt: string;
  @attr('string') declare updatedAt: string;

  get earliestOutboundAtdAt() {
    return this.routeLocations
      .map((routeLocation) => routeLocation.outboundAtdAt)
      .filter(Boolean)
      .sort()[0];
  }

  @cached
  get uniqueOutboundVessels() {
    return Array.from(
      new Set(
        this.routeLocations
          .reduce((acc: VesselV2Model[], routeLocation) => {
            acc.push(routeLocation.outboundVessel);

            return acc;
          }, [])
          .filter((vessel) => vessel?.id),
      ),
    );
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    route: RouteModel;
  }
}
