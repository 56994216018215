/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/classic-decorator-hooks */
import { setProperties } from '@ember/object';
import ValidSelect from 'tnt-ui/components/forms/valid-select';
import { US_STATES } from 'tnt-ui/utils/fixtures/us-states';

export default class StateSelect extends ValidSelect {
  prop = 'stateAbbr';
  options = US_STATES;
  labelPath = 'k';
  valuePath = 'v';

  init() {
    super.init(...arguments);
    setProperties(this, {
      prompt: 'Select State',
      name: 'State',
    });
  }
}
