import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class extends Controller {
  @service router;

  searchVal = null;
  search = (query) => {
    this.router.transitionTo('search', { queryParams: { q: query } });
  };
}
