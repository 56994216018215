import Model, { attr } from '@ember-data/model';

export default class SteamshipLineModel extends Model {
  @attr('string') declare name: string;
  @attr('string') declare nickname: string;
  @attr('string') declare scac: string;
  @attr('string') declare bolPrefix: string;

  get nicknameAndName() {
    return `${this.name} ${this.nickname} ${this.scac} ${this.bolPrefix}`;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'steamship-line': SteamshipLineModel;
  }
}
