import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class Loading extends Route {
  @service routeLayout;
  activate() {
    this.routeLayout.useV2Template = true;
    this.routeLayout.setBodyClass('homepage');
  }
  deactivate() {
    this.routeLayout.useV2Template = false;
    this.routeLayout.removeBodyClass('homepage');
  }
  bodyClass = 'homepage';
}
