/* eslint-disable ember/no-computed-properties-in-native-classes */

import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { TrackedArray } from 'tracked-built-ins';

export default class RequirementModel extends Model {
  @belongsTo('account', { async: false }) account;

  @attr('string') name;
  @attr('string') currentStatus;
  @attr('tracked-array', { defaultValue: () => new TrackedArray([]) }) availableStates;

  @computed('currentStatus')
  get metaStatus() {
    if (this.currentStatus === 'not_started') {
      return 'notStarted';
    } else if (this.currentStatus === 'completed') {
      return 'completed';
    } else {
      return 'started';
    }
  }

  @computed('currentStatus')
  get completed() {
    return this.currentStatus === 'completed';
  }

  @computed('currentStatus')
  get started() {
    return this.currentStatus !== 'not_started';
  }
}
