export function serialize(object: Record<string, any>): string {
  function reducer(obj: Record<string, any>, parentPrefix: string | null = null) {
    return function (prev: string[], key: string): string[] {
      const val = obj[key];
      key = encodeURIComponent(key);
      const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

      if (val == null || typeof val === 'function') {
        prev.push(`${prefix}=`);
        return prev;
      }

      if (typeof val === 'boolean') {
        prev.push(`${prefix}=${val.toString().toLowerCase()}`);
        return prev;
      }

      if (['number', 'string'].includes(typeof val)) {
        prev.push(`${prefix}=${encodeURIComponent(val)}`);
        return prev;
      }

      if (Array.isArray(val)) {
        val.forEach((v: any) => {
          prev.push(`${prefix}[]=${encodeURIComponent(v.toString())}`);
        });
        return prev;
      }

      prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join('&'));
      return prev;
    };
  }

  return Object.keys(object).reduce(reducer(object), []).join('&');
}
