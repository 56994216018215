import Model, { attr, belongsTo } from '@ember-data/model';
import type ContainerView from 'tnt-ui/models/container-view';

export default class ContainerViewSummary extends Model {
  // Relationships
  @belongsTo('container-view', { async: false }) declare containerView: ContainerView;

  // Attributes
  @attr('number') declare totalContainers: number;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'container-view-summary': ContainerViewSummary;
  }
}
