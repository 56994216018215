/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import Rate from 'tnt-ui/models/rate';

export default class RateEditorComponent extends Component {
  rateDetails = Rate.rateDetails();

  get showErrors() {
    return this.args.showErrors ?? false;
  }
}
