/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { later } from '@ember/runloop';

export default class ContainersModalComponent extends Component {
  get hasOverlay() {
    return this.args.hasOverlay ?? true;
  }

  get isOverlayInvisible() {
    return this.args.isOverlayInvisible || false;
  }

  get size() {
    return this.args.size || 'medium';
  }

  @action
  onDidInsert(element) {
    this.modal = element;
    const overlay = this.modal.querySelector('.containers-modal__overlay');

    if (overlay) {
      overlay.classList.add('visible');
    }

    later(
      this,
      () => {
        this.modal.querySelector('.containers-modal__columns-container').classList.add('expanded');
      },
      0,
    );
  }

  @action
  async initModalClose() {
    const onBeforeClose = this.args.onBeforeClose;
    if (onBeforeClose) {
      try {
        await onBeforeClose();
      } catch {
        return;
      }
    }

    await this.closeModal();
  }

  @action
  async closeModal() {
    this._removeExpandedClass();
    await this._delay(300);
    await this.args.onModalClose?.();
  }

  _removeExpandedClass() {
    const element = this.modal.getElementsByClassName('containers-modal__columns-container')[0];
    element.classList.remove('expanded');
    this._closeOverlay();
  }

  async _delay(timeout) {
    await new Promise((resolve) => later(this, resolve, timeout));
  }

  _closeOverlay() {
    const overlay = this.modal.getElementsByClassName('containers-modal__overlay')[0];
    if (overlay) {
      overlay.classList.remove('visible');
    }
  }
}
