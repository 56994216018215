//@ts-ignore
import { saveAs } from 'file-saver';

export function getFilename(url: string): string | undefined {
  return url.split('/').pop()!.split('#')[0]?.split('?')[0];
}

export async function saveDownloadedFile(response: Response): Promise<void> {
  const blob = await response.blob();
  const contentDisposition = response.headers.get('content-disposition');

  let filename: string | undefined;

  if (contentDisposition) {
    filename =
      contentDisposition
        .split(';')
        .find((n) => n.includes('filename='))
        ?.replace('filename=', '')
        .trim() || 'download';
  } else {
    filename = getFilename(response.url);
  }

  saveAs(blob, filename);
}
