/* eslint-disable ember/no-computed-properties-in-native-classes */

import Model, { attr, belongsTo } from '@ember-data/model';
import { equal, notEmpty, or } from '@ember/object/computed';
import { toJSON } from 'tnt-ui/utils/toJSON';
import { TrackedArray } from 'tracked-built-ins';
import Validations from '../utils/validations/container-update';

export default class ContainerUpdateModel extends Model.extend(Validations) {
  @belongsTo('cntnr') cntnr;

  @attr('string') status;
  @attr('date') lastSeen;
  @attr('date') lastFreeDayOn;
  @attr('string') location;
  @attr('date') createdAt;
  @attr('string') source;
  @attr('date') timestamp;
  @attr('string') cargoId;

  @attr('tracked-array', { defaultValue: () => new TrackedArray([]) }) holds;
  @attr('tracked-array', { defaultValue: () => new TrackedArray([]) }) fees;

  @notEmpty('fees') hasFees;
  @notEmpty('holds') hasHolds;

  @equal('status', 'Delivered') isDelivered;

  @or('timestamp', 'createdAt') eventTimestamp;
  toJSON() {
    return toJSON(this);
  }
}
