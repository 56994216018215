export const OPS_STEPS = [
  {
    index: 0,
    id: 'initial_step',
    responsibleParty: 't49',
    shortText: 'Cargo Created',
    description: 'Cargo was created by customer. T49 needs to initialize operations process.',
  },
  {
    index: 1,
    id: 'verify_shipline',
    responsibleParty: 't49',
    shortText: 'Verify Info with Shipline',
    description: 'Confirm DO info with Steamship Line (Vessel ETA, Vessel/Voyage, Container type/sizes)',
  },
  {
    index: 2,
    id: 'verify_terminal',
    responsibleParty: 't49',
    shortText: 'Verify Info with Terminal',
    description: 'Confirm Terminal provided by customer by checking vessel status in the terminal website.',
  },
  {
    index: 3,
    id: 'offer_partner',
    responsibleParty: 't49',
    shortText: 'Set Rate & Offer to Partner',
    description: 'Define custom rate (if needed) and offer load & rate to trucking partner.',
  },
  {
    index: 4,
    id: 'accept_load',
    responsibleParty: 'trucker',
    shortText: 'Accept or Decline Load',
    description: 'Receive response whether trucking partner accepts or declines load.',
  },
  {
    index: 5,
    id: 'assign_partner',
    responsibleParty: 't49',
    shortText: 'Assign Trucking Partner',
    description: 'Assign the trucking partner to the container.',
  },
  {
    index: 6,
    id: 'send_order',
    responsibleParty: 't49',
    shortText: 'Send Order to Partner',
    description: 'Send an delivery order confirmation to trucking partner incl. rate and T49 link.',
  },
  {
    index: 7,
    id: 'pay_fees',
    responsibleParty: 't49',
    shortText: 'Pay Fees for Container',
    description: 'If requested by customer and applicable, pay fees for container (e.g. SSA Gate Fee).',
  },
  {
    index: 8,
    id: 'set_pu_appointment',
    responsibleParty: 'trucker',
    shortText: 'Set Pickup Appointment',
    description: 'When container is available, trucker needs to set pickup appointment at terminal (if applicable).',
  },
  {
    index: 9,
    id: 'set_drop_appointment',
    responsibleParty: 'trucker',
    shortText: 'Set Delivery Appointment',
    description: 'When container is available, trucker needs to set delivery appointment with customer.',
  },
  {
    index: 10,
    id: 'upload_pod',
    responsibleParty: 'trucker',
    shortText: 'Upload Proof of Delivery',
    description: 'After a container was delivered, trucking partner needs to upload proof of delivery.',
  },
  {
    index: 11,
    id: 'update_charges',
    responsibleParty: 't49',
    shortText: 'Request to Update Charges',
    description: 'Request partner to submit charges for container within 48 hours (line items or invoice)',
  },
  {
    index: 12,
    id: 'approve_charges',
    responsibleParty: 't49',
    shortText: 'Approve Partner Charges',
    description: 'Confirm charges. Ensure chassis and wait time is provided for customer invoicing.',
  },
  {
    index: 13,
    id: 'send_invoice',
    responsibleParty: 't49',
    shortText: 'Send Invoice to Customer',
    description: 'Use rates and trucking partner dispatch information to set charges and add proof.',
  },
  {
    index: 14,
    id: 'pay_invoice',
    responsibleParty: 'customer',
    shortText: 'Pay Invoice',
    description: 'Pay invoice via ACH or check and confirm deposit in T49 account',
  },
  {
    index: 15,
    id: 'pay_partner',
    responsibleParty: 't49',
    shortText: 'Pay Trucking Partner',
    description: 'Pay trucking partner based on invoice that was sent via email.',
  },
];
