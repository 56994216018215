import { modifier } from 'ember-modifier';
import tippyJS from 'tippy.js';

export default modifier(
  function tooltip(element, _params, hash) {
    if (element._tippy) {
      element._tippy.destroy();
    }

    if ([undefined, 'hide'].includes(hash.placement)) {
      return;
    }

    tippyJS(element, hash);
  },
  { eager: false },
);
