import { filtersMap } from 'harbor/utils/filters/filter-definitions';
import columnDefinitions from 'tnt-ui/utils/views/column-definitions';

const columns = [
  'container_number',
  'shipment_number',
  'eq_type',
  'customer_name',
  'shipment_tags',
  'port_of_origin',
  'port_of_discharge',
  'pod_terminal_name',
  'arrival',
  'carrier',
  'fees_at_pod',
  'holds_at_pod',
  'pol_etd_at',
  'current_issues',
];

export default {
  columns: columns.map((column) => columnDefinitions[column]),
  filters: {
    ...Object.fromEntries(Array.from(filtersMap.values()).map((v) => [v.queryParam, v.defaultValue])),
    filterIsOnWater: true,
    filterActivelyTracked: true,
  },
  sort: ['-arrival'],
};
