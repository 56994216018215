/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr, hasMany } from '@ember-data/model';
import { mapBy, sum } from '@ember/object/computed';

export default class BookingModel extends Model {
  @hasMany('cntnrs') cntnrs;

  @attr('string') number;
  @attr('string') shipline;
  @attr('string') vessel;
  @attr('date') cutOff;
  @attr('string') voyage;
  @attr('string') terminalName;
  @attr('date') emptyStart;
  @attr('date') fullStart;
  @attr('date') lastRefresh;
  @attr() equipmentSummary;
  @mapBy('equipmentSummary', 'booked') bookedValues;
  @sum('bookedValues') numBookedContainers;
}
