/* eslint-disable ember/no-attrs-in-components, ember/no-classic-components, ember/no-computed-properties-in-native-classes, ember/require-tagless-components, getter-return, no-setter-return */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { capitalize, dasherize } from '@ember/string';
import { className, classNames } from '@ember-decorators/component';

const TRANSFORMS = {
  '': (x) => x,
  number: (x) => {
    if (!isNaN(x)) {
      return Number(x);
    }
  },
};

const SIZEMAPPING = {
  small: 'sm',
  large: 'lg',
};

@classNames('form-group')
export default class BaseInput extends Component {
  @action
  afterSaveProperty() {}

  @action
  inputChanged(value) {
    if (this.attrs.onInput) {
      this.attrs.onInput(value);
    }
  }

  @action
  onKeyUp(model, prop, transformName = '', value) {
    const transformedValue = TRANSFORMS[transformName](value);
    this.setProperty(model, prop, transformedValue);
  }

  @computed('prop')
  get name() {
    const prop = this.prop || '';
    return dasherize(prop).split('-').map(capitalize).join(' ');
  }
  set name(text) {
    return text;
  }

  @computed('placeholder', 'name')
  get placeholderText() {
    return typeof this.placeholder === 'undefined' ? this.name : this.placeholder;
  }

  @computed('displaySize')
  get inputGroupClass() {
    return this.sizeClass('input-group-');
  }

  @computed('displaySize')
  get inputClass() {
    return this.sizeClass('form-control-');
  }

  @computed('displaySize')
  get labelClass() {
    return this.sizeClass('col-form-label-');
  }

  @computed('displaySize')
  get buttonClass() {
    return this.sizeClass('btn-');
  }

  @className
  @computed('size')
  get sizing() {
    if (this.size) {
      return `col-sm-${this.size}`;
    }
  }

  @className
  @computed('prop')
  get attrClass() {
    const prop = this.prop;
    return prop
      ? prop
          .split('.')
          .map(dasherize)
          .join(' ')
          .replace(/^disabled$/, '')
      : '';
  }

  // private

  sizeClass(prefix) {
    const suffix = SIZEMAPPING[this.displaySize];
    return suffix ? prefix + suffix : '';
  }
}
