import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class RouteLayoutService extends Service {
  @tracked useV2Template = false;

  setBodyClass(className: string) {
    const body = document.querySelector('body');
    if (body) {
      body.classList.add(className);
    }
  }
  removeBodyClass(className: string) {
    const body = document.querySelector('body');
    if (body) {
      body.classList.remove(className);
    }
  }
}
