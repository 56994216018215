/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-get */

import Component from '@ember/component';
import { get } from '@ember/object';
import { tagName } from '@ember-decorators/component';

@tagName('')
class VErrorsFor extends Component {
  // These errors come directly from Ember Data or from the server
  get modelErrors() {
    const { model, attr } = this;
    return (get(model, 'errors')?.errorsFor(attr) || []).map((e) => e.message);
  }

  // These errors come from ember-cp-validations
  get validationErrors() {
    const { model, attr } = this;
    return get(model, `validations.attrs.${attr}.messages`) || [];
  }

  get errorsToShow() {
    const { modelErrors, validationErrors } = this;
    return [...modelErrors, ...validationErrors].join(', ');
  }

  get anyErrors() {
    return this.errorsToShow.length > 0;
  }
}

VErrorsFor.reopenClass({
  positionalParams: ['model', 'attr', 'showErrors'],
});

export default VErrorsFor;
