import Application from '@ember/application';
import loadInitializers from 'ember-load-initializers';
import Resolver from 'ember-resolver';
import config from 'tnt-ui/config/environment';
import { startSentry } from './sentry';
import { startServiceWorker } from './service-worker';
import './assets/styles/app.scss';

startServiceWorker();
startSentry();

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
