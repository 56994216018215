/* eslint-disable prettier/prettier */
export function isContainerNumber(numStr: string | undefined) {
  if (!numStr || !numStr.match(/^[A-Z]{3}U[0-9]{7}$/)) return false;

  const containerNumber = numStr.toUpperCase();
  const lastDigit = parseInt(containerNumber.slice(-1), 10);
  const calculatedChecksum = _calculateChecksum(containerNumber);

  return lastDigit === calculatedChecksum;
}

function _calculateChecksum(numStr: string) {
  const charCodes = '0123456789A?BCDEFGHIJK?LMNOPQRSTU?VWXYZ';
  const elements = numStr.slice(0, 10).split('');
  const num: number = elements.reduce((acc: any, c: any, i: any) => {
    const idx = charCodes.indexOf(c);
    if (idx === -1) return false;
    if (idx === 0) return acc;
    return acc + idx * 2 ** i;
  }, 0);

  return (num % 11) % 10;
}
