import arrived_view from 'tnt-ui/utils/views/arrived';
import default_view from 'tnt-ui/utils/views/default';
import empty_returned_view from 'tnt-ui/utils/views/empty-returned';
import needs_attention_view from 'tnt-ui/utils/views/needs-attention';
import on_ship_view from 'tnt-ui/utils/views/on-ship';
import picked_up_view from 'tnt-ui/utils/views/picked-up';

export const viewMapping = {
  default: {
    id: 'default',
    view_definition: default_view,
    title: 'All Containers',
    icon: 'far fa-container-storage',
    order: 1,
  },
  needs_attention: {
    id: 'needs_attention',
    view_definition: needs_attention_view,
    title: 'Needs Attention',
    icon: 'far fa-sensor-alert',
    order: 2,
  },
  on_ship: {
    id: 'on_ship',
    view_definition: on_ship_view,
    title: 'On Ship',
    icon: 'fas fa-ship',
    order: 3,
  },
  arrived: {
    id: 'arrived',
    view_definition: arrived_view,
    title: 'Arrived',
    icon: 'fas fa-map-marker-check',
    order: 4,
  },
  picked_up: {
    id: 'picked_up',
    view_definition: picked_up_view,
    title: 'Picked Up',
    icon: 'fas fa-truck-container',
    order: 5,
  },
  empty_returned: {
    id: 'empty_returned',
    view_definition: empty_returned_view,
    title: 'Empty Returned',
    icon: 'fas fa-truck-loading',
    order: 6,
  },
};

export const viewsList = Object.values(viewMapping).sort((a, b) => a.order - b.order);
