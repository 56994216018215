/* eslint-disable ember/no-get */

import Service, { inject as service } from '@ember/service';
import { get } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import { camelize, capitalize } from '@ember/string';
import { getProps, setupObject } from 'tnt-ui/utils/fake-models';

const serverError = 'Woops! Something went wrong saving that NAME!';

export default class CrudService extends Service {
  @service message;
  @service store;

  setProperty = (object, attribute, value) => {
    object.set(attribute, value);
  };

  reload(model, relationshipName) {
    let relationshipsByName = get(model.constructor, 'relationshipsByName'),
      { kind } = relationshipsByName.get('users');

    return model[kind](relationshipName).reload();
  }

  fakeModel(modelName, props) {
    return setupObject(this, modelName, { props });
  }

  newRecord(modelName, attrs) {
    return this.store.createRecord(modelName, attrs);
  }

  /**
   * All edits are buffered, so those don't need rollback, but new models
   * ( buffered or not ) need to be unloaded from the store
   *
   * @param model
   */
  cancel(model) {
    model = model.get('content') || model;
    if (model.get('isNew')) {
      model.unloadRecord && model.unloadRecord();
    }
  }

  /**
   * Sometimes a buffer / model has a model fragment that needs rollback,
   * and while we can usually throw away an edit by throwing away a buffer,
   * the model fragment needs to be rolled back manually.
   *
   * @param model
   */
  rollback(model) {
    model = model.get('content') || model;
    model.rollbackAttributes();
  }

  create = (modelName, object) => {
    let attributes = getProps(this.store, modelName, object),
      model = this.store.createRecord(modelName, attributes),
      promise = model.save();

    promise.catch(() => {
      model.unloadRecord();
      let displayName = capitalize(camelize(modelName));
      this.message.error(serverError.replace('NAME', displayName));
    });

    return promise;
  };

  showError(showErrorMsg, model) {
    const hasErrors = model.errors ? !model.errors.isEmpty : false;
    const isValid = model.get('validations') ? model.get('validations.isValid') : true;
    let displayName = capitalize(camelize(model.constructor.modelName));

    if (hasErrors && isValid) {
      model.errors.messages.map((error) => {
        console.error(`Unhandled error: "${error}" for model: ${displayName}`);
      });
    }

    if (showErrorMsg && isValid) {
      this.message.error(serverError.replace('NAME', displayName));
    }
  }

  showSuccess(showMessage, successMessage, model) {
    if (showMessage || successMessage) {
      let modelName = capitalize(camelize(model.constructor.modelName)),
        message = successMessage || `${modelName} saved`;
      this.message.info(message);
    }
  }

  /**
   * Mostly the model being saved is a buffer, since all edits should be buffered,
   * but there are occasions when the model saved is plain model
   *
   * @param model Model or ProxyBuffer
   * @param showSuccess show a message on success
   * @param successMessage custom message to override the standard
   */
  save = (model, { showErrorMsg = true, showSuccess = false, successMessage } = {}) => {
    if (model instanceof ObjectProxy) {
      model = model.get('content');
    }
    const promise = model.save();
    promise
      .then(() => this.showSuccess(showSuccess, successMessage, model))
      .catch(() => this.showError(showErrorMsg, model));
    return promise;
  };
}
