import { isEmpty, isPresent } from '@ember/utils';

/**
 * Remove empty object / string / value from array
 *
 * @public
 * @param {Array} array
 */
export function removeEmpty(array) {
  return array.map((v) => (isEmpty(v) ? null : v)).compact();
}

export function intersect(array1, array2) {
  return array1.filter(function (item1) {
    return isPresent(array2.find((item2) => item1 === item2));
  });
}

export function subtract(array1, array2) {
  return array1.filter(function (item1) {
    return isEmpty(array2.find((item2) => item1 === item2));
  });
}
