import BaseValidator from 'ember-cp-validations/validators/base';

class UrlScheme extends BaseValidator {
  validate(value, options /*, model, attribute */) {
    if (value.startsWith('https') || value.startsWith('arn:aws:sns')) {
      return true;
    }

    return options.message;
  }
}

export default UrlScheme;
