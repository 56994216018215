import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountModel from 'tnt-ui/models/account';

export enum Resource {
  All = 'all',
  EmbeddedWidget = 'embedded_widget',
  EmbeddedMap = 'embedded_map',
}

export default class ApiKeyModel extends Model {
  @belongsTo('account') declare account: AccountModel;

  @attr('string') declare name: string;
  @attr('string') declare token: string;
  @attr('string', { defaultValue: Resource.All }) declare resource: Resource;
  @attr('boolean', { defaultValue: false }) declare isPublic: boolean;
  @attr('date') declare lastUsedAt: Date;
  @attr('date') declare createdAt: Date;
  @attr('date') declare disabledAt: Date;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'api-key': ApiKeyModel;
  }
}
