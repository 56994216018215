/* import __COLOCATED_TEMPLATE__ from './view-share.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import fetch from 'fetch';
import ENV from 'tnt-ui/config/environment';
import { saveDownloadedFile } from 'tnt-ui/utils/file';

export default class ModalsViewShareComponent extends Component {
  @service modals;
  @service notify;
  @service store;
  @service metrics;

  @tracked downloadState = 'idle';

  get containerView() {
    return this.args.content.containerView;
  }

  get publicShareURL() {
    return `${this.appURL}/share/${this.containerView?.id}`;
  }

  get publicExportURL() {
    return `${ENV.APP.API_HOST}/v2/containers/share/${this.containerView?.id}.csv`;
  }

  @action
  async toggleViewAnonymization(value) {
    const containerView = this.containerView;
    containerView.isAnonymized = value;

    if (value) {
      this.trackEvent({
        event: 'view_anonymize_enabled',
        view_id: containerView.get('id'),
      });
    } else {
      this.trackEvent({
        event: 'view_anonymize_disabled',
        view_id: containerView.get('id'),
      });
    }

    try {
      await containerView.save();

      this.notify.success('Successfully saved the container view.');
    } catch (error) {
      this.notify.error("Couldn't save the container view. Please try again.");
    }
  }

  @action
  async toggleViewSharing(value) {
    const containerView = this.containerView;
    containerView.isPublicShared = value;

    if (value) {
      this.trackEvent({
        event: 'view_sharing_enabled',
        view_id: containerView.get('id'),
      });
    } else {
      this.trackEvent({
        event: 'view_sharing_disabled',
        view_id: containerView.get('id'),
      });
    }

    await containerView.save();
  }

  @action
  closeModal() {
    this.modals.close('view-share');
  }

  get appURL() {
    return ENV.APP.APP_URL;
  }

  get viewShares() {
    const shares = this.containerView.containerViewShares;
    return shares.toArray().sort((a, b) => {
      // Sort by most recent first
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  }

  @action
  async onExportClick(e) {
    e.preventDefault();

    try {
      this.downloadState = 'running';
      const response = await fetch(this.publicExportURL);
      await saveDownloadedFile(response);
      this.downloadState = 'completed';
    } finally {
      later(
        this,
        () => {
          this.downloadState = 'idle';
        },
        1500,
      );
    }
  }

  @task
  *removeViewShare(viewShare) {
    const { recipientName, recipientEmail, containerView } = viewShare;

    try {
      this.trackEvent({
        event: 'view_granular_share_disabled',
        view_id: containerView.get('id'),
        target_email: recipientEmail,
      });

      yield viewShare.destroyRecord();

      this.notify.success(`Successfully removed the link that you shared with ${recipientName}.`);
    } catch (e) {
      console.log(e);
      this.notify.error(`Could not remove the link you shared with ${recipientName}.  Please try again.`);
    }
  }

  @task
  *resendViewShare(viewShare) {
    const { recipientName, recipientEmail, containerView } = viewShare;

    try {
      this.trackEvent({
        event: 'view_granular_share_resend',
        view_id: containerView.get('id'),
        target_email: recipientEmail,
      });

      yield viewShare.resendEmail();

      this.notify.success(`Successfully resent the link that you shared with ${recipientName}.`);
    } catch (e) {
      console.log(e);
      this.notify.error(`Could not resend the link you shared with ${recipientName}.  Please try again.`);
    }
  }

  @action
  trackEvent(attrs) {
    this.metrics.trackEvent({
      ...attrs,
      source: 'Container Dashboard',
    });
  }
}
