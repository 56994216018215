/* import __COLOCATED_TEMPLATE__ from './feedback-form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/application';
import EmberObject, { action } from '@ember/object';
import { later } from '@ember/runloop';
import { isEqual } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { UploadFile } from 'ember-file-upload';
import html2canvas from 'html2canvas';
import moment from 'moment-timezone';
import delay from 'tnt-ui/utils/delay';
import { uploadFileToS3 } from 'tnt-ui/utils/upload-to-s3';
import Validations from 'tnt-ui/utils/validations/feedback';

class Feedback extends EmberObject.extend(Validations) {
  @service fetch;

  @action
  async save() {
    await this.fetch.nonJsonRequest('/v2/feedbacks', {
      method: 'POST',
      body: JSON.stringify({
        data: {
          attributes: this,
          type: 'feedbacks',
        },
      }),
    });
  }
}

export default class ModalsFeedbackFormComponent extends Component {
  @service router;
  @service modals;
  @service message;

  @tracked model;
  @tracked errorMessage = '';
  @tracked screenshot = '';
  @tracked includeScreenshot = this.args.content.includeCheckbox || false;
  @tracked showSuccess = false;
  @tracked choiceSelected = 'positive_feedback';

  get thumbsUp() {
    return isEqual(this.choiceSelected, 'positive_feedback');
  }

  get thumbsDown() {
    return isEqual(this.choiceSelected, 'negative_feedback');
  }

  unknownProperty(key) {
    return this.args[key] || this.args.content?.[key];
  }

  @action
  onDidInsert() {
    this.model = Feedback.create(getOwner(this).ownerInjection(), {
      board_id: this.args.content.boardId,
      category_id: this.args.content.categoryId,
    });

    this.args.content.onFeedbackOpened?.();
  }

  @action
  onInclude({ target }) {
    this.includeScreenshot = target.checked;
  }

  async takeScreenshot() {
    try {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const video = document.createElement('video');
      const captureStream = await navigator.mediaDevices.getDisplayMedia({ preferCurrentTab: true });
      this.hideModal();
      await delay(this, 500);
      video.srcObject = captureStream;
      await video.play();
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0);
      captureStream.getTracks().forEach((track) => track.stop());
      this.screenshot = canvas.toDataURL('image/png');
    } catch {
      this.hideModal();
      const canvas = await html2canvas(document.body, {
        logging: false,
        windowWidth: 1920,
        windowHeight: 1080,
      });
      this.screenshot = canvas.toDataURL('image/png');
    }
  }

  async uploadFile() {
    await this.takeScreenshot();
    const file = UploadFile.fromDataURL(this.screenshot);
    file.name = moment().utc();

    const response = await uploadFileToS3(file, { folder: 'feedbacks' });
    return response.querySelector('Location').textContent;
  }

  @task
  *onSave() {
    this.errorMessage = '';

    try {
      const { validations } = yield this.model.validate();

      if (validations.get('isValid')) {
        try {
          this.model.set('tags', [this.choiceSelected, ...(this.args.content.tags ?? [])]);
          this.model.set('current_url', window.location.href);

          if (this.includeScreenshot) {
            const s3Path = yield this.uploadFile();
            this.model.set('screenshot_url', s3Path);
          }

          yield this.model.save();
          this.showSuccess = true;
          this.message.info('We have receieved your feedback. Thank you.');
          this.args.content.onFeedbackSent?.();
          this.closeModal();
        } catch (e) {
          console.log(e);
          this.message.error('An error occured. Please try again.');
        }
      } else {
        this.errorMessage = 'Please fix all the errors below before continuing.';

        later(
          this,
          () => {
            this.errorMessage = '';
          },
          3000,
        );
      }

      this.model.set('didValidate', true);
    } catch {
      return;
    }
  }

  @action
  hideModal() {
    ['modal-overlays', 'containers-infinity'].forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = 'none';
      }
    });
  }

  @action
  closeModal() {
    if (!this.showSuccess) {
      this.args.content.onFeedbackClosed?.();
    }
    this.modals.close('feedback-form');
  }
}
