import { modifier } from 'ember-modifier';

const handlersQueue = [];

export default modifier(
  (_, [handler]) => {
    handlersQueue.addObject(handler);

    const onEsc = (e) => {
      if (e.key === 'Escape' && !e.shiftKey) {
        e.preventDefault();
        const lastHandler = handlersQueue[handlersQueue.length - 1];
        if (lastHandler === handler) {
          handler();
        }
      }
    };

    document.addEventListener('keyup', onEsc);

    return () => {
      handlersQueue.removeObject(handler);
      document.removeEventListener('keyup', onEsc);
    };
  },
  { eager: false },
);
