import { filtersMap } from 'harbor/utils/filters/filter-definitions';
import columnDefinitions from 'tnt-ui/utils/views/column-definitions';

const columns = [
  'container_number',
  'eq_type',
  'shipment_number',
  'customer_name',
  'shipment_tags',
  'pod_terminal_name',
  'pod_arrived_at',
  'picked_up_at',
  'days_since_pickup',
  'empty_returned_at',
  'current_issues',
];

export default {
  columns: columns.map((column) => columnDefinitions[column]),
  filters: {
    ...Object.fromEntries(Array.from(filtersMap.values()).map((v) => [v.queryParam, v.defaultValue])),
    filterHasArrived: true,
    filterReturned: false,
    filterActivelyTracked: true,
  },
  sort: ['-empty_terminated_at'],
};
