/* import __COLOCATED_TEMPLATE__ from './view-settings.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class ModalsViewSettingsComponent extends Component {
  @service modals;
  @service notify;

  get hideToggle() {
    return Boolean(this.args.content.hideToggle);
  }

  get containerView() {
    return this.args.content.containerView;
  }

  @task
  *saveViewTask() {
    yield this.containerView.save();

    this.notify.success('Settings saved successfully');
    this.closeModal();
  }

  @action
  closeModal() {
    this.modals.close('view-settings');
  }
  @action
  toggleShowInDashboard() {
    this.containerView.settings.showInDashboard = !this.containerView.settings.showInDashboard;
  }
  @action
  toggleCheck(attr) {
    this.containerView.settings[attr] = !this.containerView.settings[attr];
  }
}
