import Model, { attr } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import { inject as service } from '@ember/service';
import type SessionService from 'tnt-ui/services/session';

export default class FeatureFlagModel extends Model {
  @service declare store: StoreService;
  @service declare session: SessionService;

  @attr('string') declare key: string;
  @attr('string') declare name: string;
  @attr declare accountIds: string[];
  @attr('boolean') declare earlyAccess: boolean;
  @attr('string') declare earlyAccessFeatureId: string;

  get accountIncluded() {
    return (this.accountIds ?? []).includes(this.session.currentUser.currentAccount.id);
  }

  async toggleAccount() {
    const adapter = this.store.adapterFor('feature-flag' as never) as any;
    await adapter.toggleAccount(this.id);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'feature-flag': FeatureFlagModel;
  }
}
