import { helper } from '@ember/component/helper';
import { isNone, isPresent } from '@ember/utils';
import { humanize } from '../humanize';

export function lenTypeHeight([cargo], { abbreviated }) {
  const {
    containerLength: length,
    containerType: type,
    containerHeight: height,
  } = cargo.getProperties(['containerLength', 'containerType', 'containerHeight']);
  let unknownValues = [],
    knownValues = [],
    string = '';

  if (abbreviated) {
    return displayAbbrivated(length, type, height);
  }

  if (isNone(length)) {
    unknownValues.pushObject('length');
  } else {
    knownValues.pushObject(length + "'");
  }
  if (isNone(type)) {
    unknownValues.pushObject('type');
  } else {
    knownValues.pushObject(type);
  }
  if (isNone(height)) {
    unknownValues.pushObject('height');
  } else {
    knownValues.pushObject(height);
  }
  if (knownValues.length) {
    string = knownValues.join(' ');
  }
  if (unknownValues.length) {
    string = string + ' (no ' + unknownValues.join(', ') + ')';
  }
  return humanize([string]);
}

function displayAbbrivated(length, type, height) {
  let typeHeight = '';
  if (isPresent(type)) {
    typeHeight = type.charAt(0);
  }
  if (isPresent(height)) {
    typeHeight = typeHeight + height.charAt(0);
  }
  return `${length}' ${typeHeight}`.toUpperCase();
}

export default helper(lenTypeHeight);
