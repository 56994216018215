import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  number: [
    validator('container-number', {
      presence: true,
    }),
    validator('ds-error'),
  ],
});
