/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-classic-components, ember/no-computed-properties-in-native-classes, ember/no-get, ember/require-computed-property-dependencies, ember/require-tagless-components */
import Component from '@ember/component';
import { action, computed, get, set } from '@ember/object';

export default class FormFor extends Component {
  classNameBindings = ['formName'];

  @computed('model', 'customFormName')
  get formName() {
    if (this.customFormName) {
      return this.customFormName;
    }
    if (this.model) {
      let modelName = this.get('model.constructor.modelName') || this.get('model.content.constructor.modelName');
      return modelName + '-form';
    }
    return '';
  }

  /**
   * Using this intermediary so that you can override setProperty on the
   * form level OR individually on a control
   *
   * @param model
   * @param prop
   * @param value
   */
  @action
  setAttr(model, prop, value) {
    const afterSetProperty = this.afterSetProperty;

    set(model, prop, value);
    afterSetProperty && afterSetProperty(model, prop, value, this);
  }

  /**
   * This submit handles showing errors, checking for validations, and
   * showing a waiting spinner.
   *
   * @param model model being saved
   * @param save the actual save action you want
   */
  @action
  submit(model, save) {
    this.set('showErrors', true);
    this.beforeValidation && this.beforeValidation(model);
    if (this.canModelBeSaved(model)) {
      this.saveForm(model, save);
    } else {
      console.error('Validations failed for form: ' + this.formName);
    }
  }

  // private methods

  saveForm(model, action) {
    this.set('isSaving', true);

    return action().finally(this.afterSave(model));
  }

  afterSave = (model) => {
    return () => {
      if (!(this.isDestroyed || this.isDestroying)) {
        const hasValidationError = get(model, 'validations') ? !get(model, 'validations.isValid') : false;
        const showErrors = get(model, 'errors')?.length || hasValidationError;
        this.setProperties({
          isSaving: false,
          showErrors,
        });
      }
    };
  };

  canModelBeSaved(model) {
    return !get(model, 'validations') || get(model, 'validations.isValid');
  }
}

FormFor.reopenClass({
  positionalParams: ['model'],
});
