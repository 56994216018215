import V2ApiSerializer from './v2-api';

export default class UserContainerSerializer extends V2ApiSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    const userContainers = Array.isArray(payload.data) ? payload.data : [payload.data];

    userContainers.forEach((container) => {
      container.type = 'user_container';
      container.relationships.cargo.data.type = 'container';
    });

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
