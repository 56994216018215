import { helper } from '@ember/component/helper';
import moment from 'moment-timezone';

export function appointmentDate(args) {
  let newDate = moment(args[0][1]);

  let timeZone = args[1];
  if (timeZone) {
    newDate = newDate.tz(timeZone);
  }

  if (!newDate.isValid()) {
    return 'Unset';
  } else if (newDate.creationData().format == 'YYYY-MM-DD') {
    return newDate.format('M/D');
  } else {
    return newDate.format('M/D h:mma');
  }
}

export default helper(appointmentDate);
