import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';

export function breaklines(text) {
  let string = htmlSafe(text).string;
  string = string.replace(/\r\n?/g, '\n').trim();
  if (string.length > 0) {
    string = string.replace(/\n\s*\n/g, '</p><p>');
    string = string.replace(/\n/g, '<br />');
  }
  return htmlSafe(`<p>${string}</p>`);
}
export default helper(breaklines);
