import { isBlank } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';
import moment from 'moment-timezone';

export default class TimeRange extends BaseValidator {
  validate(value, options, model) {
    if (isBlank(options.dependentKeys) || options.dependentKeys.length !== 1) {
      throw new Error("time-range validator requires a single key in dependentKeys that is the 'to' time.");
    }
    if (options.dependentKeys[0].substr(0, 5) !== 'model') {
      throw new Error('time-range dependent key must be a model key');
    }
    if (options.allowBlank && isBlank(value)) {
      return true;
    } else {
      let attributeKey = options.dependentKeys[0].substr(6),
        toValue = model.get(attributeKey),
        toMoment = moment(toValue, 'HH:mm', true),
        fromMoment = moment(value, 'HH:mm', true);
      if (fromMoment.isBefore(toMoment) || !toMoment.isValid()) {
        return true;
      }
      return options.message ? options.message : 'Check time range';
    }
  }
}
