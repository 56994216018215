import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  contractLength: validator('presence', {
    presence: true,
  }),
  billingPeriod: validator('presence', {
    presence: true,
  }),
  startDate: validator('presence', {
    presence: true,
  }),
  expectedContainersAnnual: validator('presence', {
    presence: true,
  }),
  expectedDashboardSeats: validator('presence', {
    presence: true,
  }),
});
