/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isEqual, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { UploadFile } from 'ember-file-upload';
import moment from 'moment-timezone';
import { uploadFileToS3 } from 'tnt-ui/utils/upload-to-s3';

export default class ReportIssueModalComponent extends Component {
  @service session;
  @service store;
  @service notify;
  @service log;

  @tracked selectedEntity = null;
  @tracked selectedIssueAttribute = null;
  @tracked selectedIssueType = null;
  @tracked additionalDetails = null;
  @tracked showErrors = false;

  shipmentFields = [
    { name: 'Steamship Line', field: 'steamship_line' },
    {
      name: 'No. of Containers in Shipment',
      field: 'number_of_containers_in_shipment',
    },
    { name: 'Reference Numbers', field: 'reference_numbers' },
    { name: 'Tags', field: 'tags' },
    { name: 'Port of Lading (POL)', field: 'port_of_lading' },
    { name: 'Port of Discharge (POD)', field: 'port_of_discharge' },
    { name: 'Inland Destination', field: 'destination' },
    { name: 'Terminal Firms Code (POD)', field: 'pod_terminal_firms' },
    { name: 'ETD (POL)', field: 'pol_etd' },
    { name: 'ATD (POL)', field: 'pol_atd' },
    { name: 'ETA (POD)', field: 'pod_eta' },
    { name: 'ATA (POD)', field: 'pod_ata' },
    { name: 'Terminal (POD)', field: 'terminal_pod' },
    { name: 'POD Vessel', field: 'arrival_vessel' },
    { name: 'Voyage No.', field: 'voyage_number' },
  ];

  containerFields = [
    { name: 'Stale Container Data', field: 'stale_container_data' },
    {
      name: 'Container Details (weights, chassis no., container type)',
      field: 'container_details',
    },
    { name: 'Container Status', field: 'availability_status' },
    { name: 'Holds', field: 'holds' },
    { name: 'Fees', field: 'fees' },
    { name: 'Last Free Day (LFD)', field: 'last_free_day' },
    {
      name: 'Container (Current) Location',
      field: 'container_current_location',
    },
    { name: 'Vessel/Voyage', field: 'vessel' },
    { name: 'Empty Out Milestone', field: 'empty_out_milestone' },
    { name: 'Full in Milestone', field: 'full_in_milestone' },
    { name: 'Empty in Milestone', field: 'empty_in_milestone' },
    { name: 'Vessel Loaded Milestone', field: 'vessel_loaded_milestone' },
    { name: 'Vessel Departed Milestone', field: 'vessel_departed_milestone' },
    { name: 'Vessel Arrived Milestone', field: 'vessel_arrived_milestone' },
    {
      name: 'Vessel Discharged Milestone',
      field: 'vessel_discharged_milestone',
    },
    {
      name: 'Transhipment Loaded Milestone',
      field: 'transhipment_loaded_milestone',
    },
    {
      name: 'Transhipment Departed Milestone',
      field: 'transhipment_departed_milestone',
    },
    {
      name: 'Transhipment Arrived Milestone',
      field: 'transhipment_arrived_milestone',
    },
    {
      name: 'Transhipment Discharged Milestone',
      field: 'transhipment_discharged_milestone',
    },
    { name: 'Rail Departed Milestone', field: 'rail_departed_milestone' },
    { name: 'Full Out', field: 'full_out' },
  ];

  issueTypes = ['incorrect', 'missing', 'other'];

  constructor() {
    super(...arguments);
    if (this.args.container) {
      this.selectedEntity = this.entitiesWithIssues.find(
        (e) => isPresent(e.container) && e.container.id === this.args.container.id,
      );
    }
  }

  get entitiesWithIssues() {
    const containers = this.args.shipment.get('cargos').map((container) => {
      return {
        category: 'container',
        label: `Container ${container.number}`,
        requiresField: true,
        container,
      };
    });

    return [
      { category: 'shipment_overview', label: 'Shipment Overview', requiresField: true },
      ...containers,
      { category: 'other', label: 'Other', requiresField: false },
      { category: 'shipment_map', label: 'Shipment Map', requiresField: false },
    ];
  }

  get containerIsSelected() {
    return isPresent(this.selectedEntity) && isEqual(this.selectedEntity.category, 'container');
  }

  get fieldsWithErrors() {
    if (this.containerIsSelected) {
      return this.containerFields;
    } else {
      return this.shipmentFields;
    }
  }

  @task
  *createIssue() {
    const file = UploadFile.fromDataURL(this.args.screenshot);
    file.name = moment().utc();

    let params = {
      user: this.session.currentUser,
      account: this.session.currentUser.currentAccount,
      shipment: this.args.shipment,
      issueCategory: isPresent(this.selectedEntity) ? this.selectedEntity.category : null,
      issueType: this.selectedIssueType,
      issueAttribute: isPresent(this.selectedIssueAttribute) ? this.selectedIssueAttribute.field : null,
      additionalDetails: this.additionalDetails,
      status: 'new',
      ctaSource: this.args.source,
    };

    if (this.containerIsSelected) {
      params.cargo = this.selectedEntity.container;
    }

    let issue = this.store.createRecord('reported-issue', params);

    if (issue.validations.isValid) {
      try {
        let responseXML = yield uploadFileToS3(file, { folder: 'reported_issues' });
        const s3Path = responseXML.querySelector('Key').textContent;
        issue.set('screenshotPath', s3Path);

        this.args.reportIssue(issue);
        this.args.close();
      } catch (error) {
        this.notify.error('There was an error while reporting the issue');
        this.log.error(error);
      }
    } else {
      this.showErrors = true;
    }
  }
}
