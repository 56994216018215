/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 * @param {Array<ProductModel>}        products
 * @param {UserModel}                  currentUser
 */
export default class ProductProductList extends Component {
  @service store;

  @action
  newProduct() {
    const { currentUser } = this.args;
    this.store.createRecord('product', {
      account: currentUser.currentAccount,
      user: currentUser,
    });
  }
}
