import BaseValidator from 'ember-cp-validations/validators/base';

class PhoneNumber extends BaseValidator {
  validate(_value, options, model, attribute) {
    if (model[`${attribute}Valid`]) {
      return true;
    }

    return options.message;
  }
}

export default PhoneNumber;
