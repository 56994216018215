import V2ApiSerializer from './v2-api';

// Translates Client-side modelNames to the ones required by API
const SERIALIZE_RELATIONSHIP_TYPE_MAPPING = {
  'shipment-v2': 'shipment',
  container: 'cargo',
};

// Translates Server-side modelNames to the ones used by Client.
const DESERIALIZE_RELATIONSHIP_TYPE_MAPPING = {
  shipment: 'shipment-v2',
  cargo: 'container',
};

export default class NoteV2Serializer extends V2ApiSerializer {
  payloadKeyFromModelName(modelName) {
    const serverSideMapping = SERIALIZE_RELATIONSHIP_TYPE_MAPPING[modelName];
    if (serverSideMapping) {
      return serverSideMapping;
    } else {
      return super.payloadKeyFromModelName(modelName);
    }
  }

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    const notes = Array.isArray(payload.data) ? payload.data : [payload.data];

    notes.forEach((note) => {
      const notable = note?.relationships?.notable?.data;
      const clientSideMapping = DESERIALIZE_RELATIONSHIP_TYPE_MAPPING[notable?.type];
      if (clientSideMapping) {
        notable.type = clientSideMapping;
      }
    });

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
