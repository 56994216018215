/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import currentSession from 'tnt-ui/decorators/current-session';

@currentSession
export default class CargoTruckerAssignmentComponent extends Component {
  STATUS_MAP = {
    accepted: 'Assigned to',
    waiting: 'Offered to',
    expired: 'No response from',
    declined: 'Declined by',
    unoffered: '',
  };

  get assignmentClass() {
    if (this.args.showTruckingDetails) {
      return 'offer-' + this.args.cargo.offerStatus;
    }
    return 'no-assignment-status';
  }

  get assignmentStatus() {
    return this.STATUS_MAP[this.args.cargo.offerStatus];
  }
}
