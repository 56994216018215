/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-get */

import Service, { inject as service } from '@ember/service';
import { isBlank, isEmpty } from '@ember/utils';
import currentSession from 'tnt-ui/decorators/current-session';

const CHANNEL_ACTIVITIES = ['booking-order', 'shipment'];

@currentSession
export default class ActivityManagerService extends Service {
  @service('app-socket') appSocket;

  @service store;

  _subscription = null;

  setupSocket(reconnect = false) {
    if (this.get('session.isAuthenticated') && isEmpty(this._subscription)) {
      const authToken = this.get('session.data.authenticated.access_token');
      let connection = this.appSocket.setupSocket(authToken, reconnect);
      if (connection && isBlank(this._subscription)) {
        this._setupActivityChannel(connection);
      }
    }
  }

  remove() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  _setupActivityChannel(connection) {
    let subscription = connection.subscriptions.create('ActivityChannel', {
      connected: () => {
        window.console.debug('ActivityChannel:Connected');
      },
      received: (data) => {
        window.console.debug('ActivityChannel:Data Received', data);
        if (data.data == undefined) {
          return;
        }
        let activity = this._storeActivity(data);
        let subject = activity.get('subject.content.constructor.modelName');

        if (CHANNEL_ACTIVITIES.includes(subject)) {
          activity.get('subject.activities').pushObject(activity);
        }

        this.currentUser.incrementProperty('unreadActivitiesCount');
      },
      disconnected() {
        window.console.debug('ActivityChannel:disconnected');
      },
    });
    this.set('_subscription', subscription);
  }

  _storeActivity(payload) {
    let store = this.store;
    let modelName = payload.data.type;
    let modelClass = store.modelFor(modelName);
    let serializer = store.serializerFor(modelName);
    let normalizedPayload = serializer.normalizeSingleResponse(store, modelClass, payload, null, true);
    return store.push(normalizedPayload);
  }
}
