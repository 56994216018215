/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-computed-properties-in-native-classes, ember/no-get, ember/require-computed-property-dependencies, ember/use-brace-expansion */

import { inject as service } from '@ember/service';
import { action, computed, get } from '@ember/object';
import { filterBy, reads, sort } from '@ember/object/computed';
import { dasherize } from '@ember/string';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import BookingCargoListItem from 'tnt-ui/components/booking-cargo-list-item';
import { isCustomsPending } from 'tnt-ui/schemas/hold';

export default class ContainerListItem extends BookingCargoListItem {
  @service store;
  @service session;
  @service metrics;
  @service timezone;

  @tracked container;

  classNameBindings = ['showTruckerDetails:has-trucker'];
  classNames = ['cargo-item'];
  showIssues = false;
  isTruckerAccount = false;
  showTrucking = true;

  @computed('cargo.currentStatus')
  get statusClassName() {
    return dasherize(this.cargo.get('currentStatus'));
  }

  @computed('cargo.currentStatus', 'cargo.podTerminal.firmsCode')
  get showFirmsCode() {
    const includeStatus = ['grounded', 'available', 'not_available', 'picked_up'].includes(
      this.cargo.get('currentStatus'),
    );

    return isPresent(get(this.cargo, 'podTerminal.firmsCode')) && includeStatus;
  }

  get terminalOfDelivery() {
    return this.get('shipment.podTerminal.name');
  }

  @reads('cargo.cntnr') cntnr;
  @reads('cargo.shipment') shipment;

  get lfd() {
    return this.container?.get('indFacilityLfdOn') ?? this.cargo.get('lastFreeDayOn');
  }

  get timeZone() {
    if (this.container?.get('indFacilityLfdOn')) {
      return this.container?.get('pickupFacilityTimeZone');
    } else if (this.cargo.get('lastFreeDayOn')) {
      return this.shipment.get('portOfDischarge.timeZone');
    } else {
      return '';
    }
  }

  get lastFreeDayInTz() {
    return this.timezone.dateInTz(this.lfd, this.timeZone);
  }

  get timeZoneAbbreviation() {
    return this.timezone.timeZoneAbbr(this.lfd, this.timeZone);
  }

  updateSortDesc = ['eventTimestamp:desc'];
  @sort('cntnr.terminalContainerUpdates', 'updateSortDesc') allContainerEvents;

  @filterBy('allContainerEvents', 'isNew', false) containerEvents;

  @computed('containerEvents')
  get lastEvent() {
    return this.containerEvents.firstObject;
  }

  get cargoHolds() {
    let holds = this.cargo.holds || [];
    let isDeliveredOrPickedUp = this.cargo.delivered || this.cargo.pickedUp;
    return holds.reject((hold) => isCustomsPending(hold) && isDeliveredOrPickedUp);
  }

  onToggleUserContainer = task(async () => {
    let userCargo = await this.cargo.get('userCargo');

    if (userCargo) {
      await userCargo.destroyRecord();
    } else {
      userCargo = this.store.createRecord('user-cargo', {
        cargo: this.cargo,
        user: this.session.currentUser,
      });

      await userCargo.save();
    }
  });

  @action
  async loadContainer() {
    this.container =
      this.store.peekRecord('container', this.cargo.get('id')) ??
      (await this.store.findRecord('container', this.cargo.get('id')));
  }

  @action
  sendEvent() {
    this.trackEvent({
      event: this.showingAdditionalDetails ? 'container_info_expanded' : 'container_info_collapsed',
      shipment_id: this.cargo.get('shipment.id'),
      container_id: this.cargo.get('id'),
    });
  }

  @action
  trackEvent(attrs) {
    this.metrics.trackEvent({
      ...attrs,
      source: 'Shipments',
    });
  }
}
