/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { mapBy, or, sum } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import { hasStatus } from '../utils/decorators';

export const Draft = 'draft',
  Open = 'open',
  Reviewing = 'reviewing',
  Pending = 'pending',
  Submitted = 'submitted',
  Canceled = 'canceled',
  Paid = 'paid',
  statusNames = [Draft, Open, Reviewing, Pending, Submitted, Canceled, Paid];

@hasStatus(statusNames, 'currentStatus')
export default class PayoutModel extends Model {
  @attr('string') refToken;
  @attr('string', { defaultValue: Draft }) currentStatus;
  @attr('date') currentStatusAt;
  @attr('number') paidAmount;
  @attr('boolean') automated;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr('date') payoutOn;
  @attr('date') reviewOn;
  @attr('date') approveOn;
  @attr('date') paidAt;
  @attr('string') svbAchStatus;
  @attr('string') paymentDetails;
  @attr('string') cancelReason;
  @attr('array') versions;

  @belongsTo('booking-order', { async: false }) bookingOrder;
  @belongsTo('account', { async: false }) truckingAccount;

  @hasMany('charge') charges;
  @hasMany('cargo') cargos;

  @mapBy('charges', 'amount') chargeAmounts;
  @sum('chargeAmounts') totalAmount;

  @or('isOpen', 'isReviewing') unApproved;

  @computed('versions')
  get statusChangeVersion() {
    const Statuses = {
      reviewing: 'charges submitted',
      pending: 'approved',
      submitted: 'payment initiated',
    };

    let versions = this.versions || [];
    return versions
      .filter((version) => isPresent(version.changes['current-status']))
      .map((version) => {
        let newStatus = Statuses[version.changes['current-status'][1]] || version.changes['current-status'][1];
        return {
          status: newStatus,
          timestamp: version['created-at'],
        };
      });
  }
}
