/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import html2canvas from 'html2canvas';
import currentSession from 'tnt-ui/decorators/current-session';

@currentSession
export default class CargoDisplayUiComponent extends Component {
  @service session;
  @service metrics;

  @tracked showReportIssueModal = false;
  @tracked screenshot = '';
  @tracked editCargo = false;
  @tracked showDetails = this.args.showDetails || this.autoExpandDetails();

  get showForm() {
    return this.editCargo || this.args.cargo.isNew;
  }

  @action
  closeEditingMode() {
    this.editCargo = false;
  }

  async takeScreenshot() {
    const canvas = await html2canvas(document.body);
    this.screenshot = canvas.toDataURL('image/png');
  }

  @task openReportIssueModal = function* () {
    yield this.takeScreenshot();

    yield (this.showReportIssueModal = true);
  };

  @action
  visitContainerDebugger() {
    window.open(`https://bi.t49.co/dashboard/173-container-debugging?container_id=${this.args.cargo.id}`, '_blank');
  }

  @action
  sendEventForEdit() {
    if (this.editCargo) {
      this.trackEvent({
        event: 'container_edit_clicked',
        shipment_id: this.args.shipment?.id,
        container_id: this.args.cargo.id,
      });
    }
  }

  @action
  sendEventForReport() {
    if (this.showReportIssueModal) {
      this.trackEvent({
        event: 'container_report_clicked',
        shipment_id: this.args.shipment?.id,
        container_id: this.args.cargo.id,
      });
    }
  }

  @action
  trackEvent(attrs) {
    this.metrics.trackEvent({
      ...attrs,
      source: 'Shipments',
    });
  }

  autoExpandDetails() {
    return Boolean(this.args.shipment?.cargos.length === 1);
  }
}
