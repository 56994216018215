import { camelize, dasherize } from '@ember/string';
import { isEmpty, typeOf } from '@ember/utils';

/**
 * Remove keys with empty values.
 *
 * @public
 * @param obj
 * @returns {{}} new object with keys removed if value was empty
 */
export function compact(obj: any) {
  const newObj = {};
  for (const [key, value] of entries(obj)) {
    if (!isEmpty(value)) {
      // @ts-ignore
      newObj[key] = value;
    }
  }
  return newObj;
}

function isNullParam(value: unknown) {
  return isEmpty(value) || ('string' === typeof value && (value === 'null' || value === 'undefined'));
}

/**
 * For query param manipulations. In place modification
 *
 * When params come is as null, they are converted to "null" by ember,
 * and this function resets them to undefined
 *
 * @public
 * @param obj
 * @returns {*}
 */
export function nullKeysToUndefined(obj: { [x: string]: undefined }) {
  for (const [key, value] of entries(obj)) {
    if (isNullParam(value)) {
      obj[key] = undefined;
    }
  }
  return obj;
}

/**
 * Does object have any non empty values?
 *
 * @public
 * @param {Object} obj
 * @returns {boolean}
 */
export function isEmptyObject(obj: any) {
  if (typeOf(obj) !== 'object') {
    return true;
  }
  const newObj = compact(obj);
  return !Object.keys(newObj).length;
}

/**
 * Iterator for object key, values
 *
 * @public
 * @param obj
 */
export function* entries(obj: { [x: string]: any }) {
  for (const key of Object.keys(obj)) {
    yield [key, obj[key]];
  }
}

export function dasherizeKeys(obj: { [x: string]: any }) {
  if (!obj) {
    return obj;
  }

  return Object.keys(obj).reduce((acc, key) => {
    // @ts-ignore
    acc[dasherize(key)] = obj[key];
    return acc;
  }, {});
}

export function camelizeKeys(obj: { [x: string]: any }) {
  if (!obj) {
    return obj;
  }

  return Object.keys(obj).reduce((acc, key) => {
    // @ts-ignore
    acc[camelize(key)] = obj[key];
    return acc;
  }, {});
}
