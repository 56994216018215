import { isBlank } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';
import ContainerUtil from 'tnt-ui/utils/container-isocheck';

export default class ContainerNumber extends BaseValidator {
  validate(value, options) {
    if (options.allowBlank && isBlank(value)) {
      return true;
    } else {
      if (ContainerUtil.ISOCheck(value)) {
        return true;
      } else {
        return isBlank(value) ? 'Number required' : 'Invalid container number';
      }
    }
  }
}
