import { inject as service } from '@ember/service';
import V2ApiAdapter from './v2-api';

export default class ContainerViewAdapter extends V2ApiAdapter {
  @service router;

  urlForFindAll(_, { adapterOptions }) {
    const include = adapterOptions?.include;
    const url = super.urlForFindAll(...arguments);
    return include ? `${url}?include=${include}` : url;
  }

  handleResponse(status, headers, payload, requestData) {
    const shareId = requestData.url.split('/').lastObject;
    if ([401, 403, 404].includes(status)) {
      this.router.transitionTo('share/not-found', shareId);
      return;
    }
    if (status === 500) {
      this.router.transitionTo('share/error', shareId);
      return;
    }
    return super.handleResponse(status, headers, payload, requestData);
  }

  push(id) {
    const url = this.buildURL('container-view', id) + '/push';
    return this.ajax(url, 'PATCH', {});
  }

  pull(id) {
    const url = this.buildURL('container-view', id) + '/pull';
    return this.ajax(url, 'PATCH', {});
  }

  /**
   * @param {ContainerView} model
   * @return @return {Promise}
   */
  async clone(model, count = 0) {
    const { data } = model.serialize({ includeId: true });

    const title = model.title;
    data.attributes['title'] = `${title} - ${count + 1}`;

    const url = this.buildURL('container-view', model.id) + '/clone';
    return this.ajax(url, 'POST', { data: { data } });
  }
}
