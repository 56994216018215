function generateCurvePoints(latlng1, latlng2) {
  const latlngs = [];

  const offsetX = latlng2[1] - latlng1[1];
  const offsetY = latlng2[0] - latlng1[0];

  const r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2));
  const theta = Math.atan2(offsetY, offsetX);

  const thetaOffset = 3.14 / 10;

  const r2 = r / 2 / Math.cos(thetaOffset);
  const theta2 = theta + thetaOffset;

  const midpointX = r2 * Math.cos(theta2) + latlng1[1];
  const midpointY = r2 * Math.sin(theta2) + latlng1[0];

  const midpointLatLng = [midpointY, midpointX];

  latlngs.push(latlng1, midpointLatLng, latlng2);

  return latlngs;
}

export default generateCurvePoints;
