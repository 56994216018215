/* eslint-disable prettier/prettier */
//@ts-ignore
import V2ApiAdapter from './v2-api';
import { service } from '@ember/service';

import type JSONAPIAdapter from '@ember-data/adapter/json-api';
import type TrackingRequestModel from 'tnt-ui/models/tracking-request';
import type StoreService from '@ember-data/store';

class TrackingRequestAdapter extends V2ApiAdapter {
  @service declare store: StoreService;

  async resubmit(trackingRequest: TrackingRequestModel) {
    const response = await fetch(`${this.buildURL('tracking-request', trackingRequest.id)}/resubmit`, {
      method: 'PATCH',
      headers: {
        ...this.headers,
        'Content-Type': 'application/json',
      },
    });

    const data = await response.json();

    if (this.isSuccess(response.status, {}, {})) {
      this.store.pushPayload('tracking-request', data);
    } else {
      throw data.errors[0]?.detail;
    }
  }
}

interface TrackingRequestAdapter extends JSONAPIAdapter {}

export default TrackingRequestAdapter;
