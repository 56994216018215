import Model, { attr } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';

const Validations = buildValidations({
  name: validator('presence', { presence: true, description: 'Name' }),
  phone: validator('presence', { presence: true, description: 'Phone number' }),
  email: {
    validators: [
      validator('presence', { presence: true, description: 'Email' }),
      validator('format', { type: 'email' }),
    ],
  },
  company: validator('presence', {
    presence: true,
    description: 'Company name',
  }),
  role: validator('presence', { presence: true, description: 'Role' }),
  containers: validator('presence', {
    presence: true,
    description: 'Containers imported',
  }),
  uses: validator('array-not-empty', { description: 'Use case' }),
});

export default class LeadModel extends Model.extend(Validations) {
  @attr('string') name;
  @attr('string') email;
  @attr('string') phone;
  @attr('string') company;
  @attr('string') utmSource;
  @attr('string') utmCampaign;
  @attr('string') utmMedium;
  @attr('string') utmTerm;
  @attr('string') utmContent;
  @attr('string') intercomVisitorId;
  @attr('string') role;
  @attr('string') containers;
  @attr('array', { defaultValue: () => [] }) uses;
}
