import { helper } from '@ember/component/helper';
import { isBlank, isEqual } from '@ember/utils';

export function emptyVal(params, options) {
  let [val] = params;
  const { blankString } = options;
  let replaceWith = blankString || '-';
  return isEqual(val, 0) || isBlank(val) ? replaceWith : val;
}
export default helper(emptyVal);
