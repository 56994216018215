import Model, { attr, belongsTo } from '@ember-data/model';
// @ts-ignore
import { apiAction } from '@mainmatter/ember-api-actions';
import { modelValidator, type ValidatedModel, type ValidationsConfig } from 'ember-model-validator';
import type ContainerView from 'tnt-ui/models/container-view';

@modelValidator
class ContainerViewShare extends Model {
  // Relationships
  @belongsTo('container-view') declare containerView: ContainerView;

  // Attributes
  @attr('string') declare recipientEmail: string;
  @attr('string') declare recipientName: string;
  @attr('string') declare title: string;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;

  validations: ValidationsConfig = {
    recipientEmail: {
      presence: true,
      email: true,
    },
    recipientName: { presence: true },
    title: { presence: true },
  };

  async resendEmail() {
    return await apiAction(this, {
      method: 'POST',
      path: 'resend_email',
    });
  }
}

interface ContainerViewShare extends ValidatedModel, Model {}

export default ContainerViewShare;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'container-view-share': ContainerViewShare;
  }
}
