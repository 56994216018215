/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class ContainersViewShareFormComponent extends Component {
  @service store;
  @service metrics;

  @tracked newViewShare;
  @tracked newViewShareError;

  @action
  resetNewViewShare() {
    this.newViewShare = this.store.createRecord('container-view-share', {
      title: this.args.containerView.title,
    });
  }

  @task
  *saveViewShareTask(event) {
    event.preventDefault();

    if (this.newViewShare.validate()) {
      try {
        const { containerView } = this.args;
        this.newViewShare.containerView = containerView;
        this.newViewShareError = null;
        yield this.newViewShare.save();

        this.trackEvent({
          event: 'view_granular_share_sent',
          view_id: containerView.id,
          target_email: this.newViewShare.recipientEmail,
        });

        this.resetNewViewShare();
      } catch (e) {
        console.log(e);
        this.newViewShareError = 'There was an error sharing the link.  Please try again.';
      }
    }
  }

  @action
  validateAttr(attr) {
    this.newViewShare.validate({ only: [attr] });
  }

  @action
  trackEvent(attrs) {
    this.metrics.trackEvent({
      ...attrs,
      source: 'Container Dashboard',
    });
  }

  @action
  onWillDestroy() {
    if (!(this.store.isDestroyed || this.store.isDestroying)) {
      this.unloadUnsavedNewShares();
    }
  }

  unloadUnsavedNewShares() {
    const loadedShares = this.store.peekAll('container-view-share');
    const unsavedNewShare = loadedShares.filter((share) => share.isNew);
    unsavedNewShare.forEach((share) => share.unloadRecord());
  }
}
