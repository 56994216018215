import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import type PostHogFeaturesService from 'tnt-ui/services/posthog-features';

type Positional = [string];

export interface IsPosthogFeatureEnabledHelperSignature {
  Args: {
    Positional: Positional;
  };
  Return: string | boolean | undefined;
}

export default class IsPosthogFeatureEnabledHelper extends Helper<IsPosthogFeatureEnabledHelperSignature> {
  @service declare posthogFeatures: PostHogFeaturesService;

  public compute([feature]: Positional) {
    this.posthogFeatures.triggerChange;
    return this.posthogFeatures.isPosthogFeatureEnabled(feature);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'is-posthog-feature-enabled': typeof IsPosthogFeatureEnabledHelper;
  }
}
