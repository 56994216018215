import { datadogRum } from '@datadog/browser-rum';
import ENV from 'tnt-ui/config/environment';

export function initialize() {
  if (ENV.environment === 'production') {
    datadogRum.init({
      applicationId: ENV.APP.DATADOG_APP_ID,
      clientToken: ENV.APP.DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'tntui',
      env: 'production',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sampleRate: 100,
      trackInteractions: true,
    });
  }
}

export default {
  name: 'datadog',
  initialize,
};
