/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ModalsService from 'tnt-ui/services/modals';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    boardId: string;
    categoryId?: string;
    tags?: string[];
  };
}

export default class FeedbackButtonComponent extends Component<Signature> {
  @service declare modals: ModalsService;

  @action
  onFeedbackForm() {
    const { boardId, categoryId, tags } = this.args;
    this.modals.open('feedback-form', {
      boardId,
      categoryId,
      tags,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FeedbackButton: typeof FeedbackButtonComponent;
  }
}
