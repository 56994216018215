import Model, { attr, hasMany, type AsyncHasMany } from '@ember-data/model';
import type VoyageModel from 'tnt-ui/models/voyage';

export default class VesselModel extends Model {
  @hasMany('voyage') declare voyages: AsyncHasMany<VoyageModel>;

  @attr('string') declare name: string;
  @attr('string') declare imo: string;
  @attr('string') declare mmsi: string;
  @attr('string') declare marineTrafficId: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    vessel: VesselModel;
  }
}
