/* eslint-disable prettier/prettier */
import { helper } from '@ember/component/helper';
import { capitalize } from '@ember/string';

export function humanize([str]: string[]) {
  if (str === null || str === undefined) {
    return '';
  }
  if (typeof str === 'boolean') {
    return humanizeBoolean(str);
  }
  if (typeof str === 'string') {
    return humanizeString(str);
  }
  return str;
}

const humamizeHelper = helper(humanize);

export default humamizeHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    humanize: typeof humamizeHelper;
  }
}

function humanizeBoolean(bol: boolean) {
  if (bol === true) {
    return 'Yes';
  }
  if (bol === false) {
    return 'No';
  }
  return 'N/A';
}

export function humanizeString(string = '') {
  return string
    .toString()
    .split(/-|_|\s+/gi)
    .map((w) => capitalize(w.trim()))
    .join(' ')
    .trim();
}
