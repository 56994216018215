/* eslint-disable prettier/prettier */
import Service from '@ember/service';
import { A } from '@ember/array';
import { later } from '@ember/runloop';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { disableMainPageScroll, enableMainPageScroll } from 'tnt-ui/utils/scrollbar';

type poolItem = {
  name: string;
  content: any;
  number: number;
};

export default class ModalsService extends Service {
  // Properties
  @tracked pool: poolItem[] = A([]);

  // Methods
  open(name: string, content = {}, removeAll = false) {
    if (removeAll) {
      this.removeAll(name);
    }

    if (this.pool.length === 0) {
      disableMainPageScroll();
    }

    this.pool.pushObject({
      name,
      content,
      number: this.pool.length + 1,
    });
  }

  close(name: string, timeout = 0) {
    if (isPresent(name)) {
      const modal = this.pool.findBy('name', name);

      if (isPresent(modal)) {
        later(
          this,
          () => {
            modal?.content.modalClose?.();
            this.pool.removeObject(modal as poolItem);

            if (this.pool.length === 0) {
              enableMainPageScroll();
            }
          },
          timeout,
        );
      }
    } else {
      later(
        this,
        () => {
          this.pool.popObject();

          if (this.pool.length === 0) {
            enableMainPageScroll();
          }
        },
        timeout,
      );
    }
  }

  removeAll(namespace: string, timeout = 0) {
    if (isPresent(namespace)) {
      const modals = this.pool.filter(({ name }) => name.startsWith(namespace));

      modals.forEach((modal) => this.close(modal.name, timeout));
    } else {
      this._resetAll();
    }
  }

  _resetAll() {
    this.pool.forEach((modal) => modal.content.modalClose?.());
    this.pool = A([]);

    enableMainPageScroll();
  }
}
