/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-get */
import Service, { inject as service } from '@ember/service';

export default class AddressService extends Service {
  @service crud;

  saveAddress = (address, account) => {
    address.set('account', account);
    return this.get('crud.save')(address);
  };
}
