/* eslint-disable prettier/prettier */
import Service, { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import fetch from 'fetch';
import { reject, resolve } from 'rsvp';
import ENV from 'tnt-ui/config/environment';

import type SessionService from 'tnt-ui/services/session';

type Options = {
  method?: string;
  headers?: Record<string, string>;
  data?: Record<string, unknown>;
  body?: string;
};

export default class FetchService extends Service {
  @service declare session: SessionService;

  apiHost = ENV.APP['apiHost'];

  get headers() {
    const headers: {
      'Content-Type': string;
      Authorization?: string;
      'X-Account-ID'?: string;
    } = {
      'Content-Type': 'application/json; charset=utf-8',
    };

    // @ts-ignore
    const authToken = this.session.get('data.authenticated.access_token') as string;

    if (isPresent(authToken)) {
      headers.Authorization = `Bearer ${authToken}`;
    }

    // @ts-ignore
    headers['X-Account-ID'] = this.session.get('data.current_account_id') as string;

    return headers;
  }

  async raw(url: string, options: Options = {}) {
    options.headers = Object.assign({}, this.headers);

    if (isPresent(options.data)) {
      options.body = JSON.stringify(options.data);
      delete options.data;
    }

    const response = await fetch(url, options);

    const payload = await response.json();

    if (response.ok) {
      return resolve(payload);
    } else {
      // @ts-ignore
      response.payload = payload;

      return reject(response);
    }
  }

  async nonJsonRequest(uri: string, options: Options = {}) {
    options.headers = Object.assign({}, this.headers);

    if (isPresent(options.data)) {
      options.body = JSON.stringify(options.data);
      delete options.data;
    }

    return await fetch(`${this.apiHost}${uri}`, options);
  }

  async request(uri: string, options: Options = {}) {
    options.headers = Object.assign({}, this.headers);

    if (isPresent(options.data)) {
      options.body = JSON.stringify(options.data);
      delete options.data;
    }

    const response = await fetch(`${this.apiHost}${uri}`, options);

    const payload = await response.json();

    if (response.ok) {
      return resolve(payload);
    } else {
      // @ts-ignore
      response.payload = payload;

      return reject(response);
    }
  }

  async put(uri: string, options: Options = {}) {
    options.method = 'PUT';

    return await this.request(uri, options);
  }

  async post(uri: string, options: Options = {}) {
    options.method = 'POST';

    return await this.request(uri, options);
  }
}
