import Service, { service } from '@ember/service';
import type SessionService from 'tnt-ui/services/session';

export default class AccessService extends Service {
  @service declare session: SessionService;

  // It checks whether
  // a currentUser exists (for share view they might not)
  // a currentUser is an admin
  // and whether an admin is using 'signedInAs'
  get isAdminOrImpersonating() {
    const { currentUser } = this.session;
    const signedInAs = localStorage.getItem('signedInAs');
    const currentUserIsAdmin = currentUser && currentUser.currentAccount && currentUser.isAdmin;

    if (signedInAs) {
      const json = JSON.parse(signedInAs);
      return Boolean(currentUserIsAdmin || json.adminUserId);
    } else {
      return Boolean(currentUserIsAdmin);
    }
  }
}
