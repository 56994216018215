import { get } from '@ember/object';
import V2ApiAdapter from './v2-api';

export default class CredentialAdapter extends V2ApiAdapter {
  buildURL(modelName, id, snapshot, requestType, query) {
    return super.buildURLV2(modelName, id, snapshot, requestType, query);
  }

  buildNestedURL(query) {
    const { integrationId } = query;

    const url = [];
    url.push(this.urlPrefix());
    url.push('integrations');
    url.push(integrationId);
    url.push('credentials');

    return url.join('/');
  }

  query(_store, _type, query) {
    let url = this.buildNestedURL(query);

    return this.ajax(url, 'GET');
  }

  createRecord(store, type, snapshot) {
    const data = {};
    const serializer = store.serializerFor(type.modelName);

    serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

    const url = this.buildNestedURL({
      // eslint-disable-next-line ember/no-get
      integrationId: get(snapshot.belongsTo('integration'), 'id'),
    });

    return this.ajax(url, 'POST', { data: data });
  }

  check(model) {
    const data = {};
    const serializer = this.store.serializerFor('credential');
    const snapshot = model._createSnapshot();
    serializer.serializeIntoHash(data, 'credential', snapshot, { includeId: true });

    const url = this.buildNestedURL({
      // eslint-disable-next-line ember/no-get
      integrationId: get(snapshot.belongsTo('integration'), 'id'),
    });

    return this.ajax(`${url}/check`, 'POST', { data: data });
  }
}
