/* eslint-disable prettier/prettier */
import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';
import type SessionService from 'tnt-ui/services/session';

export default class IsSignedInHelper extends Helper {
  @service declare session: SessionService;

  compute() {
    // eslint-disable-next-line ember/no-get
    return isPresent(get(this.session, 'data.current_account_id'));
  }
}
