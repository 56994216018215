/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import ModalDialog from 'ember-modal-dialog/components/modal-dialog';

export default class ActionModal extends ModalDialog {
  attachment = 'none';
  targetAttachment = 'none';
  overlayClassNames = 'fade show';
  modalSize = 'modal-lg';
  translucentOverlay = true;

  get localContainerClassNames() {
    return `modal-dialog ${this.containerClassNames} ${this.modalSize}`;
  }

  didInsert() {
    document.querySelector('body').classList.add('no-scroll');
  }

  willDestroy() {
    document.querySelector('body').classList.remove('no-scroll');
  }

  @action
  close() {
    const action = this.closeAction;
    if (action) {
      action();
    } else {
      console.warn('Please specify an action to the closeAction property');
    }
  }
}
