export const BOOKING_REFERENCE_TYPES = [
  { k: 'house_bol', v: 'House BOL' },
  { k: 'order_no', v: 'Order No' },
  { k: 'load_no', v: 'Load No' },
  { k: 'entry_no', v: 'Entry No' },
  { k: 'awb_no', v: 'AWB No' },
  { k: 'hawb_no', v: 'HAWB No' },
  { k: 'cust_ref_no', v: 'Cust Ref No' },
  { k: 'shipper_ref_no', v: 'Shipper Ref No' },
  { k: 'consignee_ref_no', v: 'Consignee Ref No' },
  { k: 'forwarder_ref_no', v: 'Forwarder Ref No' },
  { k: 'broker_ref_no', v: 'Broker Ref No' },
  { k: 'trucker_ref_no', v: 'Trucker Ref No' },
];

export const CONTAINER_REFERENCE_TYPES = [
  { k: 'seal_no', v: 'Seal No' },
  { k: 'weight_ticket', v: 'Weight Ticket' },
  { k: 'po_no', v: 'PO No' },
  { k: 'so_no', v: 'SO No' },
  { k: 'customer_so_no', v: 'Cust SO No' },
  { k: 'customer_po_no', v: 'Cust PO No' },
  { k: 'distributor_so_no', v: 'Dist SO No' },
  { k: 'distributor_po_no', v: 'Dist PO No' },
];
