/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class CargoRefNumbersFormComponent extends Component {
  @tracked refNumbers = this.args.refNumbers || [];

  @action
  addTag(tag) {
    this.refNumbers.pushObject(tag);
    this.args.updateRefNumbers(this.refNumbers);
  }

  @action
  removeTagAtIndex(index) {
    this.refNumbers.removeAt(index);
    this.args.updateRefNumbers(this.refNumbers);
  }
}
