import ENV from 'tnt-ui/config/environment';

const SUCCESS_HANDLERS = [];

export function initialize() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register(`${ENV.rootURL}sw.js`, {
          scope: ENV.rootURL,
        })
        .then((registration) => {
          let current = Promise.resolve();

          for (let i = 0, len = SUCCESS_HANDLERS.length; i < len; i++) {
            current = current.then(function () {
              return SUCCESS_HANDLERS[i](registration);
            });
          }

          return current.then(function () {
            console.log('Service Worker registration succeeded. Scope is ' + registration.scope);
          });
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }
}

export function addSuccessHandler(func) {
  SUCCESS_HANDLERS.push(func);
}

export default {
  initialize,
  addSuccessHandler,
};
