import Model, { attr, belongsTo } from '@ember-data/model';
import Validations from '../utils/validations/message';

export default class MessageModel extends Model.extend(Validations) {
  @belongsTo('user') user;
  @belongsTo({ polymorphic: true }) messageable;

  @attr('string') body;
  @attr('string') recipient;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @attr({ defaultValue: () => ({}) }) meta;
}
