import Model, { attr, belongsTo } from '@ember-data/model';
import type AccountModel from 'tnt-ui/models/account';

export default class ProductDimensionModel extends Model {
  @belongsTo('account') declare account: AccountModel;

  @attr('string') declare name: string;
  @attr('string') declare postfix: string;
  @attr('string') declare dataType: string;
  // @ts-ignore
  @attr('array') declare categories: any[];
  @attr('boolean') declare defaultDimension: boolean;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'product-dimension': ProductDimensionModel;
  }
}
