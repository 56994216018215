/* eslint-disable ember/no-classic-classes, ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import { isArray } from '@ember/array';
import { dasherize } from '@ember/string';
import { camelizeKeys } from '../utils/object-functions';

export default DS.JSONAPISerializer.extend({
  normalize(typeClass, hash) {
    this._normalizeFragments(typeClass, hash);
    return this._super(...arguments);
  },

  _normalizeFragments(typeClass, hash) {
    typeClass.eachAttribute((key) => {
      const metaAttribute = typeClass.attributes.get(key);
      const dasherizedKey = dasherize(key);
      const attrValue = hash.attributes[dasherizedKey];
      const { type } = metaAttribute;

      if (!isArray(attrValue) && metaAttribute.isFragment) {
        hash.attributes[dasherizedKey] = camelizeKeys(attrValue);
      }

      // `tracked-array` is used as a replacement for for `fragment-array`, but we need to convert
      // its objects' keys to camel case. The key conversion for the rest of the attributes is done
      // by the serializer by default.
      if (type === 'tracked-array' && isArray(attrValue)) {
        for (const item of attrValue) {
          if (typeof item === 'object' && item !== null) {
            hash[dasherizedKey] = camelizeKeys(item);
          }
        }
      }

      // Similarly to `tracked-array`, `tracked-object` is used as a replacement for `fragment`
      if (type === 'tracked-object') {
        hash.attributes[dasherizedKey] = camelizeKeys(attrValue);
      }
    });
  },
});
