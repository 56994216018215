/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { debug } from '@ember/debug';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class CargoRateEditor extends Component {
  @service store;

  @tracked showRateEditor = false;

  get truckingAccount() {
    return this.args.cargo.truckingAccount;
  }

  get truckingRate() {
    return this.args.cargo.truckingRate;
  }

  get bookingOrder() {
    return this.args.cargo.bookingOrder;
  }

  get rate() {
    if (isPresent(this.truckingRate.id)) {
      return this.truckingRate;
    } else {
      return this.newRate;
    }
  }

  get newRate() {
    return this.store.createRecord('rate', {
      account: this.args.cargo.truckingAccount,
      rateType: 'partner',
      origin: this.bookingOrder.rate.origin,
      originZip: this.bookingOrder.rate.originZip,
      destination: this.bookingOrder.rate.destination,
      destinationZip: this.bookingOrder.rate.destinationZip,
    });
  }

  get canSaveRate() {
    let rate = this.rate;

    if (isPresent(rate)) {
      let rateAttrs = rate.getProperties('isValid', 'liveUnloadRate', 'pickDropRate');
      return Object.values(rateAttrs).every(isPresent);
    } else {
      return false;
    }
  }

  @task
  *saveRate() {
    try {
      yield this.rate.save();
      this.args.cargo.truckingRate = this.rate;
      yield this.args.cargo.save();
    } catch (e) {
      debug(e.message);
      debug(e);
    }
  }

  @action
  cancelEditing() {
    if (isPresent(this.rate.id)) {
      const rate = this.store.findRecord('rate', this.rate.id);
      rate.rollbackAttributes();
    }
    this.showRateEditor = false;
  }
}
