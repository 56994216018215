/* eslint-disable ember/no-computed-properties-in-native-classes, ember/require-computed-property-dependencies */

import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

export default class CargoProductModel extends Model {
  @belongsTo({ async: false }) cargo;
  @belongsTo({ async: false }) product;
  @attr('tracked-array', { defaultValue: () => new TrackedArray([]) }) dimensions;

  @computed('product')
  get sku() {
    return this.product.sku;
  }

  @computed('product')
  get name() {
    return this.product.name;
  }

  @task defaultDimensionQuantity = function* () {
    let account = yield this.product.get('account');
    let accountDimensions = yield account.get('productDimensions');

    let defaultDimension = accountDimensions.findBy('defaultDimension', true);

    if (defaultDimension == null) {
      return null;
    }
    let matchingDimension = this.dimensions.find((d) => d.dimension === defaultDimension.id);
    if (matchingDimension == null) {
      return null;
    }
    return matchingDimension.value;
  };

  @computed('product.account', 'dimensions.[]')
  get quantity() {
    return this.defaultDimensionQuantity.perform();
  }

  @computed('dimensions.[]')
  get numDimensions() {
    return this.dimensions.length;
  }
}
