/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { humanizeHoldName, isCustomsPending } from 'tnt-ui/schemas/hold';
import type { Hold } from 'tnt-ui/schemas/hold';

export interface CargoContainerHoldsSignature {
  Element: HTMLButtonElement;
  Args: {
    holds: Hold[];
  };
}

export default class CargoContainerHoldsComponent extends Component<CargoContainerHoldsSignature> {
  humanizeHoldName = humanizeHoldName;
  isCustomsPending = isCustomsPending;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Cargo::ContainerHolds': typeof CargoContainerHoldsComponent;
  }
}
