/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

export default class CopyableInputComponent extends Component {
  @tracked copyMessage = '';

  get sharedURL() {
    return this.copyMessage || this.args.sharedURL;
  }

  @action
  onClick(e) {
    e.target.select();
  }

  @action
  async onCopy() {
    await navigator.clipboard.writeText(this.args.sharedURL);
    this.copyMessage = 'Copied to clipboard!';

    later(
      this,
      () => {
        this.copyMessage = '';
      },
      1200,
    );
  }
}
