/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

const DEFAULT_TIME_ZONE = 'America/Los_Angeles';

/**
 * @param {Array<ContainerUpdateModel>} events
 * @param {CargoModel} cargo
 * @param {Function} [canEditUpdates]
 * @param {Sting} [portTimeZone]
 */
export default class CargoContainerStateHistory extends Component {
  get timeZone() {
    return this.args.portTimeZone || DEFAULT_TIME_ZONE;
  }
}
