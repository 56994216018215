/* eslint-disable ember/no-get */

import { get } from '@ember/object';
import { isPresent } from '@ember/utils';
import V2ApiSerializer from './v2-api';

export default class TrackingRequestSerializer extends V2ApiSerializer {
  normalizeResponse(_store, _primaryModelClass, payload) {
    let dataPayload = Array.isArray(payload.data) ? payload.data : [payload.data];

    dataPayload.forEach((tr) => {
      if (isPresent(get(tr, 'relationships.tracked_object.data'))) {
        tr.relationships.tracked_object.data.type = 'shipment-v2';
      }
    });

    if (isPresent(payload.included)) {
      payload.included.forEach((modelData) => {
        if (modelData.type === 'shipment') {
          modelData.type = 'shipment-v2';
        }
        if (modelData.type === 'container' && isPresent(get(modelData, 'relationships.shipment.data'))) {
          modelData.relationships.shipment.data.type = 'shipment-v2';
        }
      });
    }

    return super.normalizeResponse(...arguments);
  }
}
