import { set } from '@ember/object';

export function initialize(applicationInstance) {
  const metricsService = applicationInstance.lookup('service:metrics');
  set(metricsService, 'context.signedIn', false);
  set(metricsService, 'context.userId', null);
  set(metricsService, 'context.email', null);
}

export default {
  name: 'metrics-context',
  initialize,
};
