/* eslint-disable prettier/prettier */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import type Transition from '@ember/routing/transition';
import type SessionService from 'tnt-ui/services/session';

export default class ApplicationRoute extends Route {
  @service declare session: SessionService;

  beforeModel(transition: Transition) {
    this.session.requireAuthentication(transition, 'sign-in');
    super.beforeModel(transition);
  }
}
