/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/classic-decorator-hooks */
import { setProperties } from '@ember/object';
import ValidSelect from 'tnt-ui/components/forms/valid-select';
import { COUNTRIES } from 'tnt-ui/utils/fixtures/countries';

export default class CountrySelect extends ValidSelect {
  options = COUNTRIES;
  labelPath = 'k';
  valuePath = 'v';

  init() {
    super.init(...arguments);
    setProperties(this, {
      prompt: 'Select Country',
      name: 'Country',
    });
  }
}
