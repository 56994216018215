let ord = 1;

export const columnDefinitions = {
  container_number: {
    id: 'container_number',
    name: 'Container',
    valuePath: 'number',
    tooltip: 'Container number',
    component: 'container-number',
    width: 200,
    sticky: true,
    order: ord++,
  },
  created_at: {
    id: 'created_at',
    name: 'Date Added',
    valuePath: 'createdAt',
    component: 'datetime',
    width: 150,
    sortable: true,
    order: ord++,
  },
  ref_numbers: {
    id: 'ref_numbers',
    name: 'Ref numbers',
    valuePath: 'refNumbers',
    component: 'array/number',
    width: 200,
    order: ord++,
    anonymizable: true,
  },
  eq_type: {
    id: 'eq_type',
    name: 'Equipment',
    valuePath: 'equipmentType',
    tooltip: 'Equipment size',
    component: 'container-type',
    width: 100,
    order: ord++,
  },
  current_issues: {
    id: 'current_issues',
    name: 'Current Issues',
    valuePath: 'currentIssues',
    component: 'array/issues',
    width: 100,
    order: ord++,
  },
  available_for_pickup: {
    id: 'available_for_pickup',
    name: 'Available for pickup',
    valuePath: 'availableForPickup',
    component: 'boolean',
    width: 200,
    order: ord++,
  },
  pod_arrived_at: {
    id: 'pod_arrived_at',
    name: 'POD ATA',
    valuePath: 'podArrivedAt',
    component: 'datetime',
    width: 200,
    order: ord++,
    meta: {
      timezone: 'podTimezone',
    },
  },
  pod_discharged_at: {
    id: 'pod_discharged_at',
    name: 'POD Discharge',
    valuePath: 'podDischargedAt',
    component: 'datetime',
    width: 200,
    order: ord++,
    sortable: true,
    meta: {
      timezone: 'podTimezone',
    },
  },
  fees_at_pod: {
    id: 'fees_at_pod',
    name: 'POD Terminal Fees',
    valuePath: 'feesAtPodTerminal',
    component: 'array/fees',
    width: 200,
    order: ord++,
  },
  holds_at_pod: {
    id: 'holds_at_pod',
    name: 'POD Holds',
    valuePath: 'holdsAtPodTerminal',
    component: 'array/holds',
    width: 200,
    order: ord++,
  },
  pickup_lfd: {
    id: 'pickup_lfd',
    name: 'Pickup LFD',
    valuePath: 'pickupLfd',
    component: 'datetime',
    width: 200,
    order: ord++,
    sortable: true,
    meta: {
      timezone: 'podTimezone',
    },
  },
  picked_up_at: {
    id: 'picked_up_at',
    name: 'POD Full Out',
    valuePath: 'pickedUpAt',
    component: 'datetime',
    width: 200,
    order: ord++,
    sortable: true,
  },
  pickup_appointment_at: {
    id: 'pickup_appointment_at',
    name: 'Pickup appointment',
    valuePath: 'pickupAppointmentAt',
    component: 'datetime',
    width: 200,
    order: ord++,
    sortable: true,
  },
  pod_chassis_number: {
    id: 'pod_chassis_number',
    name: 'Pickup Chassis',
    valuePath: 'podFullOutChassisNumber',
    component: 'string',
    width: 200,
    order: ord++,
  },
  pod_terminal_location: {
    id: 'pod_terminal_location',
    name: 'POD Terminal Yard Location',
    valuePath: 'locationAtPodTerminal',
    component: 'string',
    width: 200,
    order: ord++,
  },
  empty_returned_at: {
    id: 'empty_returned_at',
    name: 'Empty returned',
    valuePath: 'emptyTerminatedAt',
    component: 'datetime',
    width: 200,
    order: ord++,
    sortable: true,
    meta: {
      timezone: 'emptyTerminatedTimezone',
    },
  },
  // Shipment Columns
  shipment_number: {
    id: 'shipment_number',
    name: 'Shipment',
    valuePath: 'shipment.billOfLadingNumber',
    tooltip: 'Shipment BL or Booking number',
    component: 'bol',
    width: 200,
    order: ord++,
  },
  shipment_ref_numbers: {
    id: 'shipment_ref_numbers',
    name: 'Shipment Ref Numbers',
    valuePath: 'shipment.refNumbers',
    component: 'array/reference',
    width: 200,
    order: ord++,
    anonymizable: true,
  },
  shipment_tags: {
    id: 'shipment_tags',
    name: 'Shipment Tags',
    valuePath: 'shipment.tags',
    component: 'array/tags',
    width: 200,
    order: ord++,
    anonymizable: true,
  },
  port_of_origin: {
    id: 'port_of_origin',
    name: 'POL',
    valuePath: 'shipment.portOfLadingName',
    tooltip: 'Port Of Lading or Port of Origin',
    component: 'port',
    width: 200,
    order: ord++,
    meta: {
      type: 'port_of_lading',
    },
  },
  port_of_discharge: {
    id: 'port_of_discharge',
    name: 'POD',
    valuePath: 'shipment.portOfDischargeName',
    tooltip: 'Port of Discharge',
    component: 'port',
    width: 200,
    order: ord++,
    meta: {
      type: 'port_of_discharge',
    },
  },
  pod_terminal_name: {
    id: 'pod_terminal_name',
    name: 'POD Terminal',
    valuePath: 'podTerminal.name',
    component: 'string',
    width: 200,
    order: ord++,
  },
  vessel: {
    id: 'vessel',
    name: 'POD Vessel / Voyage',
    valuePath: 'shipment.vesselAndVoyage',
    component: 'string',
    width: 200,
    order: ord++,
  },
  carrier: {
    id: 'carrier',
    name: 'Shipping Line',
    valuePath: 'shipment.shippingLineShortName',
    tooltip: 'Shipping Line Name or Carrier',
    component: 'string',
    width: 200,
    order: ord++,
  },
  scac: {
    id: 'scac',
    name: 'SCAC',
    valuePath: 'shipment.shippingLineScac',
    component: 'string',
    width: 200,
    order: ord++,
  },
  customer_name: {
    id: 'customer_name',
    name: 'Customer',
    valuePath: 'shipment.customerName',
    tooltip: 'Customer name',
    component: 'string',
    width: 200,
    order: ord++,
    anonymizable: true,
  },
  // Sortable datetimes
  pol_etd_at: {
    id: 'pol_etd_at',
    name: 'POL ETD',
    valuePath: 'shipment.polEtdAt',
    component: 'datetime',
    width: 200,
    order: ord++,
    sortable: true,
    meta: {
      timezone: 'shipment.polTimezone',
    },
  },
  pol_atd_at: {
    id: 'pol_atd_at',
    name: 'POL ATD',
    valuePath: 'shipment.polAtdAt',
    component: 'datetime',
    width: 200,
    order: ord++,
    sortable: true,
    meta: {
      timezone: 'shipment.polTimezone',
    },
  },
  pod_eta_at: {
    id: 'pod_eta_at',
    name: 'POD ETA',
    valuePath: 'shipment.podEtaAt',
    component: 'datetime',
    width: 200,
    order: ord++,
    sortable: true,
    meta: {
      timezone: 'shipment.podTimezone',
    },
  },
  arrival: {
    id: 'arrival',
    name: 'POD Arrival',
    component: 'eta',
    width: 200,
    order: ord++,
    sortable: true,
  },
  //custom fields
  days_since_pickup: {
    id: 'days_since_pickup',
    name: 'Days since pickup',
    valuePath: 'pickedUpAt',
    component: 'days-since-pickup',
    width: 200,
    order: ord++,
    sortable: false,
  },

  data_confidence: {
    id: 'data_confidence',
    name: 'Confidence',
    valuePath: 'created_at',
    component: 'data-confidence',
    width: 200,
    order: ord++,
    sortable: false,
    disabled: true,
  },
};

export const columnArray = Object.values(columnDefinitions);

export default columnDefinitions;
