/* import __COLOCATED_TEMPLATE__ from './dialog.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface DialogComponentSignature {
  Element: HTMLDivElement;
  Args: {
    contentClass: string;
    headerClass: string;
    bodyClass: string;
    footerClass: string;
    hideOverlay?: boolean;
    hideClose?: boolean;
    onClose?: () => void;
  };
  Blocks: {
    default: [];
    header: [];
    body: [];
    footer: [];
  };
}

export default class DialogComponent extends Component<DialogComponentSignature> {
  get hideOverlay() {
    return this.args.hideOverlay ?? false;
  }

  @action
  closeModal() {
    this.args.onClose?.();
  }

  @action
  setAttributes(element: HTMLDivElement) {
    if (this.args.onClose) {
      element.setAttribute('role', 'button');
      element.addEventListener('click', this.closeModal);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Dialog: typeof DialogComponent;
  }
}
