import { attr, hasMany, type AsyncHasMany } from '@ember-data/model';
import LocationModel from 'tnt-ui/models/location';
import type RailTerminalModel from 'tnt-ui/models/rail-terminal';
import type TerminalModel from 'tnt-ui/models/terminal';
import { COUNTRIES } from 'tnt-ui/utils/fixtures/countries';

export default class MetroAreaModel extends LocationModel {
  @hasMany('terminal') declare terminals: AsyncHasMany<TerminalModel>;
  @hasMany('rail-terminal') declare railTerminals: AsyncHasMany<RailTerminalModel>;

  @attr('string') declare name: string;
  @attr('string') declare code: string;
  @attr('string') declare stateAbbr: string;
  @attr('string') declare countryCode: string;
  @attr('string') declare timeZone: string;

  @attr('number') declare longitude: number;
  @attr('number') declare latitude: number;

  get countryName() {
    const country = COUNTRIES.findBy('v', this.countryCode);
    if (country !== undefined) {
      return country.k;
    }
    return country;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'metro-area': MetroAreaModel;
  }
}
