import Model, { attr, belongsTo } from '@ember-data/model';
import Validations from '../utils/validations/contract-detail';

export default class ContractDetailModel extends Model.extend(Validations) {
  @belongsTo('billing-account', { async: false }) billingAccount;

  @attr('string') companyName;
  @attr('string') accountEmail;
  @attr('string') stripeCustomerId;
  @attr('boolean') stripeValid;
}
