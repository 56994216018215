import Model, { attr } from '@ember-data/model';

class LocationModel extends Model {
  @attr('number') declare latitude: number;
  @attr('number') declare longitude: number;

  get locationType(): string {
    // @ts-ignore
    return this.constructor.modelName;
  }

  get hasCoordinates(): boolean {
    return Boolean(this.latitude && this.longitude);
  }
}

export default LocationModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    location: LocationModel;
  }
}
