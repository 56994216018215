import { helper } from '@ember/component/helper';
import { pluralize as pluralizeString } from 'ember-inflector';

export function pluralize(params, options) {
  let [count, word] = params;
  const { omitCount } = options;

  if (count !== 1) {
    count = count || 0;
    word = pluralizeString(word);
  }

  return (omitCount ? '' : count + ' ') + word;
}

export default helper(pluralize);
