/* import __COLOCATED_TEMPLATE__ from './container-shared-form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DialogsContainerSharedFormComponent extends Component {
  @service dialogs;

  unknownProperty(key) {
    return this.args[key] || this.args?.content[key];
  }

  @action
  resolveAndClose() {
    this.args.content.promise.resolve();
    this.closeDialog();
  }

  @action
  rejectAndClose() {
    this.args.content.promise.reject();
    this.closeDialog();
  }

  @action
  onClone() {
    this.args.content.onClone();
    this.rejectAndClose();
  }

  @action
  onSave() {
    this.args.content.onSave.perform();
    this.rejectAndClose();
  }

  @action
  closeDialog() {
    this.dialogs.close('container-shared-form');
  }
}
