/* import __COLOCATED_TEMPLATE__ from './container.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { importSync } from '@embroider/macros';
import { ensureSafeComponent } from '@embroider/util';

export default class ModalsContainerComponent extends Component {
  @service modals;

  getModalComponent(componentName) {
    let module = importSync(`./${componentName}`);
    return ensureSafeComponent(module.default, this);
  }
}
