/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import templateOnlyComponent from '@ember/component/template-only';
import { type FeeObject } from 'tnt-ui/models/cntnr';

interface ContainersNewArrayFeesSignature {
  Element: HTMLDivElement;
  Args: {
    value: FeeObject[];
  };
  Blocks: {
    default: [];
  };
}

const ContainersNewArrayFees = templateOnlyComponent<ContainersNewArrayFeesSignature>();

export default ContainersNewArrayFees;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContainersNew::Array::Fees': typeof ContainersNewArrayFees;
    'containers-new/array/fees': typeof ContainersNewArrayFees;
  }
}
