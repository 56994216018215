/* eslint-disable prettier/prettier */
import type Model from '@ember-data/model';

export function toJSON(model: Model) {
  const json: any = {};

  //@ts-ignore
  model.eachAttribute((key: keyof Model, meta: any) => {
    const value = meta.type === 'boolean' ? !!model[key] : model[key];
    json[key] = value ?? null;
  });

  model.eachRelationship((key, meta) => {
    let value;
    if (meta.kind === 'belongsTo') {
      value = model.belongsTo(key).id();
    } else {
      const ids = model.hasMany(key).ids();
      value = ids.length ? ids : undefined;
    }
    if (value !== undefined) {
      json[key] = value;
    }
  });

  return json;
}
