import Model, { attr, belongsTo } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';

export default class BillingSubscriptionItemModel extends Model {
  @belongsTo('billing') billing;
  @attr('string') stripeSubscriptionItemId;
  @attr('date') currentPeriodStart;
  @attr('date') currentPeriodEnd;
  @attr('date') disabledAt;
  @attr('date') syncedAt;
  @attr('date') createdAt;

  async enable() {
    return await apiAction(this, {
      method: 'PATCH',
      path: 'enable',
    });
  }

  async disable() {
    return await apiAction(this, {
      method: 'PATCH',
      path: 'disable',
    });
  }
}
