/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEqual, isPresent } from '@ember/utils';

export default class PortComponent extends Component {
  @action
  addToSearch() {
    const portType = this.args.meta.type;

    let locodeAccessor = '';
    if (isEqual(portType, 'port_of_lading')) {
      locodeAccessor = 'shipment.portOfLadingLocode';
    } else if (isEqual(portType, 'port_of_discharge')) {
      locodeAccessor = 'shipment.portOfDischargeLocode';
    } else if (isEqual(portType, 'destination')) {
      locodeAccessor = 'shipment.destinationLocode';
    }

    const value = this.args.row.get(locodeAccessor);
    if (isPresent(value)) {
      this.args.addToSearch(this.args.columnId, [value]);
    }
  }
}
