/* import __COLOCATED_TEMPLATE__ from './notifications-list.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import currentSession from 'tnt-ui/decorators/current-session';

@currentSession
export default class NotificationsListComponent extends Component {
  @service sessionSetup;
  @service router;

  @tracked isLoading = false;
  @tracked recentActivities = null;

  @action
  async loadActivities() {
    this.isLoading = true;
    this.recentActivities = await this.sessionSetup.recentActivity();
    this.isLoading = false;
  }

  @action
  markAsRead() {
    this.args.updateUserActivityFlag();
  }

  @action
  redirectToSubject(subjectId, subjectType) {
    if (subjectType === 'account') {
      this.router.transitionTo('settings.company');
    } else if (subjectType === 'shipment') {
      this.router.transitionTo('shipments.details', subjectId);
    }
  }
}
