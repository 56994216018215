import Model, { attr } from '@ember-data/model';
// @ts-ignore
import Validations from '../utils/validations/webhook';

// @ts-ignore
export default class WebhookModel extends Model.extend(Validations) {
  @attr('string') declare url: string;
  @attr('string') declare secret: string;
  @attr('boolean', { defaultValue: true }) declare active: boolean;
  @attr() declare headers: any;
  @attr() declare events: any;
  @attr('date') declare createdAt: Date;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    webhook: WebhookModel;
  }
}
