import Model, { attr, hasMany, type AsyncHasMany } from '@ember-data/model';
import AccountMetabaseDashboardModel from './account-metabase-dashboard';

export default class MetabaseDashboardModel extends Model {
  // Relationships
  @hasMany('account-metabase-dashboard') declare accountMetabaseDashboards: AsyncHasMany<AccountMetabaseDashboardModel>;

  @attr('string') declare label: string;
  @attr('string') declare description: string;
  @attr('number') declare metabaseId: number;
  @attr('boolean') declare archived: boolean;
  @attr('boolean') declare paramsConfigured: boolean;
  @attr('boolean') declare defaultDashboard: boolean;
  @attr('boolean') declare removedOnMetabase: boolean;
  @attr({ defaultValue: () => ({}) }) declare configuration: Record<string, any>;

  get flagged() {
    if (!this.paramsConfigured) {
      return true;
    }
    if (this.defaultDashboard || this.removedOnMetabase) {
      return true;
    }
    return false;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'metabase-dashboard': MetabaseDashboardModel;
  }
}
