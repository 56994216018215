export const AccountCategories = [
  { key: 'other', label: 'Other', admin: false },
  { key: 'power_of_attorney', label: 'Power Of Attorney', admin: true },
  {
    key: 'continuous_customs_bond',
    label: 'Continuous Customs Bond',
    admin: false,
  },
  {
    key: 'continuous_customs_bond_application',
    label: 'Continuous Customs Bond Application',
    admin: true,
  },
  {
    key: 'fictitious_business_name_statement',
    label: 'Fictitious Business Name Statement',
    admin: false,
  },
];

export const ShipmentCategories = [
  {
    key: 'other',
    label: 'Other',
    admin: false,
  },
  {
    key: 'commercial_invoice',
    label: 'Commercial Invoice',
    admin: false,
  },
  {
    key: 'packing_list',
    label: 'Packing List',
    admin: false,
  },
  {
    key: 'commercial_invoice_and_packing_list',
    label: 'Commercial Invoice And Packing List',
    admin: false,
  },
  {
    key: 'insurance',
    label: 'Insurance',
    admin: true,
  },
  {
    key: 'hbol',
    label: 'House BOL',
    admin: true,
  },
  {
    key: 'mbol',
    label: 'Master BOL',
    admin: true,
  },
  {
    key: 'cbp_7501',
    label: 'Entry Summary (CBP 7501)',
    admin: true,
  },
  {
    key: 'cbp_3461',
    label: 'Entry/Immediate Delivery (CBP 3461)',
    admin: true,
  },
];

export const BookingCategories = [
  {
    key: 'delivery_order',
    label: 'Delivery Order',
    admin: false,
  },
];

export const CargoCategories = [
  {
    key: 'proof_of_delivery',
    label: 'Proof of Delivery',
    admin: true, // truckers can only upload it through POD modal component
  },
  {
    key: 'signed_bol',
    label: 'Signed BOL',
    admin: false,
  },
  {
    key: 'weight_receipt',
    label: 'Weight Receipt',
    admin: false,
  },
  {
    key: 'interchange_in',
    label: 'Interchange In',
    admin: false,
  },
  {
    key: 'interchange_out',
    label: 'Interchange Out',
    admin: false,
  },
];

export const DocumentCategories = ShipmentCategories.concat(BookingCategories).concat(CargoCategories);
