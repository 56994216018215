/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr, belongsTo } from '@ember-data/model';
import { reads } from '@ember/object/computed';

export default class BookingParticipantModel extends Model {
  @belongsTo('booking-order') bookingOrder;
  @belongsTo('account') account;

  @attr('boolean') archivedOrder;
  @attr('date') createdAt;

  @reads('account.companyType') companyType;
  @reads('account.isTrucker') isTrucker;
}
