/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable prettier/prettier */
import templateOnlyComponent from '@ember/component/template-only';

import type Model from '@ember-data/model';

interface InputErrorsSignature {
  Element: HTMLDivElement;
  Args: {
    model: Model;
    attribute: string;
  };
  Blocks: {
    default: [errors: unknown];
  };
}

const InputErrors = templateOnlyComponent<InputErrorsSignature>();

export default InputErrors;
