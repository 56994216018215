/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { typeOf } from '@ember/utils';

export default class TaskBtnComponent extends Component {
  get isRunning() {
    return this.args.action.isRunning ?? false;
  }

  get runningLabel() {
    return this.args.runningText ?? 'Saving ...';
  }

  get label() {
    return this.args.value ?? 'Save';
  }

  @action
  onClick() {
    const action = this.args.action;
    if (typeOf(action) === 'function') {
      action();
    } else {
      action.perform();
    }
  }
}
