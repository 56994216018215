import Helper from '@ember/component/helper';
import AddressPresenter from 'tnt-ui/presenters/address';
import BookingOrderPresenter from 'tnt-ui/presenters/booking-order';
import TerminalPresenter from 'tnt-ui/presenters/terminal';

const PRESENTERS = {
  address: AddressPresenter,
  'booking-order': BookingOrderPresenter,
  terminal: TerminalPresenter,
};

export function getPresenter(presenterName) {
  return PRESENTERS[presenterName];
}

export default class PresentHelper extends Helper {
  compute([model, key, presenterName]) {
    if (!model) {
      return null;
    }

    let name = presenterName || model.constructor.modelName;
    let presenter = getPresenter(name).create({ model });

    return presenter.get(key);
  }
}
