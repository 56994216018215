/* import __COLOCATED_TEMPLATE__ from './container-unsaved-form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DialogsContainerUnsavedFormComponent extends Component {
  @service dialogs;

  get isSaveable() {
    const { isNew, isMine, isNotAll } = this.args.content.containerView;
    return isNew || (isMine && isNotAll);
  }

  unknownProperty(key) {
    return this.args[key] || this.args?.content[key];
  }

  @action
  resolveAndClose() {
    this.args.content.promise.resolve();
    this.closeDialog();
  }

  @action
  rejectAndClose() {
    this.args.content.promise.reject();
    this.closeDialog();
  }

  @action
  onClone() {
    this.args.content.onClone();
    this.rejectAndClose();
  }

  @action
  onSave() {
    const { containerView, onSave } = this.args.content;

    if (containerView.isNew) {
      this.dialogs.open('container-save-form', {
        containerView: containerView,
        onSave: onSave,
      });
    } else {
      onSave.perform();
    }

    this.rejectAndClose();
  }

  @action
  closeDialog() {
    this.dialogs.close('container-unsaved-form');
  }
}
