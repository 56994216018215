/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ProductProductItemComponent extends Component {
  @service crud;

  @tracked editExisting = false;

  get newProduct() {
    return this.args.newProduct ?? false;
  }

  get editing() {
    return this.newProduct || this.editExisting;
  }

  @action
  editProduct() {
    this.editExisting = true;
  }

  @action
  cancel() {
    if (this.args.product.isNew) {
      this.args.product.deleteRecord();
    } else {
      this.args.product.rollbackAttributes();
      this.editExisting = false;
    }
  }

  @action
  async saveProduct(product) {
    await this.crud.save(product);
    this.editExisting = false;
  }
}
