import BaseValidator from 'ember-cp-validations/validators/base';

class DocumentPresent extends BaseValidator {
  validate(value, options /*, model, attribute*/) {
    let valid;
    if (options && options['category']) {
      valid = value.filterBy('category', options['category']).length > 0;
    } else {
      valid = value.length > 0;
    }
    if (!valid && options && options['message']) {
      return options['message'];
    }
    return valid;
  }
}

DocumentPresent.reopenClass({
  /**
   * Define attribute specific dependent keys for your validator
   *
   * [
   * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
   * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
   * ]
   *
   * @param {String}  attribute   The attribute being evaluated
   * @param {Unknown} options     Options passed into your validator
   * @return {Array}
   */
  getDependentsFor(/* attribute, options */) {
    return ['model.${attribute}.@each.category'];
  },
});

export default DocumentPresent;
