/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable prettier/prettier */
import templateOnlyComponent from '@ember/component/template-only';

interface ContainersNewArrayIssuesSignature {
  Element: HTMLDivElement;
  Args: {
    value: string[];
  };
  Blocks: {
    default: [];
  };
}

const ContainersNewArrayIssues = templateOnlyComponent<ContainersNewArrayIssuesSignature>();

export default ContainersNewArrayIssues;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContainersNew::Array::Issues': typeof ContainersNewArrayIssues;
    'containers-new/array/issues': typeof ContainersNewArrayIssues;
  }
}
