import { defineProperty } from '@ember/object';
import { equal } from '@ember/object/computed';
import { camelize, capitalize } from '@ember/string';
import require from 'require';

/**
 * Class level decorator that turns the array of status in to computed properties
 *
 * ```javascript
 *   @hasStatus(['submitted', 'delivered'], 'currentStatus')
 *   export default class extends Model {
 *      @attr currentStatus
 *   }
 * ```
 *
 * This produces a list of computed properties on the class:
 *
 *  isSubmitted, isDelivered, etc ..
 *
 *  where if 'currentStatus' == 'submitted'
 *  model.get('isSubmitted') //=> true
 *  model.get('isDelivered') //=> false
 *
 * @function
 * @param {Array} [statusNames] - ['submitted', 'delivered', ...etc]
 */
export function hasStatus(...params) {
  return function decorator(target) {
    let [names, statusField] = params;
    // remove name of 'new' so isNew is not created ( which would override
    // ember model isNew method )
    names = names.reject((name) => name === 'new');
    names.forEach((status) => {
      let cp = equal(statusField, status);
      defineProperty(target.prototype, `is${capitalize(camelize(status))}`, cp);
    });
  };
}

/**
 * Class level decorator that sets validations on the class
 *
 * ```javascript
 *   @hasValidations(Validations)
 *   export default class extends Model {
 *      // your class now has the validations
 *   }
 * ```
 *
 * @function
 * @param {Mixin} cp validations
 */
export function hasValidations(validations) {
  return mixin([validations]);
}

/**
 * Class level decorator that tags a model as being use polymorphically
 * by the names you provide
 *
 * ```javascript
 *   @usedAs(['subjectable', 'attachable'])
 *   export default class extends Model {
 *
 *   }
 * ```
 *
 * @function
 * @param {Array} [poylmorhic type names] - ['attachable', 'subjectable', ...etc]
 */
export function usedAs(types) {
  const mixins = types.map((type) => require(`tnt-ui/mixins/${type}`).default);
  return function decorator(target) {
    mixins.forEach((mixin) => target.reopen(mixin));
  };
}

/**
 * Class level decorator that adds mixins to a class
 *
 *
 * ```javascript
 * import MyMixin from '../mixins/my-mixin;
 *
 * @mixin([MyMixin])
 * export default class extends Model {
 *
 * }
 * ```
 *
 * @function
 * @param {Array} [mixins] - ['location', ...etc]
 */
export function mixin(mixins) {
  return function decorator(target) {
    mixins.forEach((mixin) => target.prototype.reopen(mixin));
  };
}

/**
 * Instance level decorator that tells if you some models satisfy the property check
 * in a hasMany array
 *
 * ```javascript
 * import { isAnyBy } from '../mixins/decorators;
 *
 * export default class extends Model {
 *   @hasMany things
 *   @isAnyBy('things', 'isNew') someNewThings
 * }
 * ```
 *
 * @function
 * @param {String} hasMany relationship name
 */
// export function isAnyBy(hasMany, propertyCheck) {
//   return computedDecoratorWithParams(function(target, key, desc) {
//     const filteredHasManyKey = `${propertyCheck}${hasMany.camelize().capitalize()}`,
//           filteredModels     = filterBy(hasMany, propertyCheck);
//     // set up intermmeidate property to collect saved models
//     // unless it already exists
//     if (!target[filteredHasManyKey]) {
//       Ember.defineProperty(target, filteredHasManyKey, filteredModels);
//     }
//     // need to use awesome macros because they are composable
//     desc.value = notEmpty(filteredHasManyKey);
//     return extractValue(desc);
//   });
// }

// export const presenter = computedDecoratorWithParams(function(target, key/*, desc, params*/) {
//   target.reopen({
//     setupPresenter: Ember.on('init', function() {
//       let model        = this.get(key),
//           presenterKey = `presenter:${key.dasherize()}`,
//           owner        = Ember.getOwner(this);
//       let presenter = owner.factoryFor(presenterKey).create({model});
//       this.set(`${key}Presenter`, presenter);
//     })
//   });
// });
// export const fragment = computedDecoratorWithParams(function(target, key, desc, params) {
//   return fragmentDecorator(fragmentObject, params, key);
// });
//
// export const fragmentList = computedDecoratorWithParams(function(target, key, desc, params) {
//   return fragmentDecorator(fragmentArray, params, key);
// });
// function fragmentDecorator(build, params, key) {
//   let [paramsKey, ...rest] = params;
//   if (typeof(paramsKey) !== 'string') {
//     [paramsKey, rest] = ['', params];
//   }
//
//   return build(singularize(paramsKey || key), ...rest);
// }
