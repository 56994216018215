/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-computed-properties-in-native-classes, ember/no-get, ember/require-computed-property-dependencies */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { A } from '@ember/array';
import { computed, get } from '@ember/object';
import { mapBy } from '@ember/object/computed';
import { isPresent } from '@ember/utils';
import ENV from 'tnt-ui/config/environment';
import { usedAs } from '../utils/decorators';

export const SHIPMENT_URL = `${ENV.APP.apiNamespace}/shipments`;

@usedAs(['attachable', 'subjectable'])
export default class ShipmentModel extends Model {
  @belongsTo('account', { async: false }) creator;
  @belongsTo('account', { async: false }) customer;
  @belongsTo('port', { async: false }) portOfLading;
  @belongsTo('port', { async: false }) portOfDischarge;
  @belongsTo('user', { async: true }) user;
  @belongsTo('terminal', { async: false }) podTerminal;
  @belongsTo('port', { async: false }) destination;
  @belongsTo('terminal', { async: false }) destinationTerminal;

  @belongsTo('shipping-line', { async: false }) shippingLine;
  @belongsTo('shipping-line', { async: false, inverse: null }) pickupRailCarrier;
  @belongsTo('voyage', { async: false }) voyage;
  @belongsTo('user', { async: false }) lineTrackingStoppedByUser;

  @hasMany('booking-order') bookingOrders;
  @hasMany('cargo') cargos;
  @hasMany('shipment-participant') shipmentParticipants;
  @hasMany('document') documents;
  @hasMany('activity') activities;

  @attr('string') number;
  @attr('string') polRaw;
  @attr('string') podRaw;
  @attr('string') destinationRaw;
  @attr('date') updatedAt;
  @attr('date') createdAt;
  @attr('array') tags;
  @attr('array') refNumbers;

  @attr('date') lineTrackingLastSucceededAt;
  @attr('date') lineTrackingStoppedAt;
  @attr('string') lineTrackingStoppedReason;

  @attr() newShipmentAttributes;

  @computed('number', 'refNumbers')
  get ref() {
    if (this.refNumbers) {
      return `${this.refNumbers} (${this.number})`;
    }
    return this.number;
  }

  @mapBy('cargos', 'cargoProducts') cargoProductGroupings;

  @computed('cargoProductGroupings.[]')
  get products() {
    return this.cargoProductGroupings
      .reduce(function (masterCargoProducts, cargoProducts) {
        return masterCargoProducts.pushObjects(cargoProducts.toArray());
      }, A())
      .uniqBy('product');
  }

  @computed('customer')
  get dimensions() {
    return this.customer.productDimensions;
  }

  @computed('voyage.podAtaAt')
  get arrivedAtportOfDischarge() {
    return isPresent(this.get('voyage.podAtaAt'));
  }

  @computed('destination')
  get hasInlandDestination() {
    return Boolean(this.destination);
  }

  get railCarrierScac() {
    const firstCargo = this.cargos.firstObject;

    if (firstCargo) {
      let container = this.store.peekRecord('container', firstCargo.id);

      if (container) {
        return container.railCarrierScac;
      } else {
        container = this.store.findRecord('container', firstCargo.id);
      }

      return get(container, 'railCarrierScac');
    } else {
      return undefined;
    }
  }

  async stopTracking() {
    await this.store.adapterFor('shipment-v2').stopTracking(this);
  }

  async resumeTracking() {
    await this.store.adapterFor('shipment-v2').resumeTracking(this);
  }
}
