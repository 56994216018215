/* eslint-disable ember/classic-decorator-hooks, ember/no-capital-letters-in-routes, ember/no-incorrect-calls-with-inline-anonymous-functions */

import { inject as service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import EmberRouter from '@embroider/router';
import config from 'tnt-ui/config/environment';

export default class Router extends EmberRouter {
  @service metrics;

  location = config.locationType;
  rootURL = config.rootURL;

  init() {
    super.init(...arguments);
    this.on('routeDidChange', () => {
      this._trackPage();
    });
  }

  _trackPage() {
    scheduleOnce('afterRender', this, () => {
      const page = this.currentRouteName || 'unknown';
      const title = document.title;
      this.metrics.trackPage({ page, title });
    });
  }
}

Router.map(function () {
  this.route('maintenance');

  this.route('sign-in');
  this.route('register');

  this.route('account-locked');

  this.route('products');
  this.route('activity');
  this.route('invites', { path: '/invites/:invite_id' });

  this.route('reports', function () {
    this.route('details', { path: ':report_path' });
    this.route('preview');
  });

  this.route('not-found', { path: '/*path' }); // needs to be placed at the bottom, Catch all route.
  // this.route('not-found'); // TransitionTo Route (if 404)

  this.route('admin', function () {
    this.route('accounts', function () {
      this.route('account', { path: ':account_id' }, function () {
        this.route('activity');
        this.route('users');
        this.route('delivery-locations');
        this.route('documents');
        this.route('products');
        this.route('reports');
        this.route('api-keys');
        this.route('billing');
      });
      this.route('edit', { path: ':account_id/edit' });
      this.route('new');
    });
    this.route('addresses');
    this.route('billing-accounts', function () {});
    this.route('feature-flags');
  });

  this.route('settings', function () {
    this.route('profile');
    this.route('company');
    this.route('notifications');
    this.route('my-containers');
    this.route('data-sources', function () {
      this.route('integrations', function () {
        this.route('index', { path: ':integration_id' });
      });
    });
    this.route('early-access-features');
  });
  this.route('socket-check');

  this.route('track', function () {
    this.route('new', { path: '/' });
  });

  this.route('shipments', function () {
    this.route('region-redirect');
    this.route('details', { path: ':shipment_id' });
    // Deprecated. Will be replaced by /tracking
    this.route('imports', function () {
      this.route('index', { path: 'recently-added' });
    });
  });

  this.route('dashboard', function () {
    this.route('containers-at-risk');
    this.route('arriving-today');
    this.route('eta-changed');
  });

  this.route('developers', function () {
    this.route('api-keys');
    this.route('webhooks', function () {
      this.route('new');
      this.route('edit', { path: '/:webhook_id/edit' });
    });
    this.route('tracking-requests', function () {});
    this.route('webhook-notifications', function () {});
  });
  this.route('apiKeys');
  this.route('widget');
  this.route('containers', function () {
    this.route('new', function () {
      this.route('index', { path: '/:id' });
      this.route('manage-views');
    });
  });
  this.route('share/not-found', { path: '/share/:share_id/not-found' });
  this.route('share/error', { path: '/share/:share_id/error' });
  this.route('share', { path: '/share/:share_id' });
  this.route('vessels');

  this.route('debug', function () {
    this.route('shipment-map');
  });

  this.route('onboarding');
  this.route('global-map');
});
