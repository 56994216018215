import Model, { attr, belongsTo } from '@ember-data/model';

export default class ActivityModel extends Model {
  @belongsTo('account') account;
  @belongsTo('subjectable', { polymorphic: true }) subject;
  @belongsTo('subjectable', { polymorphic: true }) target;

  @attr('string') name;
  @attr('string') subjectDisplayId;
  @attr('string') targetDisplayId;
  @attr('string') targetType;
  @attr('string') userName;
  @attr('string') accountName;
  @attr('string') accountCompanyType;
  @attr() meta;
  @attr('string') timeZone;
  @attr('date') createdAt;
}
