import Model, { attr, belongsTo } from '@ember-data/model';
import Validations from '../utils/validations/contract-item';

export default class ContractItemModel extends Model.extend(Validations) {
  @belongsTo('contract') contract;

  @attr('number') quantityIncluded;
  @attr('number') price;
  @attr('string') product;
  @attr('string') namespace;
  @attr('string') plan;
  @attr('string') stripePriceId;

  get stripePriceIdLink() {
    return `https://dashboard.stripe.com/products/${this.stripePriceId}`;
  }

  get requirePrice() {
    return (
      this.product === 'container_overage.metered' ||
      this.product === 'dashboard_users' ||
      this.namespace === 'container_prebilled'
    );
  }

  get requireQuantityIncluded() {
    return this.product === 'dashboard_users' || this.namespace === 'container_prebilled';
  }

  get requireStripePriceId() {
    return this.product === 'container_overage.graduate' || this.product === 'container_overage.volume';
  }
}
