export const filterWithLimit = (items, fn, searchLimit = 150) => {
  const matches = [];

  for (let index = 0; index < items.length; index++) {
    if (fn(items[index])) {
      matches.push(items[index]);
    }

    if (matches.length > searchLimit) {
      break;
    }
  }

  return matches;
};
