import { camelizeKeys, dasherizeKeys } from '../utils/object-functions';
import ApplicationSerializer from './application';

export default class AccountSerializer extends ApplicationSerializer {
  normalize(typeClass, hash) {
    // Dasherize attributes keys and relationships keys
    // In order to support response from V2 Serializer
    hash.attributes = dasherizeKeys(hash.attributes);
    hash.relationships = dasherizeKeys(hash.relationships);

    const normalizedHash = super.normalize(typeClass, hash);

    if (normalizedHash.data.attributes.flags) {
      normalizedHash.data.attributes.flags = camelizeKeys(normalizedHash.data.attributes.flags);
    }

    return normalizedHash;
  }
}
