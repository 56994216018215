import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  description: validator('presence', {
    presence: true,
    description: 'Description',
  }),
  rateCategory: validator('presence', {
    presence: true,
    description: 'Rate Category',
  }),
  // TODO: bring these back when i finish payout refactor
  //  unitPrice: validator('presence', {presence: true, description: 'Unit Price'}),
  //  amount: [
  //    validator('presence', {presence: true, description: 'Amount'}),
  //    validator('number', {gt: 0, description: 'Amount',})
  //  ],
  quantity: [
    validator('presence', { presence: true, description: 'Quantity' }),
    validator('format', {
      value: (model, attr) => String(model.get(attr)),
      regex: /^\d{1,6}(\.+\d{1,2})?$/,
      message: 'Quantity should not have more than 2 decimal places',
    }),
  ],
});
