import type StoreService from '@ember-data/store';
import Service, { inject as service } from '@ember/service';
import Evented from '@ember/object/evented';
import { next } from '@ember/runloop';
import { isEmpty } from '@ember/utils';
import { singularize } from 'ember-inflector';
import type SessionService from 'tnt-ui/services/session';

export default class BroadcastService extends Service.extend(Evented) {
  @service('app-socket') declare appSocket: any;
  @service declare store: StoreService;
  @service declare session: SessionService;

  _subscription: any = undefined;

  setupSocket(reconnect = false) {
    if (this.session.isAuthenticated && isEmpty(this._subscription)) {
      const authToken = this.session.data.authenticated.access_token;
      const connection = this.appSocket.setupSocket(authToken, reconnect);
      if (connection) {
        this._setupBroadcastChannel(connection);
      }
    }
  }

  _setupBroadcastChannel(connection: any) {
    const subscription = connection.createSubscription('BroadcastChannel', {
      received: (message: any) => {
        if (message.resource) {
          this._pushPayload(JSON.parse(message.resource));
        } else if (message.event) {
          this.trigger(message.event);
        }
      },
    });
    this._subscription = subscription;
  }

  remove() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  _pushPayload(payload: any) {
    next(this, () => {
      this.store.pushPayload(payload);
      this.trigger('update', this.store.peekRecord(singularize(payload.data.type), payload.data.id));
    });
  }
}
