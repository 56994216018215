import Model, { attr } from '@ember-data/model';

export default class WebhookEventCategoryModel extends Model {
  @attr('string') declare name: string;
  @attr() declare events: any;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'webhook-event-category': WebhookEventCategoryModel;
  }
}
