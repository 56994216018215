/* import __COLOCATED_TEMPLATE__ from './confirmation.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 * @param {Function}        closeAction
 * @param {Function}        confirmAction
 * @param {Boolean}         confirmDisabled
 * @param {String}          title
 * @param {String}          confirmText
 * @param {String}          confirmAction
 */
export default class ModalsConfirmationComponent extends Component {
  @action
  confirm(event) {
    event.preventDefault();
    if (!this.args.confirmDisabled) {
      this.args.confirmAction();
    }
  }
}
