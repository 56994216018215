import { not } from '@ember/object/computed';
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  status: validator('presence', { presence: true, description: 'Status' }),
  timestamp: validator('presence', {
    presence: true,
    dependentKeys: ['isDelivered'],
    disabled: not('model.isDelivered'),
  }),
});
