import V2ApiAdapter from './v2-api';

export default class ContractDetailAdapter extends V2ApiAdapter {
  urlForQueryRecord(query, _modelName) {
    const url = [this.urlPrefix(), 'billing_accounts', query.billingAccountId, 'contract_detail'].join('/');
    delete query.billingAccountId;

    if (url.includes('https')) {
      return url;
    } else {
      return '/' + url;
    }
  }
}
