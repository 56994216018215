/* eslint-disable ember/no-computed-properties-in-native-classes, ember/no-get, ember/use-brace-expansion */
import EmberObject, { computed, getProperties } from '@ember/object';
import { reads } from '@ember/object/computed';

export default class AddressPresenter extends EmberObject {
  @reads('model.companyName') title;

  @computed('model.city', 'model.stateAbbr')
  get subtitle() {
    const { city, stateAbbr } = getProperties(this.model, 'city', 'stateAbbr');
    return [city, stateAbbr].join(', ');
  }
}
