import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { modelValidator, type ValidatedModel, type ValidationsConfig } from 'ember-model-validator';
import type TrackingRequestAdapter from 'tnt-ui/adapters/tracking-request';
import type AccountModel from 'tnt-ui/models/account';
import type UserModel from 'tnt-ui/models/user';
import { toJSON } from 'tnt-ui/utils/toJSON';
import type ShippingLineModel from './shipping-line';

@modelValidator
class TrackingRequestModel extends Model {
  @belongsTo('account', { async: true }) declare account: AsyncBelongsTo<AccountModel> | AccountModel;
  @belongsTo('account', { async: true }) declare customer?: AsyncBelongsTo<AccountModel> | AccountModel;
  @belongsTo('user', { async: false }) declare user: UserModel;
  @belongsTo('shipment-v2', { async: false }) declare trackedObject: any;

  @attr('string') declare requestNumber: string;
  @attr('string', { defaultValue: 'bill_of_lading' }) declare requestType: 'container' | 'bill_of_lading';
  @attr('string') declare scac?: string;
  @attr('string') declare status: string;
  @attr('string') declare failedReason: string;
  @attr('string') declare creationPathway: string;
  @attr('date') declare createdAt: Date;
  @attr() declare shipmentTags: string[];
  @attr() declare refNumbers: string[];

  @tracked shippingLine?: ShippingLineModel;

  validations: ValidationsConfig = {
    requestNumber: { presence: true },
    shippingLine: { presence: true },
  };

  get shipment() {
    return this.trackedObject;
  }

  async resubmit() {
    const adapter = this.store.adapterFor('tracking-request' as never) as TrackingRequestAdapter;
    await adapter.resubmit(this);
  }

  toJSON(): Record<string, unknown> {
    return toJSON(this);
  }
}

interface TrackingRequestModel extends ValidatedModel, Model {}

export default TrackingRequestModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tracking-equest': TrackingRequestModel;
  }
}
