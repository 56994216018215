/* import __COLOCATED_TEMPLATE__ from './container-view-shared-form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ModalsContainerViewFormComponent extends Component {
  @service store;
  @service localStorage;
  @service router;
  @service modals;

  get iconClasses() {
    return ['tw-w-5', 'tw-text-gray-300', 'fa-solid', `fa-${this.args.content.containerView.icon ?? 'ship'}`].join(' ');
  }

  get icons() {
    return [
      'location-pin',
      'clock',
      'circle-exclamation',
      'grid',
      'container-storage',
      'ship',
      'train',
      'anchor',
      'clock-rotate-left',
      'backward',
      'circle-check',
      'circle-user',
    ];
  }

  // This is a special method that ember calls when can't find the varible on 'this' reference
  unknownProperty(key) {
    return this.args[key] || this.args.content?.[key];
  }

  @action
  loadIcon(element) {
    element.innerHTML = `<i class='${this.iconClasses}' />`;
  }

  @action
  closeModal() {
    this.modals.close('container-view-shared-form');
  }

  @action
  changeToCreateViewModal() {
    const containerView = this.args.content.containerView;
    const clonedContainerView = this.args.content.onClone(containerView);

    this.modals.open('container-view-form', {
      containerView: clonedContainerView,
      onSave: this.args.content.onSave,
    });
    this.closeModal();
  }
}
