import V2ApiAdapter from './v2-api';

export default class RouteV2Adapter extends V2ApiAdapter {
  buildURL(modelName, id, snapshot, requestType, query) {
    return super.buildURLV2(modelName, id, snapshot, requestType, query);
  }

  urlForQueryRecord(query, modelName) {
    const { containerId } = query;

    if (containerId) {
      return `${this.buildURL('container', containerId)}/route`;
    } else {
      return super.urlForQueryRecord(query, modelName);
    }
  }
}
