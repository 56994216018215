export const US_STATES = [
  { v: 'AL', k: 'Alabama' },
  { v: 'AK', k: 'Alaska' },
  { v: 'AZ', k: 'Arizona' },
  { v: 'AR', k: 'Arkansas' },
  { v: 'AS', k: 'American Samoa' },
  { v: 'CA', k: 'California' },
  { v: 'CO', k: 'Colorado' },
  { v: 'CT', k: 'Connecticut' },
  { v: 'DE', k: 'Delaware' },
  { v: 'DC', k: 'District Of Columbia' },
  { v: 'FL', k: 'Florida' },
  { v: 'FM', k: 'Federated States of Micronesia' },
  { v: 'GA', k: 'Georgia' },
  { v: 'GU', k: 'Guam' },
  { v: 'HI', k: 'Hawaii' },
  { v: 'ID', k: 'Idaho' },
  { v: 'IL', k: 'Illinois' },
  { v: 'IN', k: 'Indiana' },
  { v: 'IA', k: 'Iowa' },
  { v: 'KS', k: 'Kansas' },
  { v: 'KY', k: 'Kentucky' },
  { v: 'LA', k: 'Louisiana' },
  { v: 'ME', k: 'Maine' },
  { v: 'MH', k: 'Marshall Islands' },
  { v: 'MD', k: 'Maryland' },
  { v: 'MA', k: 'Massachusetts' },
  { v: 'MI', k: 'Michigan' },
  { v: 'MN', k: 'Minnesota' },
  { v: 'MS', k: 'Mississippi' },
  { v: 'MO', k: 'Missouri' },
  { v: 'MT', k: 'Montana' },
  { v: 'NE', k: 'Nebraska' },
  { v: 'NV', k: 'Nevada' },
  { v: 'NH', k: 'New Hampshire' },
  { v: 'NJ', k: 'New Jersey' },
  { v: 'NM', k: 'New Mexico' },
  { v: 'NY', k: 'New York' },
  { v: 'NC', k: 'North Carolina' },
  { v: 'ND', k: 'North Dakota' },
  { v: 'MP', k: 'Northern Mariana Islands' },
  { v: 'OH', k: 'Ohio' },
  { v: 'OK', k: 'Oklahoma' },
  { v: 'OR', k: 'Oregon' },
  { v: 'PA', k: 'Pennsylvania' },
  { v: 'PW', k: 'Palau' },
  { v: 'PR', k: 'Puerto Rico' },
  { v: 'RI', k: 'Rhode Island' },
  { v: 'SC', k: 'South Carolina' },
  { v: 'SD', k: 'South Dakota' },
  { v: 'TN', k: 'Tennessee' },
  { v: 'TX', k: 'Texas' },
  { v: 'UT', k: 'Utah' },
  { v: 'VT', k: 'Vermont' },
  { v: 'VA', k: 'Virginia' },
  { v: 'VI', k: 'Virgin Islands' },
  { v: 'WA', k: 'Washington' },
  { v: 'WV', k: 'West Virginia' },
  { v: 'WI', k: 'Wisconsin' },
  { v: 'WY', k: 'Wyoming' },
];
