import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { hasStatus } from '../utils/decorators';

export const statusNames = ['waiting', 'accepted', 'sent', 'canceled', 'expired', 'declined'];

@hasStatus(statusNames, 'currentStatus')
export default class OfferModel extends Model {
  @belongsTo('rate') rate;
  @belongsTo('account') truckingAccount;
  @belongsTo('booking-order') bookingOrder;
  @hasMany('cargo') cargos;

  @attr('string') currentStatus;
  @attr('string') customerName;
  @attr() locations;
  @attr('date') sentAt;
  @attr('date') repliedAt;
  @attr('date') expiresAt;
  @attr('date') createdAt;
}
