/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { dasherize } from '@ember/string';
import {
  Available,
  Delivered,
  Dropped,
  EmptyReturned,
  Grounded,
  Loaded,
  New,
  NotAvailable,
  OffDock,
  OnRail,
  OnShip,
  PickedUp,
} from 'tnt-ui/models/cargo';

export const STATUS_TEXTS = {
  [New]: 'No Status',
  [OnShip]: 'On Ship',
  [OnRail]: 'On Rail',
  [Grounded]: 'At Terminal',
  [OffDock]: 'Available at Shippers',
  [NotAvailable]: 'Not available',
  [Available]: 'Available',
  [PickedUp]: 'Picked up',
  [Delivered]: 'Delivered',
  [Dropped]: 'Dropped',
  [Loaded]: 'Loaded',
  [EmptyReturned]: 'Empty Returned',
};

export const SIMPLE_STATUS_TEXTS = Object.assign({}, STATUS_TEXTS, {
  [OffDock]: 'Available',
});

export default class ContainerTerminalStatusComponent extends Component {
  get simpleStatus() {
    return this.args.simpleStatus ?? false;
  }

  get statusText() {
    let status_texts = this.simpleStatus ? SIMPLE_STATUS_TEXTS : STATUS_TEXTS;
    return status_texts[this.args.status] ?? this.args.status;
  }

  get statusClassName() {
    return dasherize(this.args.status);
  }
}
