import Service from '@ember/service';
import moment from 'moment-timezone';

export default class TimezoneService extends Service {
  dateInTz = (tzDate, timeZone) => {
    if (tzDate == undefined) {
      return null;
    }
    // When we don't know what the TZ is we parse in UTC so that's
    // what we should fall back to.
    let eventTimeZone = timeZone || 'Etc/UTC';
    return moment.tz(tzDate, eventTimeZone);
  };

  timeZoneAbbr = (tzDate, timeZone) => {
    if (timeZone == undefined) {
      return null;
    }
    let momentTz = moment.tz.zone(timeZone);
    if (momentTz == null) {
      return null;
    }
    return moment.tz.zone(timeZone).abbr(tzDate);
  };
}
