import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export const ProofOfDelivery = 'proof_of_delivery',
  Categories = [ProofOfDelivery];

export default class DocumentModel extends Model {
  @belongsTo('user') user;
  @belongsTo('attachable', { polymorphic: true }) attachable;
  @hasMany('activity') activities;

  @attr('string') category;
  @attr('string') path;
  @attr('string') url;
  @attr('string') name;
  @attr('string') fileName;
  @attr('number') fileSize;
  @attr('string') fileType;
  @attr('date') createdAt;
}
