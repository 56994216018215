/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable prettier/prettier */
import type { Hold } from 'tnt-ui/schemas/hold';
import templateOnlyComponent from '@ember/component/template-only';

interface ContainersNewArrayHoldsSignature {
  Element: HTMLDivElement;
  Args: {
    value: Hold[];
  };
  Blocks: {
    default: [];
  };
}

const ContainersNewArrayHolds = templateOnlyComponent<ContainersNewArrayHoldsSignature>();

export default ContainersNewArrayHolds;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContainersNew::Array::Holds': typeof ContainersNewArrayHolds;
    'containers-new/array/holds': typeof ContainersNewArrayHolds;
  }
}
