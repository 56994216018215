/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable prettier/prettier */
import templateOnlyComponent from '@ember/component/template-only';
import { type Task } from 'ember-concurrency';

interface ContainersNewArrayReferenceSignature {
  Element: HTMLDivElement;
  Args: {
    value: string[];
    onSearch: Task<void, any>;
  };
  Blocks: {
    default: [];
  };
}

const ContainersNewArrayReference = templateOnlyComponent<ContainersNewArrayReferenceSignature>();

export default ContainersNewArrayReference;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContainersNew::Array::Reference': typeof ContainersNewArrayReference;
    'containers-new/array/reference': typeof ContainersNewArrayReference;
  }
}
