import { not } from '@ember/object/computed';
import { buildValidations, validator } from 'ember-cp-validations';

const street1 = validator('is-admin', { description: 'Street1' });
const city = validator('is-admin', { description: 'City' });
const stateAbbr = validator('is-admin', { description: 'State' });
const zip = validator('is-admin', { description: 'Zip' });
const domain = validator('account-domain', {});
const companyName = validator('presence', {
  presence: true,
  description: 'Company name',
});

export default buildValidations({
  companyName,
  domain,
  street1,
  city,
  stateAbbr,
  zip,
  scac: validator('presence', {
    presence: true,
    description: 'Scac',
    dependentKeys: ['isTrucker'],
    disabled: not('model.isTrucker'),
  }),
  abbrName: [
    validator('length', {
      min: 4,
      max: 4,
    }),
    validator('format', {
      regex: /^\w+$/,
      message: 'Account abbr can only contain letters and numbers',
      disabled: not('model.abbrName'),
    }),
    validator('ds-error'),
  ],
  usdot: [
    validator('presence', {
      presence: true,
      description: 'DOT number',
      dependentKeys: ['isTrucker'],
      disabled: not('model.isTrucker'),
    }),
    validator('number', {
      allowString: true,
      integer: true,
      gte: 0,
      description: 'DOT',
      disabled: not('model.isTrucker'),
    }),
  ],
});
