/* eslint-disable ember/no-computed-properties-in-native-classes, ember/require-computed-property-dependencies */
import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';

export default class CargoShipmentEventModel extends Model {
  @belongsTo('vessel') vessel;
  @belongsTo('cargo') cargo;
  @belongsTo('location', { polymorphic: true, async: false }) location;

  @attr('plaindate') estimatedOn;
  @attr('plaindate') actualOn;
  @attr('date') estimatedAt;
  @attr('date') actualAt;
  @attr('date') willOccurAt;
  @attr('boolean') archived;
  @attr('string') event;
  @attr('string') originalEvent;
  @attr('string') locationRaw;
  @attr('string') voyageNum;
  @attr('number') index;
  @attr('string') timeZone;
  @attr('string') dataSource;
  @attr('date') createdAt;

  @computed('actualOn', 'estimatedOn')
  get hasTime() {
    return this.actualOn == null && this.estimatedOn == null;
  }

  @computed('actualOn', 'actualAt')
  get estimated() {
    return this.actualOn == null && this.actualAt == null;
  }

  @computed('actualOn', 'estimatedOn')
  get eventTimestamp() {
    return this.actualAt || this.actualOn || this.estimatedAt || this.estimatedOn;
  }

  get vesselDeparted() {
    return this.event === 'full_out';
  }

  get vesselArrived() {
    return this.event === 'vessel_arrived';
  }
}
