/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable prettier/prettier */
import templateOnlyComponent from '@ember/component/template-only';

interface HeaderLogoSignature {
  Element: HTMLDivElement;
  Args: {};
}

const HeaderLogoComponent = templateOnlyComponent<HeaderLogoSignature>();

export default HeaderLogoComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    HeaderLogo: typeof HeaderLogoComponent;
  }
}
