import { not } from '@ember/object/computed';
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  product: validator('presence', {
    presence: true,
  }),
  price: validator('presence', {
    presence: true,
    disabled: not('model.requirePrice'),
  }),
  quantityIncluded: validator('presence', {
    presence: true,
    disabled: not('model.requireQuantityIncluded'),
  }),
  stripePriceId: validator('presence', {
    presence: true,
    disabled: not('model.requireStripePriceId'),
  }),
});
