/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class DocumentItemComponent extends Component {
  @service notify;

  get showByline() {
    return this.args.showByline ?? true;
  }

  get filenameNewLine() {
    return this.args.filenameNewLine ?? false;
  }

  get showNotification() {
    return this.args.showNotification ?? true;
  }

  get showArchive() {
    return this.args.showArchive ?? true;
  }

  get attachableIsCargo() {
    const modelName = this.args.model.get('attachable.content.constructor.modelName');
    return modelName === 'cargo';
  }

  @task
  *archive() {
    try {
      yield this.args.model.destroyRecord();
      if (this.showNotification) {
        this.notify.success('Document has been archived!');
      }
    } catch (e) {
      this.notify.error(e.errors[0].detail);
    }
  }
}
