import ApplicationAdapter from './application';

export default class AccountAdapter extends ApplicationAdapter {
  urlForQuery(query) {
    if (query.accountId) {
      const url = `${super.urlForQuery(...arguments)}/${query.accountId}/customers`;
      delete query.accountId;
      return url;
    }

    return super.urlForQuery(...arguments);
  }

  /**
   * @param {Account} model
   * @return @return {Promise}
   */
  async addCustomer(model, companyName) {
    const data = {
      type: 'accounts',
      attributes: {
        'company-name': companyName,
      },
    };

    const url = this.buildURL('accounts', model.id) + '/add_customer';
    return this.ajax(url, 'POST', { data: { data } });
  }
}
