/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isNone } from '@ember/utils';
import moment from 'moment-timezone';
import currentSession from 'tnt-ui/decorators/current-session';

@currentSession
export default class CargoPickupAppointmentStatusComponent extends Component {
  @service fetch;
  @service store;

  appointmentTimeFormat = 'MMM D, h:mm A';
  appointmentDateFormat = 'MMM D';
  machineTimeFormat = 'YYYY-MM-DDTHH:mm';
  machineDateFormat = 'YYYY-MM-DD';

  @action
  markAsDelivered() {
    this.args.cargo.set('currentStatus', 'delivered');
    this.args.cargo.save();
  }

  get timeZone() {
    return this.args.cargo.get('shipment.portOfDischarge.timeZone') ?? 'America/Los_Angeles';
  }

  get deliveryAppointmentCssClass() {
    return this.args.cargo.deliveryAppointmentOn ? 'appointment-upcoming' : 'appointment-not-made';
  }

  get deliveryAppointment() {
    let at = this.args.cargo.deliveryAppointmentAtLocation,
      on = this.args.cargo.deliveryAppointmentOn;
    if (at) {
      return moment(at).format(this.appointmentTimeFormat);
    } else if (on) {
      return moment(on).format(this.appointmentDateFormat);
    }
    return '';
  }

  get deliveryAppointmentMachineReadable() {
    let at = this.args.cargo.deliveryAppointmentAtLocation,
      on = this.args.cargo.deliveryAppointmentOn;
    if (at) {
      return moment(at).format(this.machineTimeFormat);
    } else if (on) {
      return moment(on).format(this.machineDateFormat);
    }
    return '';
  }

  get pickupAppointmentStatus() {
    if (isNone(this.daysToPickupAppointment)) {
      return 'notMade';
    }
    if (this.daysToPickupAppointment >= 0) {
      return 'upcoming';
    } else {
      return 'past';
    }
  }

  get pickupAppointmentCssClass() {
    if (this.args.cargo.pickedUp) {
      return 'appointment-complete';
    }
    switch (this.pickupAppointmentStatus) {
      case 'notMade':
        return 'appointment-not-made';
      case 'upcoming':
        return 'appointment-upcoming';
      case 'past':
        return 'appointment-past-due';
    }
    return '';
  }

  get pickupAppointment() {
    let at = this.args.cargo.pickupAppointmentAtLocation,
      on = this.args.cargo.pickupAppointmentOn,
      day = this.daysToPickupAppointment * -1;
    switch (this.pickupAppointmentStatus) {
      case 'upcoming':
        if (at) {
          return moment(at).format(this.appointmentTimeFormat);
        } else if (on) {
          return moment(on).format(this.appointmentDateFormat);
        }
        break;
      case 'past':
        return 'Past Due: ' + day + ' day' + (day > 1 ? 's' : '');
    }
    return '';
  }

  get pickupAppointmentMachineReadable() {
    let at = this.args.cargo.pickupAppointmentAtLocation,
      on = this.args.cargo.pickupAppointmentOn;
    if (at) {
      return moment(at).format(this.machineTimeFormat);
    } else if (on) {
      return moment(on).format(this.machineDateFormat);
    }
    return '';
  }

  get daysToPickupAppointment() {
    let at = this.args.cargo.pickupAppointmentAt,
      pickup = at ? at : this.args.cargo.pickupAppointmentOn;
    if (pickup) {
      let currentDate = moment().startOf('day'),
        pickupAppointmentDate = moment(pickup).startOf('day');
      return pickupAppointmentDate.diff(currentDate, 'days');
    }
    return '';
  }
}
