import Model, { attr, belongsTo } from '@ember-data/model';
import type LocationModel from 'tnt-ui/models/location';
import type PortModel from 'tnt-ui/models/port';
import type RouteModel from 'tnt-ui/models/route';
import type VesselV2Model from 'tnt-ui/models/vessel-v2';

export default class RouteLocationModel extends Model {
  @belongsTo('route', { async: false }) declare route: RouteModel;
  @belongsTo('vessel-v2', { async: false }) declare inboundVessel: VesselV2Model;
  @belongsTo('vessel-v2', { async: false }) declare outboundVessel: VesselV2Model;

  // Could be Port, Metro-Area, Terminal
  @belongsTo('location', { async: false, polymorphic: true }) declare location: LocationModel;
  @belongsTo('location', { async: false, polymorphic: true }) declare facility: LocationModel;

  @attr('string') declare inboundScac: string;
  @attr('string') declare inboundMode: string;
  @attr('string') declare inboundEtaAt: string;
  @attr('string') declare inboundAtaAt: string;
  @attr('string') declare inboundVoyageNumber: string;
  @attr('string') declare outboundScac: string;
  @attr('string') declare outboundMode: string;
  @attr('string') declare outboundEtdAt: string;
  @attr('string') declare outboundAtdAt: string;
  @attr('string') declare outboundVoyageNumber: string;
  @attr('string') declare createdAt: string;
  @attr('string') declare updatedAt: string;

  get portLocation() {
    return this.location as PortModel;
  }

  get hasInboundVessel() {
    return Boolean(this.inboundVessel?.id);
  }

  get hasOutboundVessel() {
    return Boolean(this.outboundVessel?.id);
  }

  get isPortOfLading() {
    return this.hasOutboundVessel && !this.hasInboundVessel;
  }

  get isPortOfDischarge() {
    return this.hasInboundVessel && !this.hasOutboundVessel;
  }

  get isTransshipment() {
    return this.hasOutboundVessel && this.hasInboundVessel;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'route-location': RouteLocationModel;
  }
}
