import { isBlank } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';
import moment from 'moment-timezone';

export default class Time extends BaseValidator {
  validate(value, options) {
    if (options.allowBlank && isBlank(value)) {
      return true;
    } else {
      if (moment(value, 'HH:mm', true).isValid()) {
        return true;
      }
      return options.message ? options.message : '24-hour format HH:MM';
    }
  }
}
