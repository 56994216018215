/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import window from 'ember-window-mock';

export default class ShipmentStatusAtLineComponent extends Component {
  @service notify;

  @tracked isDisabled = false;

  @action
  async stopTracking() {
    try {
      this.isDisabled = true;

      const confirmed = window.confirm(
        "This will immediately stop any further checks on this shipment status and move it to the 'Stopped Tracking' tab.\nAre you sure you wish to continue?",
      );

      if (confirmed) {
        await this.args.shipment.stopTracking();
        await this.args.shipment.reload();
      }

      return false;
    } finally {
      this.isDisabled = false;
    }
  }

  @action
  async resumeTracking() {
    try {
      this.isDisabled = true;

      await this.args.shipment.resumeTracking();
      await this.args.shipment.reload();

      this.notify.success('Shipment tracking resumed!');
      return false;
    } finally {
      this.isDisabled = false;
    }
  }
}
