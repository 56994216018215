import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  origin: {
    validators: [
      validator('presence', {
        presence: true,
        message: 'Please select the port and terminal',
      }),
    ],
  },
  destination: {
    validators: [
      validator('presence', {
        presence: true,
        message: 'Please select the delivery location',
      }),
    ],
  },
  documents: {
    validators: [
      validator('DocumentPresent', {
        category: 'delivery_order',
        message: 'Please upload a Delivery Order document',
      }),
    ],
  },
});
