/* eslint-disable ember/no-computed-properties-in-native-classes */

import { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { not } from '@ember/object/computed';
import LocationModel from 'tnt-ui/models/location';
import { COUNTRIES } from 'tnt-ui/utils/fixtures/countries';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';
import Validations from '../utils/validations/address';

function formatAddress(context) {
  const { line1, city, state, stateAbbr } = context;
  const country = context.get('countryName');
  const abbreviation = state || stateAbbr || country ? `, ${state || stateAbbr} ${country}` : '';
  return `${line1} ${city || ''}${abbreviation}`;
}

const fields = ['companyName', 'line1', 'line2', 'city', 'country', 'zip'];

export default class AddressModel extends LocationModel.extend(Validations) {
  @belongsTo('account', { async: false }) account;
  @hasMany('rate') rates;

  @attr('string') companyName;
  @attr('string') line1;
  @attr('string') line2;
  @attr('string') city;
  @attr('string') state;
  @attr('string') stateAbbr;
  @attr('string') zip;
  @attr('string') country;
  @attr('time') deliveryHoursFrom;
  @attr('time') deliveryHoursTo;
  @attr('string') deliveryInstructions;
  @attr('string') appointmentInstructions;
  @attr('boolean') appointmentRequired;
  @attr('number') deliveryHourFrom;
  @not('isNew') isSaved;
  @attr('tracked-array', {
    defaultValue: () => {
      const contact = new TrackedObject({ name: null, email: null, phone: null });
      return new TrackedArray([contact]);
    },
  })
  contacts;

  locationType = 'address';

  @computed(...fields)
  get formattedLine() {
    return formatAddress(this);
  }

  @computed('country')
  get isDomestic() {
    return this.country === 'US' || this.country === undefined;
  }

  @computed('country', 'state', 'stateAbbr')
  get displayState() {
    return this.country === 'US' || this.country === undefined ? this.stateAbbr : this.state;
  }

  @computed('country')
  get countryName() {
    const country = COUNTRIES.findBy('v', this.country);
    if (country !== undefined) {
      return country.k;
    }
    return this.country;
  }
}
