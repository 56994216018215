/* eslint-disable prettier/prettier */
import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';

export default class LocalStorageService extends Service {
  @tracked cacheBuster: number = 0;

  setItem(key: string, value: any): void {
    localStorage.setItem(key, value);
    this.cacheBuster = Math.random();
  }

  getItem(key: string): any {
    this.cacheBuster;
    return localStorage.getItem(key);
  }

  writeItem(key: string, value: any): void {
    const item = JSON.stringify(value);

    this.setItem(key, item);
    this.cacheBuster = Math.random();
  }

  readItem<T>(key: string): T | null {
    this.cacheBuster;
    const item = this.getItem(key);

    if (!item) {
      return null;
    }
    try {
      return JSON.parse(item);
    } catch (error) {
      return item;
    }
  }

  clear() {
    localStorage.clear();
  }
}
