/* eslint-disable prettier/prettier */
import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import SessionService from 'tnt-ui/services/session';
import NotifyService from 'ember-notify/services/notify';
import JSConfetti from 'js-confetti';
import { isTesting } from '@embroider/macros';
import UserModel from 'tnt-ui/models/user';
import MetricsService from './metrics';
import HubspotService from './hubspot';

export type Task = {
  id: string;
  serverId: keyof UserModel;
  title: string;
  completionDatetime?: string;
};

export const tasks: Task[] = [
  {
    id: 'create-account',
    serverId: 'completedCreateAccount',
    title: 'Create an account',
  },
  {
    id: 'user-info',
    serverId: 'completedUserInfo',
    title: 'Tell us more about you and your company',
  },
  {
    id: 'track-shipment',
    serverId: 'completedTrackShipments',
    title: 'Begin tracking shipments and containers',
  },
  {
    id: 'invite-user',
    serverId: 'completedInviteUser',
    title: 'Invite team members',
  },
];

export default class OnboardingService extends Service {
  @service declare session: SessionService;
  @service declare notify: NotifyService;
  @service declare metrics: MetricsService;
  @service declare hubspot: HubspotService;

  @tracked tasks: Task[] = tasks.map((task) => ({
    ...task,
    completionDatetime: this.completionDatetime(task),
  }));

  get currentUser() {
    return this.session.currentUser;
  }

  get firstIncompleteTask() {
    return this.tasks.find((task) => !task.completionDatetime);
  }

  get numTasks() {
    return this.tasks.length;
  }

  get numCompleted() {
    return this.tasks.filter((task) => task.completionDatetime).length;
  }

  get onboardingComplete() {
    return this.numCompleted === this.numTasks;
  }

  completionDatetime(task: Task): string | undefined {
    return this.currentUser?.get(task.serverId) as string | undefined;
  }

  isComplete(taskId: string) {
    const task = this.tasks.find((task) => task.id === taskId);
    return task?.completionDatetime;
  }

  async completeTask(task: Task) {
    const user = this.currentUser;
    if (!user) {
      return;
    }
    const currentValue = user.get(task.serverId);
    if (currentValue) {
      return;
    }

    this.metrics.trackEvent({ event: `Onboarding Task Completed - ${task.id}` });

    user.set(task.serverId, new Date().toISOString());
    this.tasks = tasks.map((task) => ({
      ...task,
      completionDatetime: this.completionDatetime(task),
    }));
    await user.save();
    await this.hubspot.updateUser(user.id);

    this.showConfettiIfDone();
  }

  async completeTaskAccountCreated() {
    const task = this.tasks.find((task) => task.id === 'create-account') as Task;
    await this.completeTask(task);
  }

  async completeTaskUserInfo() {
    const task = this.tasks.find((task) => task.id === 'user-info') as Task;
    await this.completeTask(task);
  }

  async completeTaskTrackShipment() {
    const task = this.tasks.find((task) => task.id === 'track-shipment') as Task;
    const isFirstTimeCompletingTask = !task.completionDatetime;

    await this.completeTask(task);

    if (isFirstTimeCompletingTask) {
      this.showToastLinkToOnboarding();
    }
  }

  async completeTaskInviteUser() {
    const task = this.tasks.find((task) => task.id === 'invite-user') as Task;
    await this.completeTask(task);
  }

  showToastLinkToOnboarding() {
    const numRemaining = this.numTasks - this.numCompleted;
    const link =
      '<a href="/onboarding" style="color: #000" id="onboarding-return-notification">Click here to go back to the onboarding page.</a>';
    let message = '';
    if (numRemaining === 0) {
      message =
        "Onboarding complete!  There's still plenty more to discover, so be sure to explore the dashboard and visit our help center for more information.";
    } else if (numRemaining === 1) {
      message = `Task complete! One more onboarding task remaining. ${link}`;
    } else {
      message = `Task complete! ${numRemaining} more onboarding tasks remaining. ${link}`;
    }
    this.notify.info({
      html: message,
      closeAfter: isTesting() ? 5 : 10000,
    });
  }

  showConfettiIfDone() {
    const numRemaining = this.numTasks - this.numCompleted;
    if (numRemaining === 0) {
      const jsConfetti = new JSConfetti();
      jsConfetti.addConfetti({
        confettiNumber: 500,
      });
      jsConfetti.addConfetti({
        emojis: ['🚢', '📦', '🏗️', '🚚', '🚂', '🚛', '🛳️'],
        confettiNumber: 20,
      });
    }
  }
}

declare module 'tnt-ui/services/session' {
  export default interface Registry {
    onboarding: typeof OnboardingService;
  }
}
