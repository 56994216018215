import { isPresent } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

export default class AccountDomain extends BaseValidator {
  validate(value, _options, model) {
    if (!model.isNew) return true;
    if (isPresent(value)) {
      return true;
    } else {
      return "Domain can't be blank";
    }
  }
}
