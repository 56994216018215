import ApplicationAdapter from './application';

export default class ShipmentAdapter extends ApplicationAdapter {
  urlForFindRecord(id, modelName, snapshot) {
    if (snapshot.adapterOptions?.useAnonymousEndpoint) {
      const { shareId } = snapshot.adapterOptions;
      return `${this.urlPrefix()}/share/${shareId}/shipment/${id}`;
    }
    return this.buildURL(modelName, id, snapshot);
  }
}
