/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isBlank, isEqual, isPresent } from '@ember/utils';

export default class NavBarAuthenticatedComponent extends Component {
  @service session;
  @service router;
  @service featureFlags;

  get isAdmin() {
    return this.args.currentUser?.admin ?? false;
  }

  get flags() {
    return this.args.currentAccount?.flags ?? {};
  }

  get showDeliveryOrders() {
    return this.flags.deliveryOrders;
  }

  get showPayments() {
    return this.args.currentAccount?.isTrucker ?? false;
  }

  get showDevelopers() {
    return this.flags.apiAccess;
  }

  get showTrackAndTrace() {
    return this.flags.trackAndTrace;
  }

  get setupDelivery() {
    return this.flags.setupDelivery;
  }

  get showReports() {
    return isPresent(this.args.currentAccount?.accountMetabaseDashboards);
  }

  get showRegistration() {
    return isBlank(this.args.currentAccount);
  }

  get anyReportLinkIsActive() {
    if (this.showReports) {
      return this.args.currentAccount?.accountMetabaseDashboards.find((m) => {
        return this.router.currentURL.includes(`/reports/${m.path}`);
      });
    } else {
      return false;
    }
  }

  get showVesselReport() {
    return isPresent(
      this.args.currentAccount?.accountMetabaseDashboards.find(({ path }) => isEqual(path, 'vessel-arrivals-beta')),
    );
  }

  @action
  onChangeAccount(current, account_id) {
    if (current) {
      return;
    }

    this.session.set('data.current_account_id', account_id);
    location.reload();
  }
}
