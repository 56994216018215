/* eslint-disable ember/no-classic-classes, ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import moment from 'moment-timezone';

export default DS.Transform.extend({
  deserialize(serialized) {
    return moment(serialized, 'HH:mm', true).isValid() ? serialized : null;
  },

  serialize(deserialized) {
    return moment(deserialized, 'HH:mm', true).isValid() ? deserialized + ':00' : null;
  },
});
