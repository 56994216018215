import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type LocationModel from 'tnt-ui/models/location';
import type VesselModel from 'tnt-ui/models/vessel';

export default class RawEventModel extends Model {
  @belongsTo('vessel') declare vessel: AsyncBelongsTo<VesselModel>;
  @belongsTo('location', { polymorphic: true, async: false }) declare location: AsyncBelongsTo<LocationModel>;

  @attr('date') declare actualOn?: Date;
  @attr('date') declare estimatedAt: Date;
  @attr('date') declare actualAt?: Date;
  @attr('string') declare event: string;
  @attr('string') declare originalEvent: string;
  @attr('string') declare locationName: string;
  @attr('string') declare locationLocode: string;
  @attr('string') declare voyageNum: string;
  @attr('number') declare index: number;
  @attr('string') declare timezone: string;
  @attr('date') declare createdAt: Date;

  get estimated() {
    return this.actualOn == null && this.actualAt == null;
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'raw-event': RawEventModel;
  }
}
