// @ts-ignore
import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  companyName: validator('presence', {
    presence: true,
    description: 'Company Name',
  }),
  accountEmail: {
    validators: [
      validator('presence', { presence: true, description: 'Account Email' }),
      validator('format', { type: 'email' }),
    ],
  },
});
