import Model, { attr, belongsTo } from '@ember-data/model';
import type LocationModel from 'tnt-ui/models/location';
import type ShipmentModel from 'tnt-ui/models/shipment';
import type TerminalModel from 'tnt-ui/models/terminal';
import type VesselModel from 'tnt-ui/models/vessel';

export default class TransportEventModel extends Model {
  @belongsTo('shipment', { async: false }) declare shipment: ShipmentModel;
  @belongsTo('container', { async: false }) declare container: any;
  @belongsTo('vessel', { async: false }) declare vessel: VesselModel;
  @belongsTo('location', { async: false, polymorphic: true }) declare location: LocationModel;
  @belongsTo('terminal', { async: false }) declare terminal: TerminalModel;

  @attr('string') declare event: string;
  @attr('string') declare createdAt: string;
  @attr('string') declare voyageNumber: string;
  @attr('string') declare timestamp: string;
  @attr('string') declare locationLocode: string;
  @attr('string') declare timezone: string;
}
