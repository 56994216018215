import V2ApiAdapter from './v2-api';

export default class VesselV2Adapter extends V2ApiAdapter {
  buildURL(modelName, id, snapshot, requestType, query) {
    return super.buildURLV2(modelName, id, snapshot, requestType, query);
  }

  urlForQueryRecord(query, modelName) {
    const { imo, id, ...otherQueryParams } = query;

    if (imo && Object.keys(otherQueryParams).length === 0) {
      return `${this.buildURL(modelName, imo)}/imo`;
    } else if (id) {
      return this.buildURL(modelName, id);
    } else {
      return super.urlForQueryRecord(query, modelName);
    }
  }
}
