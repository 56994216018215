import { helper } from '@ember/component/helper';

export function truncate(object, options) {
  var limit = options.limit || 25;
  var symbol = options.symbol || '...';
  if (object[0]) {
    var str = object[0].toString();
    if (str.length > limit) {
      str = str.substr(0, limit - 3) + symbol;
    }
    return str;
  } else {
    return '';
  }
}
export default helper(truncate);
