/* eslint-disable prettier/prettier */
import { helper } from '@ember/component/helper';

export function colorizeCargoIssue([str]: string[]) {
  if (str) {
    if (str.includes('past free day')) {
      return 'badge-danger';
    }

    switch (str) {
      case 'no_proof_of_delivery_uploaded':
      case 'fees':
      case 'last_free_day_today':
      case 'last_free_day_tomorrow':
      case 'holds':
        return 'badge-warning';
      case 'no_delivery_appointment':
        return 'badge-primary';
      case 'past_pickup':
        return 'badge-danger';
      default:
        return 'badge-default';
    }
  } else {
    return 'badge-default';
  }
}

const colorizeHelper = helper(colorizeCargoIssue);

export default colorizeHelper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'colorize-cargo-issue': typeof colorizeHelper;
  }
}
