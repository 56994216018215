// names
export const Customs = 'customs';
export const Usda = 'USDA';
export const Freight = 'freight';
export const Tmf = ' TMF';
export const Other = ' other';

// statuses
export const Pending = 'pending';
export const Hold = 'hold';

export const HoldsPresentation: Record<string, string> = {
  customs: 'Customs',
  USDA: 'USDA',
  freight: 'Freight',
  TMF: 'TMF',
  other: 'Other',
};

export type Hold = {
  name: string;
  status: string;
  description: string;
};

export const isCustomsPending = (hold: Hold) => hold.name === Customs && hold.status === Pending;
export const humanizeHoldName = (holdName: string) => HoldsPresentation[holdName] || holdName;
