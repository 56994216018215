/* eslint-disable ember/no-computed-properties-in-native-classes */
import Service, { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';

export default class InvoicesService extends Service {
  @service crud;
  @service session;
  @reads('session.currentUser') currentUser;

  create = (invoice) => {
    return this.crud.create('invoice', invoice);
  };

  save = (invoice) => {
    return this.crud.save(invoice);
  };

  markAsPaid = (invoice) => {
    invoice.setProperties({
      paidAt: new Date(),
      paidBy: this.currentUser,
    });
  };

  rollback(invoice) {
    invoice.rollbackAttributes();
  }

  addInvoice = (bookingOrder) => {
    const invoice = this.crud.fakeModel('invoice', {
      bookingOrder,
      displayId: this.displayId(bookingOrder),
      createdBy: this.currentUser,
      invoicedOn: new Date(),
    });
    return invoice;
  };

  // @private

  displayId(bookingOrder) {
    const invoiceCount = bookingOrder.get('invoices.length');
    return `${bookingOrder.get('displayId')}-INV-${invoiceCount + 1}`;
  }
}
