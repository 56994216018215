/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable prettier/prettier */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface ContainersNewArrayTagsSignature {
  Element: HTMLDivElement;
  Args: {
    columnId: string;
    value: string[];
    displayOnly: boolean;
    addToSearch: (columnId: string, tags: string[]) => void;
  };
  Blocks: {
    default: [];
  };
}

export default class ContainersNewArrayTags extends Component<ContainersNewArrayTagsSignature> {
  @action
  addToSearch(tag: string) {
    this.args.addToSearch(this.args.columnId, [tag]);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContainersNew::Array::Tags': typeof ContainersNewArrayTags;
    'containers-new/array/tags': typeof ContainersNewArrayTags;
  }
}
