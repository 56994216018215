/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

export default class AddressShowViewComponent extends Component {
  get hideContacts() {
    return this.args.hideContacts ?? false;
  }

  get disabled() {
    return this.args.disabled ?? false;
  }

  get showDeliveryInstructions() {
    return this.args.showDeliveryInstructions ?? false;
  }

  get showContacts() {
    return (this.args.address && this.args.address.get('contacts').length === 0) || this.hideContacts ? false : true;
  }
}
