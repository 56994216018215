/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

export default class DataConfidence extends Component {
  get confidenceLevel() {
    return this.args.row.get('confidenceLevel');
  }

  get confidenceReason() {
    return this.args.row.get('confidenceReason');
  }
}
