export const COUNTRIES = [
  { v: 'AF', k: 'Afghanistan' },
  { v: 'AL', k: 'Albania' },
  { v: 'DZ', k: 'Algeria' },
  { v: 'AS', k: 'American Samoa' },
  { v: 'AD', k: 'Andorra' },
  { v: 'AO', k: 'Angola' },
  { v: 'AI', k: 'Anguilla' },
  { v: 'AQ', k: 'Antarctica' },
  { v: 'AG', k: 'Antigua And Barbuda' },
  { v: 'AR', k: 'Argentina' },
  { v: 'AM', k: 'Armenia' },
  { v: 'AW', k: 'Aruba' },
  { v: 'AU', k: 'Australia' },
  { v: 'AT', k: 'Austria' },
  { v: 'AZ', k: 'Azerbaijan' },
  { v: 'BS', k: 'Bahamas' },
  { v: 'BH', k: 'Bahrain' },
  { v: 'BD', k: 'Bangladesh' },
  { v: 'BB', k: 'Barbados' },
  { v: 'BY', k: 'Belarus' },
  { v: 'BE', k: 'Belgium' },
  { v: 'BZ', k: 'Belize' },
  { v: 'BJ', k: 'Benin' },
  { v: 'BM', k: 'Bermuda' },
  { v: 'BT', k: 'Bhutan' },
  { v: 'BO', k: 'Bolivia' },
  { v: 'BA', k: 'Bosnia And Herzegovina' },
  { v: 'BW', k: 'Botswana' },
  { v: 'BV', k: 'Bouvet Island' },
  { v: 'BR', k: 'Brazil' },
  { v: 'IO', k: 'British Indian Ocean Territory' },
  { v: 'BN', k: 'Brunei Darussalam' },
  { v: 'BG', k: 'Bulgaria' },
  { v: 'BF', k: 'Burkina Faso' },
  { v: 'BI', k: 'Burundi' },
  { v: 'KH', k: 'Cambodia' },
  { v: 'CM', k: 'Cameroon' },
  { v: 'CA', k: 'Canada' },
  { v: 'CV', k: 'Cape Verde' },
  { v: 'KY', k: 'Cayman Islands' },
  { v: 'CF', k: 'Central African Republic' },
  { v: 'TD', k: 'Chad' },
  { v: 'CL', k: 'Chile' },
  { v: 'CN', k: 'China' },
  { v: 'CX', k: 'Christmas Island' },
  { v: 'CC', k: 'Cocos (keeling) Islands' },
  { v: 'CO', k: 'Colombia' },
  { v: 'KM', k: 'Comoros' },
  { v: 'CG', k: 'Congo' },
  { v: 'CD', k: 'Congo' },
  { v: 'CK', k: 'Cook Islands' },
  { v: 'CR', k: 'Costa Rica' },
  { v: 'CI', k: "Cote D'ivoire" },
  { v: 'HR', k: 'Croatia' },
  { v: 'CU', k: 'Cuba' },
  { v: 'CY', k: 'Cyprus' },
  { v: 'CZ', k: 'Czech Republic' },
  { v: 'DK', k: 'Denmark' },
  { v: 'DJ', k: 'Djibouti' },
  { v: 'DM', k: 'Dominica' },
  { v: 'DO', k: 'Dominican Republic' },
  { v: 'TP', k: 'East Timor' },
  { v: 'EC', k: 'Ecuador' },
  { v: 'EG', k: 'Egypt' },
  { v: 'SV', k: 'El Salvador' },
  { v: 'GQ', k: 'Equatorial Guinea' },
  { v: 'ER', k: 'Eritrea' },
  { v: 'EE', k: 'Estonia' },
  { v: 'ET', k: 'Ethiopia' },
  { v: 'FK', k: 'Falkland Islands' },
  { v: 'FO', k: 'Faroe Islands' },
  { v: 'FJ', k: 'Fiji' },
  { v: 'FI', k: 'Finland' },
  { v: 'FR', k: 'France' },
  { v: 'GF', k: 'French Guiana' },
  { v: 'PF', k: 'French Polynesia' },
  { v: 'TF', k: 'French Southern Territories' },
  { v: 'GA', k: 'Gabon' },
  { v: 'GM', k: 'Gambia' },
  { v: 'GE', k: 'Georgia' },
  { v: 'DE', k: 'Germany' },
  { v: 'GH', k: 'Ghana' },
  { v: 'GI', k: 'Gibraltar' },
  { v: 'GR', k: 'Greece' },
  { v: 'GL', k: 'Greenland' },
  { v: 'GD', k: 'Grenada' },
  { v: 'GP', k: 'Guadeloupe' },
  { v: 'GU', k: 'Guam' },
  { v: 'GT', k: 'Guatemala' },
  { v: 'GN', k: 'Guinea' },
  { v: 'GW', k: 'Guinea-bissau' },
  { v: 'GY', k: 'Guyana' },
  { v: 'HT', k: 'Haiti' },
  { v: 'HM', k: 'Heard Island And Mcdonald Islands' },
  { v: 'VA', k: 'Holy See (vatican City State)' },
  { v: 'HN', k: 'Honduras' },
  { v: 'HK', k: 'Hong Kong' },
  { v: 'HU', k: 'Hungary' },
  { v: 'IS', k: 'Iceland' },
  { v: 'IN', k: 'India' },
  { v: 'ID', k: 'Indonesia' },
  { v: 'IR', k: 'Iran' },
  { v: 'IQ', k: 'Iraq' },
  { v: 'IE', k: 'Ireland' },
  { v: 'IL', k: 'Israel' },
  { v: 'IT', k: 'Italy' },
  { v: 'JM', k: 'Jamaica' },
  { v: 'JP', k: 'Japan' },
  { v: 'JO', k: 'Jordan' },
  { v: 'KZ', k: 'Kazakstan' },
  { v: 'KE', k: 'Kenya' },
  { v: 'KI', k: 'Kiribati' },
  { v: 'KP', k: 'North Korea' },
  { v: 'KR', k: 'South Korea' },
  { v: 'KV', k: 'Kosovo' },
  { v: 'KW', k: 'Kuwait' },
  { v: 'KG', k: 'Kyrgyzstan' },
  { v: 'LA', k: 'Laos' },
  { v: 'LV', k: 'Latvia' },
  { v: 'LB', k: 'Lebanon' },
  { v: 'LS', k: 'Lesotho' },
  { v: 'LR', k: 'Liberia' },
  { v: 'LY', k: 'Libya' },
  { v: 'LI', k: 'Liechtenstein' },
  { v: 'LT', k: 'Lithuania' },
  { v: 'LU', k: 'Luxembourg' },
  { v: 'MO', k: 'Macau' },
  { v: 'MK', k: 'Macedonia' },
  { v: 'MG', k: 'Madagascar' },
  { v: 'MW', k: 'Malawi' },
  { v: 'MY', k: 'Malaysia' },
  { v: 'MV', k: 'Maldives' },
  { v: 'ML', k: 'Mali' },
  { v: 'MT', k: 'Malta' },
  { v: 'MH', k: 'Marshall Islands' },
  { v: 'MQ', k: 'Martinique' },
  { v: 'MR', k: 'Mauritania' },
  { v: 'MU', k: 'Mauritius' },
  { v: 'YT', k: 'Mayotte' },
  { v: 'MX', k: 'Mexico' },
  { v: 'FM', k: 'Micronesia' },
  { v: 'MD', k: 'Moldova' },
  { v: 'MC', k: 'Monaco' },
  { v: 'MN', k: 'Mongolia' },
  { v: 'MS', k: 'Montserrat' },
  { v: 'ME', k: 'Montenegro' },
  { v: 'MA', k: 'Morocco' },
  { v: 'MZ', k: 'Mozambique' },
  { v: 'MM', k: 'Myanmar' },
  { v: 'NA', k: 'Namibia' },
  { v: 'NR', k: 'Nauru' },
  { v: 'NP', k: 'Nepal' },
  { v: 'NL', k: 'Netherlands' },
  { v: 'AN', k: 'Netherlands Antilles' },
  { v: 'NC', k: 'New Caledonia' },
  { v: 'NZ', k: 'New Zealand' },
  { v: 'NI', k: 'Nicaragua' },
  { v: 'NE', k: 'Niger' },
  { v: 'NG', k: 'Nigeria' },
  { v: 'NU', k: 'Niue' },
  { v: 'NF', k: 'Norfolk Island' },
  { v: 'MP', k: 'Northern Mariana Islands' },
  { v: 'NO', k: 'Norway' },
  { v: 'OM', k: 'Oman' },
  { v: 'PK', k: 'Pakistan' },
  { v: 'PW', k: 'Palau' },
  { v: 'PS', k: 'Palestine' },
  { v: 'PA', k: 'Panama' },
  { v: 'PG', k: 'Papua New Guinea' },
  { v: 'PY', k: 'Paraguay' },
  { v: 'PE', k: 'Peru' },
  { v: 'PH', k: 'Philippines' },
  { v: 'PN', k: 'Pitcairn' },
  { v: 'PL', k: 'Poland' },
  { v: 'PT', k: 'Portugal' },
  { v: 'PR', k: 'Puerto Rico' },
  { v: 'QA', k: 'Qatar' },
  { v: 'RE', k: 'Reunion' },
  { v: 'RO', k: 'Romania' },
  { v: 'RU', k: 'Russia' },
  { v: 'RW', k: 'Rwanda' },
  { v: 'SH', k: 'Saint Helena' },
  { v: 'KN', k: 'Saint Kitts And Nevis' },
  { v: 'LC', k: 'Saint Lucia' },
  { v: 'PM', k: 'Saint Pierre And Miquelon' },
  { v: 'VC', k: 'Saint Vincent And The Grenadines' },
  { v: 'WS', k: 'Samoa' },
  { v: 'SM', k: 'San Marino' },
  { v: 'ST', k: 'Sao Tome And Principe' },
  { v: 'SA', k: 'Saudi Arabia' },
  { v: 'SN', k: 'Senegal' },
  { v: 'RS', k: 'Serbia' },
  { v: 'SC', k: 'Seychelles' },
  { v: 'SL', k: 'Sierra Leone' },
  { v: 'SG', k: 'Singapore' },
  { v: 'SK', k: 'Slovakia' },
  { v: 'SI', k: 'Slovenia' },
  { v: 'SB', k: 'Solomon Islands' },
  { v: 'SO', k: 'Somalia' },
  { v: 'ZA', k: 'South Africa' },
  { v: 'GS', k: 'South Georgia And The South Sandwich Islands' },
  { v: 'ES', k: 'Spain' },
  { v: 'LK', k: 'Sri Lanka' },
  { v: 'SD', k: 'Sudan' },
  { v: 'SR', k: 'Suriname' },
  { v: 'SJ', k: 'Svalbard And Jan Mayen' },
  { v: 'SZ', k: 'Swaziland' },
  { v: 'SE', k: 'Sweden' },
  { v: 'CH', k: 'Switzerland' },
  { v: 'SY', k: 'Syria' },
  { v: 'TW', k: 'Taiwan' },
  { v: 'TJ', k: 'Tajikistan' },
  { v: 'TZ', k: 'Tanzania' },
  { v: 'TH', k: 'Thailand' },
  { v: 'TG', k: 'Togo' },
  { v: 'TK', k: 'Tokelau' },
  { v: 'TO', k: 'Tonga' },
  { v: 'TT', k: 'Trinidad And Tobago' },
  { v: 'TN', k: 'Tunisia' },
  { v: 'TR', k: 'Turkey' },
  { v: 'TM', k: 'Turkmenistan' },
  { v: 'TC', k: 'Turks And Caicos Islands' },
  { v: 'TV', k: 'Tuvalu' },
  { v: 'UG', k: 'Uganda' },
  { v: 'UA', k: 'Ukraine' },
  { v: 'AE', k: 'United Arab Emirates' },
  { v: 'GB', k: 'United Kingdom' },
  { v: 'US', k: 'United States' },
  { v: 'UM', k: 'United States Minor Outlying Islands' },
  { v: 'UY', k: 'Uruguay' },
  { v: 'UZ', k: 'Uzbekistan' },
  { v: 'VU', k: 'Vanuatu' },
  { v: 'VE', k: 'Venezuela' },
  { v: 'VN', k: 'Viet Nam' },
  { v: 'VG', k: 'Virgin Islands, British' },
  { v: 'VI', k: 'Virgin Islands, U.S.' },
  { v: 'WF', k: 'Wallis And Futuna' },
  { v: 'EH', k: 'Western Sahara' },
  { v: 'YE', k: 'Yemen' },
  { v: 'ZM', k: 'Zambia' },
  { v: 'ZW', k: 'Zimbabwe' },
];
