import { camelize, underscore } from '@ember/string';
import V2ApiSerializer from './v2-api';

export default class ContainerViewSerializer extends V2ApiSerializer {
  normalize(typeClass, hash) {
    let settings = hash.attributes['settings'] || {};
    let newSettings = {};
    Object.keys(settings).forEach((key) => {
      newSettings[camelize(key)] = settings[key];
    });
    hash.attributes['settings'] = newSettings;
    return super.normalize(...arguments);
  }

  serialize(snapshot, options) {
    const json = super.serialize(snapshot, options);

    // Convert the settings object keys to underscored
    json.data.attributes.settings = Object.keys(json.data.attributes.settings).reduce((acc, key) => {
      acc[underscore(key)] = json.data.attributes.settings[key];
      return acc;
    }, {});

    return json;
  }

  _normalizeFragments() {
    // Do nothing, as this method is used in the parent class to normalize tracked objects/arrays
    // that used to be fragments. There is no distinction between tracked objects/arrays and
    // fragments anymore (as fragments became the former), but this class used `tracked-object` from
    // the beginning for its `settings` attribute and needs no normalization as a result.
  }
}
