import V2ApiAdapter from './v2-api';

export default class TransportEventV2Adapter extends V2ApiAdapter {
  buildURL(modelName, id, snapshot, requestType, query) {
    return super.buildURLV2(modelName, id, snapshot, requestType, query);
  }

  urlForQuery(query, modelName) {
    const { containerId, ...otherQueryParams } = query;

    if (containerId && Object.keys(otherQueryParams).length === 0) {
      return `${this.buildURL('container', containerId)}/transport_events`;
    } else {
      return super.urlForQueryRecord(query, modelName);
    }
  }
}
