import { isPresent } from '@ember/utils';
import V2ApiAdapter from './v2-api';

export default class ContractAdapter extends V2ApiAdapter {
  buildURL(_modelName, id, snapshot, _requestType, _query) {
    let url = [this.urlPrefix(), 'billing_accounts'];
    const billingAccountId = snapshot.adapterOptions?.billingAccountId;
    url.push(billingAccountId);
    url.push('contracts');
    if (isPresent(id)) {
      url.push(id);
    }

    const joinUrl = url.join('/');

    if (joinUrl.includes('https')) {
      return joinUrl;
    } else {
      return '/' + joinUrl;
    }
  }
}
