/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-classic-components, ember/no-component-lifecycle-hooks, ember/no-computed-properties-in-native-classes, ember/no-ember-super-in-es-classes, ember/no-get, ember/no-side-effects, ember/require-computed-property-dependencies, ember/require-super-in-lifecycle-hooks, ember/require-tagless-components, ember/use-brace-expansion */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action, computed } from '@ember/object';
import moment from 'moment-timezone';
import currentSession from 'tnt-ui/decorators/current-session';
import { Delivered, EmptyReturned } from 'tnt-ui/models/cargo';

@currentSession
export default class MarkAsDelivered extends Component {
  @service store;
  @service fetch;
  @service notify;

  errors = [];
  currentTime = moment().toDate();

  didReceiveAttrs() {
    this._super(...arguments);
    let record = this.store.createRecord('containerUpdate', {
      status: 'Delivered',
      source: 'User',
      cargoId: this.cargo.id,
    });
    this.set('newContainerUpdate', record);
  }

  @computed('cargo.pickedUpAt')
  get minDate() {
    if (this.cargo.pickedUpAt == null) {
      return null;
    }
    return moment(this.cargo.pickedUpAt).add(30, 'minutes').toDate();
  }

  @computed('cargo.emptyReturnedAt')
  get maxDate() {
    return this.cargo.emptyReturnedAt || this.currentTime;
  }

  @computed('cargo.currentStatus', 'cargo.deliveredAt')
  get canMarkAsDelivered() {
    return (this.cargo.inTerminal || this.cargo.pickedUp) && !this.cargo.deliveredAt;
  }

  @computed('newContainerUpdate.timestamp', 'cargo.delivered')
  get canUploadPod() {
    return (
      this.get('currentAccount.isTrucker') &&
      (this.get('newContainerUpdate.timestamp') || this.get('cargo.delivered') || this.get('cargo.isEmptyReturned'))
    );
  }

  @computed('canMarkAsDelivered', 'canUploadPod', 'validModalForm')
  get saveOnClose() {
    return this.canMarkAsDelivered && this.canUploadPod && this.validModalForm;
  }

  _reparseTime(dateTime) {
    let timeZone = this.cargo.timeZone;

    if (timeZone) {
      let timeString = moment(dateTime).format('YYYY-MM-DDTHH:mm');

      return moment.tz(timeString, timeZone).toDate();
    } else {
      return dateTime;
    }
  }

  @action
  setTimestamp(params) {
    this.set('pickedTimestamp', params[0]);

    this.newContainerUpdate.set('timestamp', this._reparseTime(params[0]));
  }

  @computed('newContainerUpdate.timestamp', 'cargo.podDocuments')
  get validModalForm() {
    this.errors.forEach((errorName) => {
      this.set(errorName, false);
    });

    let result = [];

    if (this.canMarkAsDelivered) {
      if (!this.newContainerUpdate.get('validations.isValid')) {
        result.push('newContainerUpdateError');
      }
    }

    if (this.canUploadPod) {
      if (this.cargo.podDocuments.length == 0) {
        result.push('podDocumentsError');
      }
    }

    this.set('errors', result);

    this.errors.forEach((errorName) => {
      this.set(errorName, true);
    });

    return this.errors.length == 0;
  }

  @action
  closeModal(doSave = false) {
    if (doSave) {
      if (!this.validModalForm) {
        return;
      }

      if (this.canMarkAsDelivered) {
        this.markCargoAsDelivered();
      }
    }

    this.toggleProperty('isMarkingAsDelivered');
  }

  @action
  markCargoAsDelivered() {
    this.newContainerUpdate
      .save()
      .then(() => {
        this.cargo.set('deliveredAt', this.newContainerUpdate.timestamp);
        if (this.cargo.currentStatus != EmptyReturned) {
          this.cargo.set('currentStatus', Delivered);
        }
        this.notify.success('Marked container as delivered');
      })
      .catch((e) => {
        window.console.log(e);

        this.notify.error('Unable to save event.');
      });
  }
}
