import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import currentSession from 'tnt-ui/decorators/current-session';

@currentSession
export default class ActivityController extends Controller {
  @service router;

  @action
  redirectToSubject(subjectId, subjectType) {
    if (subjectType === 'account') {
      this.router.transitionTo('settings.company');
    } else if (subjectType === 'shipment') {
      this.router.transitionTo('shipments.details', subjectId);
    }
  }
}
