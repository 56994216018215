import Model, { attr, belongsTo } from '@ember-data/model';
import type WebhookModel from 'tnt-ui/models/webhook';

export default class WebhookNotificationModel extends Model {
  @belongsTo('webhook') declare webhook: WebhookModel;

  @attr('string') declare event: string;
  @attr('string') declare deliveryStatus: string;
  @attr('date') declare createdAt: Date;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'webhook-notification': WebhookNotificationModel;
  }
}
