export const placeholderData = {
  destination: 'New Destination',
  eta: '',
  incoterm: 'FOB',
  origin: 'New Origin',
  journey: [
    {
      legType: 'location',
      locationType: 'port',
      state: 'done',
      label: 'Origin Port',
      showLabel: true,
      exception: '',
    },
    {
      legType: 'transit',
      transitMode: 'ocean',
      state: 'done',
      exception: '',
    },
    {
      legType: 'location',
      locationType: 'port',
      state: 'done',
      label: 'Dest. Port',
      showLabel: true,
      exception: '',
    },
  ],
};

export function buildJourneyLeg(origin, destination) {
  return {
    destination: destination,
    eta: '',
    incoterm: 'FOB',
    origin: origin,
    journey: [
      {
        legType: 'location',
        locationType: 'port',
        state: 'pending',
        label: origin,
        showLabel: true,
        exception: '',
      },
      {
        legType: 'transit',
        transitMode: 'ocean',
        state: 'pending',
        exception: '',
      },
      {
        legType: 'location',
        locationType: 'port',
        state: 'pending',
        label: destination,
        showLabel: true,
        exception: '',
      },
    ],
  };
}

export const emptyFields = ['deliveryType', 'customerRef', 'steamshipLine', 'vessel', 'voyage'].reduce((acc, field) => {
  acc[field] = '';
  return acc;
}, {});
