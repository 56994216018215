import BaseValidator from 'ember-cp-validations/validators/base';

const accountUserExists = function (account, user, email) {
  let usersToCheck = account.get('users').filter((item) => {
    return item.get('id') !== user.get('id') && !item.get('isNew');
  });
  return !!usersToCheck.findBy('email', email.toLowerCase());
};

const accountInviteExists = function (account, email) {
  return account.get('invites').find((invite) => {
    return invite.get('email') === email && !invite.get('isNew');
  });
};

export default class AccountUserExists extends BaseValidator {
  validate(email, options, model) {
    let account = model.get('account'),
      listType = options.listType,
      errorMessage = options.errorMessage;

    if (listType === 'users' && account && accountUserExists(account, model, email)) {
      return errorMessage.replace('EMAIL', email);
    } else if (model.get('isNew')) {
      if (listType === 'invites' && account && accountInviteExists(account, email)) {
        return errorMessage.replace('EMAIL', email);
      }
    }

    return true;
  }
}
