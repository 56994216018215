/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-computed-properties-in-native-classes */

import Service, { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { isEmpty, isPresent } from '@ember/utils';
import { dropTask } from 'ember-concurrency';
import RSVP from 'rsvp';
import currentSession from 'tnt-ui/decorators/current-session';

@currentSession
export default class StaticInformationService extends Service {
  @service store;

  steamshipLines = [];

  @computed
  get terminals() {
    return this.store.peekAll('terminal').toArray();
  }

  @computed
  get ports() {
    return this.store.peekAll('port').toArray();
  }

  loadSteamshipLines() {
    this.load.perform();
  }

  findSteamshipLine(prefix) {
    let steamshipLine = this.steamshipLines.findBy('bolPrefix', prefix);
    if (!isPresent(steamshipLine)) {
      steamshipLine = this.steamshipLines.findBy('scac', prefix);
    }
    return steamshipLine;
  }

  @dropTask load = function* () {
    if (isEmpty(this.steamshipLines)) {
      let list = yield this.store.findAll('steamship-line');
      this.set('steamshipLines', list);
    }
  };

  getSteamshipLines() {
    return new RSVP.Promise((resolve, reject) => {
      if (isEmpty(this.steamshipLines)) {
        return this.store.findAll('steamship-line').then((shiplines) => {
          this.set('steamshipLines', shiplines);
          resolve(this.steamshipLines);
        }, reject);
      } else {
        resolve(this.steamshipLines);
      }
    });
  }
}
