/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-computed-properties-in-native-classes */

import { action } from '@ember/object';
import { or } from '@ember/object/computed';
import { on } from '@ember-decorators/object';
import BaseInput from 'tnt-ui/components/forms/base-input';

export default class FormRadio extends BaseInput {
  _radioOptions = [
    { name: 'Yes', value: true },
    { name: 'No', value: false },
  ];

  @on('init')
  setUniqueId() {
    let uId = Math.random().toString(36).substring(7);
    this.set('uniqueId', uId);
  }
  @or('radioOptions', '_radioOptions') options;

  @action
  onChange(value) {
    const { model, prop } = this;
    this.setProperty(model, prop, value);
    (this.afterSet || (() => {}))(model, prop, value);
  }

  @action
  chooseRadio(value) {
    const { model, prop } = this;
    this.setProperty(model, prop, value);
    this.set('hasChosen', true);
    (this.afterSet || (() => {}))(model, prop, value);
  }
}
