import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  board_id: validator('presence', {
    presence: true,
    description: 'Board',
  }),
  details: validator('presence', {
    presence: true,
    description: 'Details',
  }),
});
