/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/no-classic-components, ember/no-component-lifecycle-hooks, ember/no-computed-properties-in-native-classes, ember/require-tagless-components */

import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { action, computed, defineProperty, get, set } from '@ember/object';
import { alias, and, equal, not, notEmpty, oneWay, or } from '@ember/object/computed';
import { isEmpty, isPresent, typeOf } from '@ember/utils';
import { classNameBindings, classNames } from '@ember-decorators/component';

@classNames('form-group', 'validated-input')
@classNameBindings('showError:has-danger', 'isValid:has-success', 'selectInput:select-input')
export default class VInput extends Component {
  @service store;

  isSmallInput = false;
  showLabel = false;
  model = null;
  value = null;
  type = 'text';
  valuePath = '';
  placeholder = '';
  validation = null;
  isTyping = false;
  showError = false;
  autoComplete = 'off';

  didReceiveAttrs() {
    super.didReceiveAttrs(...arguments);
    const valuePath = this.valuePath;
    defineProperty(this, 'validation', oneWay(`model.validations.attrs.${valuePath}`));
    if (isEmpty(valuePath)) {
      defineProperty(this, 'value', alias('model'));
    } else {
      defineProperty(this, 'value', alias(`model.${valuePath}`));
    }
  }

  @equal('type', 'select') selectInput;

  @not('validation.isValidating') notValidating;

  @oneWay('model.didValidate') didValidate;

  @notEmpty('value') hasContent;

  @and('hasContent', 'validation.isValid', 'notValidating') isValid;

  @oneWay('validation.isInvalid') isInvalid;

  @or('hasContent', 'didValidate') isDirty;

  @or('showError', 'didValidate') showMessage;

  @and('isInvalid', 'didValidate') showErrorClass;

  @or('leftInputGroup', 'rightInputGroup') hasInputGroup;

  @computed('selectData.[]', 'selectKeyPath', 'selectValuePath')
  get _selectData() {
    const keyPath = this.selectKeyPath,
      valuePath = this.selectValuePath;
    return this.selectData.map((item) => {
      if (typeOf(item) === 'object') {
        return { key: item[keyPath], value: item[valuePath] };
      } else {
        return { key: get(item, keyPath), value: get(item, valuePath) };
      }
    });
  }

  validateInput() {
    set(this, 'showError', this.isInvalid);
  }

  @action
  validate() {
    this.validateInput();
  }

  @action
  _selectAction(event) {
    let value = event.target.value;
    let actualValue = value;
    if (value === 'null') {
      actualValue = null;
    } else if (value.match('^<tnt-ui@model:')) {
      const regex = /model:([^:]+)::[^:]+:(.*)>/;
      const parts = regex.exec(value);
      actualValue = this.store.peekRecord(parts[1], parts[2]);
    }
    if (isPresent(this.valuePath)) {
      set(this.model, this.valuePath, actualValue);
    } else {
      set(this, 'model', actualValue);
    }
    if (this.selectAction) {
      this.selectAction(actualValue);
    }

    this.validateInput();
  }
}
