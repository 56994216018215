import Transform from '@ember-data/serializer/transform';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';

export default class TrackedArrayTransform extends Transform {
  deserialize(value: unknown): TrackedArray<TrackedObject | null> | null {
    if (!Array.isArray(value)) {
      return null;
    }

    return new TrackedArray(
      value.map((item) => {
        const isObject = typeof item === 'object' && !Array.isArray(item) && item !== null;
        return isObject ? new TrackedObject(item) : item;
      }),
    );
  }

  serialize(value: TrackedArray<unknown>) {
    return value; // Tracked arrays/objects are serializable just like regular arrays/objects
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    'tracked-array': TrackedArrayTransform;
  }
}
