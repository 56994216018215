import ENV from 'tnt-ui/config/environment';
import { FileUploadFailedError } from 'tnt-ui/utils/custom-errors';

export async function uploadFileToS3(file, options) {
  let baseUrl = ENV.APP.apiBase + '/signed_upload_url';
  let { folder } = options;
  let queryParams = new URLSearchParams({
    folder,
    name: file.name,
    type: file.type,
    size: file.size,
  });
  try {
    let apiResponse = await fetch(`${baseUrl}?${queryParams.toString()}`);
    let apiResponseJson = await apiResponse.json();

    let uploadUrl = `https://${apiResponseJson.bucket}.s3.amazonaws.com`;

    let response = await file.upload(uploadUrl, { data: apiResponseJson });
    let responseText = await response.text();
    let parser = new DOMParser();
    return parser.parseFromString(responseText, 'text/html');
  } catch {
    throw new FileUploadFailedError('Uploading a file to S3 failed.');
  }
}
