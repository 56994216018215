import BaseValidator from 'ember-cp-validations/validators/base';

export default class OriginDestination extends BaseValidator {
  validate(value, options, model) {
    if (model.get('duplicateOriginDestination')) {
      return 'Origin and destination must be different';
    }
    return true;
  }
}
