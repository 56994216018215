/* eslint-disable ember/classic-decorator-hooks, ember/classic-decorator-no-classic-methods, ember/no-computed-properties-in-native-classes */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { debug } from '@ember/debug';
import { reads } from '@ember/object/computed';
import { later } from '@ember/runloop';
import ENV from 'tnt-ui/config/environment';

export default class extends Controller {
  @service cable;

  @reads('consumer.isConnecting') isConnecting;
  @reads('consumer.nextConnectionAt') nextConnectionAt;
  chatLog = [];

  init() {
    super.init(...arguments);
    this.setupConsumer();
  }

  setupConsumer() {
    let consumer = this.cable.createConsumer(ENV.APP.SOCKET_HOST);
    let self = this;

    consumer.subscriptions.create('DevCheckChannel', {
      connected() {
        const MSG = { message: "let's play ping pong", sender: 'client' };
        this.perform('hello', MSG);
        self.chatLog.pushObject(MSG);
      },
      received(data) {
        self.chatLog.pushObject(data);
        later(
          this,
          function () {
            const MSG = { message: 'Ping', sender: 'client' };
            this.perform('chat', MSG);
            self.chatLog.pushObject(MSG);
          },
          2000,
        );
      },
      disconnected() {
        debug('NotificationChannel#disconnected');
      },
    });

    this.set('consumer', consumer);
  }
}
