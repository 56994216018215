/* eslint-disable ember/no-new-mixins */

import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';
import Mixin from '@ember/object/mixin';

export default Mixin.create({
  session: service(),
  currentUser: reads('session.currentUser'),
  currentAccount: reads('currentUser.currentAccount'),
  userIsAuthenticated: reads('session.isAuthenticated'),
});
