import { remapResourceTypes } from 'tnt-ui/utils/serialization';
import V2ApiSerializer from './v2-api';

export default class ShipmentV2Serializer extends V2ApiSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    let remappedPayload = remapResourceTypes(payload, {
      data: {
        type: 'shipment_v2',
      },
      included: {
        container: {
          relationships: {
            shipment: 'shipment_v2',
            pod_terminal: 'terminal_v2',
            pickup_facility: 'terminal_v2',
          },
        },
      },
    });

    return super.normalizeResponse(store, primaryModelClass, remappedPayload, id, requestType);
  }
}
