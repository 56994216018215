import { camelize, dasherize } from '@ember/string';
import ApplicationSerializer from './application';

// TODO: might be slimmer with fragment and is this needed?

export default class User extends ApplicationSerializer {
  serialize() {
    let json = super.serialize(...arguments);
    let attribute = 'notification-settings';
    let settings = json.data.attributes[attribute] || {};
    let newSettings = {};
    Object.keys(settings).forEach((key) => {
      newSettings[dasherize(key)] = settings[key];
    });
    json.data.attributes[attribute] = newSettings;
    return json;
  }

  normalize(typeClass, hash) {
    let attribute = 'notification-settings';
    let settings = hash.attributes[attribute] || {};
    let newSettings = {};
    Object.keys(settings).forEach((key) => {
      newSettings[camelize(key)] = settings[key];
    });
    hash.attributes[attribute] = newSettings;
    return super.normalize(...arguments);
  }

  normalizeResponse(_store, _primaryModelClass, payload, id, _requestType) {
    const dataPayload = Array.isArray(payload.data) ? payload.data : [payload.data];

    dataPayload.forEach((user) => {
      if ('relationships' in user) {
        user.relationships['user-cargo-mapping'] = {
          links: {
            related: `/v2/users/${id}/user_cargo_mapping`,
          },
        };
      }
    });

    return super.normalizeResponse(...arguments);
  }
}
