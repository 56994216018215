/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { all } from 'rsvp';

/**
 * @param {Boolean} userIsAdmin
 * @param {userIsOwner} userIsOwner
 * @param {ShipmentModel} shipment
 * @param {ContainerModel} [selectedContainer]
 * @param {Boolean} [showAppointments]
 * @param {Boolean} [showNumberField]
 * @param {Boolean} [reportIssue]
 * @param {Boolean} [showReportIssueButton]
 * @param {String} source
 */
export default class BookingDetailCargos extends Component {
  @service notify;
  @service metrics;
  @service store;

  bookingOrder;

  get newCargos() {
    return this.args.shipment.get('cargos').filterBy('isNew', true);
  }

  @task saveCargosTask = function* () {
    const cargos = this.args.shipment.get('cargos') ?? [];

    try {
      const validateCargos = yield all(cargos.map((cargo) => cargo.validate()));
      validateCargos.setEach('model.didValidate', true);
      const allCargoValid = validateCargos.isEvery('validations.isValid', true);
      if (allCargoValid) {
        yield all(cargos.map((cargo) => cargo.save()));
      }
    } catch (e) {
      this.notify.error(
        'Woops! Something went wrong while saving the information. ' + 'Our support team has been notified.',
      );
    }
  };

  @action
  addCargo() {
    const { shipment } = this.args;
    let cargoHash = {};
    const lastCargo = shipment.get('cargos.lastObject');
    if (lastCargo) {
      cargoHash = {
        containerLength: parseInt(lastCargo.get('containerLength')) || null,
        containerHeight: lastCargo.get('containerHeight'),
        containerType: lastCargo.get('containerType'),
        containerWeight: lastCargo.get('containerWeight'),
      };
    }
    let newCargo = this.store.createRecord('cargo', cargoHash);
    if (this.bookingOrder) {
      this.bookingOrder.cargos.pushObject(newCargo);
    }
    shipment.cargos.pushObject(newCargo);
  }
}
