/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-computed-properties-in-native-classes, ember/no-get, no-dupe-class-members, no-setter-return */

import { computed } from '@ember/object';
import { or } from '@ember/object/computed';
import { attribute, classNames, tagName } from '@ember-decorators/component';
import BaseInput from 'tnt-ui/components/forms/base-input';

@tagName('button')
@classNames('btn', 'save', 'mb-0')
export default class SubmitButton extends BaseInput {
  @attribute type = 'submit';
  @attribute disabled = false;

  classNameBindings = ['buttonClass', 'buttonColor'];

  @or('isSaving', 'isDisabled') disabled;

  // A param with default would be better but that opens up
  // a can of worms!
  @computed('buttonType')
  get buttonColor() {
    if (this.buttonType) {
      return this.buttonType;
    }
    return 'btn-success';
  }

  @computed('model.isNew', 'text')
  get buttonText() {
    if (this.text) {
      return this.text;
    }
    return this.get('model.isNew') ? 'Create' : 'Save';
  }
  set buttonText(text) {
    return text;
  }

  click() {
    this.submitForm(this.save);
  }
}
