import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type CredentialModel from './credential';

export default class IntegrationModel extends Model {
  @attr('string') declare name: string;
  @attr('string') declare title: string;
  @attr('string') declare loginUrl: string;
  @attr('string') declare logoUrl: string;
  @belongsTo('credential') declare credential: AsyncBelongsTo<CredentialModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    integration: IntegrationModel;
  }
}
