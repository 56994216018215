/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/no-computed-properties-in-native-classes, no-setter-return */

import { computed } from '@ember/object';
import BaseInput from 'tnt-ui/components/forms/base-input';

export default class BasicSelect extends BaseInput {
  @computed('name')
  get prompt() {
    return `Select ${this.name}`;
  }
  set prompt(text) {
    return text;
  }
}
