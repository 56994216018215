import { isEmpty } from '@ember/utils';
import BaseValidator from 'ember-cp-validations/validators/base';

export default class EmailOrPhone extends BaseValidator {
  validate(value, options, model) {
    let sendSMS = model.get(options.conditionalKey);
    let regex = {
      email:
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
      phone:
        /^([+]?1\s*[-/.]?\s*)?(\((\d{3})\)|(\d{3}))\s*[-/.]?\s*(\d{3})\s*[-/.]?\s*(\d{4})\s*(([xX]|[eE][xX][tT]?[.]?|extension)\s*([#*\d]+))*$/,
    };
    let valueType = sendSMS ? 'phone' : 'email';
    let shouldValidate = isEmpty(options.validateOnKey) || model.get(options.validateOnKey) === true;
    if (shouldValidate && !regex[valueType].test(value)) {
      return this.createErrorMessage(valueType, value, options);
    }
    return true;
  }
}
