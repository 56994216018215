import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { buildValidations, validator } from 'ember-cp-validations';

const VALIDATIONS = buildValidations({
  name: validator('ds-error'),
  sku: validator('ds-error'),
});

export default class ProductModel extends Model.extend(VALIDATIONS) {
  @belongsTo('account') account;
  @belongsTo('user') user;
  @hasMany('cargo-product') cargoProducts;

  @attr('string') name;
  @attr('string') description;
  @attr('string') sku;
  @attr('string') hsCode;
  @attr('date') createdAt;
}
