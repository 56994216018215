/* import __COLOCATED_TEMPLATE__ from './ais-banner.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export interface AisBannerSignature {
  Args: {};
  Blocks: {
    default: [];
  };
  Element: null;
}

export default class AisBannerComponent extends Component<AisBannerSignature> {
  @tracked isHidden: boolean = true;

  @action
  onHidden() {
    this.isHidden = true;
  }
}
