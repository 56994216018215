export const CARGO_STATUS_FILTERS = {
  'on-ship': ['new', 'on_ship'],
  'at-terminal': ['grounded', 'available', 'not_available', 'off_dock'],
  'picked-up': ['picked_up', 'dropped'],
  delivered: ['delivered', 'empty_returned'],
  all: [],
};

// TODO why is all, not really all the cargo status names
// which are => new loaded available not_available on_ship picked_up dropped delivered
export const CARGO_STATUS_TO_DISPLAY = Object.assign(CARGO_STATUS_FILTERS, {
  all: ['new', 'on_ship', 'available', 'not_available', 'picked_up', 'dropped', 'delivered', 'empty_returned'],
});

export const CARGO_STATUS_SORT_BY = {
  'on-ship': 'vessel_eta_on',
  'at-terminal': 'last_free_day_on',
  'picked-up': 'delivery_appointment_at',
  delivered: '-delivered_on',
  all: '-created_at',
};

export const BOOKING_ORDER_FILTER = {
  all: {},
  active: {
    archived: false,
    current_status: ['draft', 'submitted', 'in_progress', 'delivered'],
  },
  submitted: {
    current_status: 'submitted',
  },
  in_progress: {
    current_status: 'in_progress',
  },
  delivered: {
    current_status: 'delivered',
  },
  archived: {
    archived: true,
  },
  canceled: {
    current_status: 'canceled',
  },
  rejected: {
    current_status: 'rejected',
  },
};

export const SHIPMENT_FILTER = {
  on_ship: { tracking_stopped: false, voyage_status: 'on_ship' },
  arrived: { tracking_stopped: false, voyage_status: 'arrived' },
  tracking_stopped: { tracking_stopped: true },
  all: { tracking_stopped: false },
};
