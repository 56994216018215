import Service, { service } from '@ember/service';
import Evented from '@ember/object/evented';
import { later } from '@ember/runloop';
import { isPresent } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';
import ENV from 'tnt-ui/config/environment';

const { environment, pollingInterval, rootURL } = ENV;

export default class UpdateNotifyService extends Service.extend(Evented) {
  @service log;

  pollingInterval = pollingInterval || 600000; // 10 minutes by default

  constructor() {
    super(...arguments);

    if (!/development|test/.test(environment)) {
      later(
        this,
        () => {
          this.attachUpdateHandler();
          this.polling = this.pollingTask.perform();
        },
        1000,
      );
    }
  }

  async attachUpdateHandler() {
    if ('isUpdateAvailable' in window) {
      const updated = await window.isUpdateAvailable;

      if (updated) {
        this.trigger('updated');

        if (isPresent(this.polling)) {
          this.polling.cancel();
        }
      }
    }
  }

  async _registrationUpdate() {
    try {
      const registration = await navigator.serviceWorker.register(`${rootURL}sw.js`, {
        scope: rootURL,
      });

      registration.update();
    } catch {
      return;
    }
  }

  @task
  *pollingTask() {
    while (true) {
      yield this._registrationUpdate();
      yield timeout(this.pollingInterval);
    }
  }
}
