/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-classic-components, ember/no-computed-properties-in-native-classes, ember/require-computed-property-dependencies, ember/require-tagless-components */
import Component from '@ember/component';
import { action, computed } from '@ember/object';
import { not } from '@ember/object/computed';
import { dasherize } from '@ember/string';
import moment from 'moment-timezone';

export default class DatePickr extends Component {
  noLabel = false;
  enableDate = true;
  showErrors = false;
  updateCallback = false;
  placeholder = 'Set Date';
  timeZone = null;
  clearCallback = null;
  enableTime = false;
  disabled = false;

  @computed('prop')
  get workingDate() {
    let inputDate = this.model.get(this.prop);
    return this.deserialize(inputDate);
  }

  @not('enableDate') noCalendar;

  @computed('prop')
  get dasherizedProp() {
    return dasherize(this.prop);
  }

  @action
  triggerUpdate(params) {
    if (this.updateCallback) {
      this.updateCallback(this.prop, this.serialize(params[0]));
    } else {
      this.model.set(this.prop, this.serialize(params[0]));
    }
    this.notifyPropertyChange('prop');
  }

  @action
  triggerClear() {
    if (this.clearCallback) {
      this.clearCallback();
    } else {
      this.model.set(this.prop, null);
    }
    this.notifyPropertyChange('prop');
  }

  serialize = (datetime) => {
    if (!datetime) {
      return null;
    }
    if (!this.timeZone) {
      return datetime;
    }
    return moment.tz(moment(datetime).format().substring(0, 19), this.timeZone).toDate();
  };

  deserialize = (datetime) => {
    if (!datetime) {
      return null;
    }
    if (!this.timeZone) {
      return datetime;
    }
    return moment(moment.tz(datetime, this.timeZone).format().substring(0, 19)).toDate();
  };
}
