import type RouterService from '@ember/routing/router-service';
import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class GlobalMapController extends Controller {
  @service declare router: RouterService;

  queryParams = ['vessel_id', 'shipment_id'];

  @tracked vessel_id: null | string = null;
  @tracked shipment_id: null | string = null;

  didChangeSelection = ({ vesselId, shipmentId }: { vesselId: string | null; shipmentId: string | null }) => {
    this.router.transitionTo('global-map', {
      queryParams: {
        vessel_id: vesselId,
        shipment_id: shipmentId,
      },
    });
  };
}
