/* eslint-disable ember/no-classic-classes, ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import moment from 'moment-timezone';

export default DS.Transform.extend({
  deserialize(serialized) {
    if (!serialized) {
      return serialized;
    }

    const m = moment(serialized, 'YYYY-MM-DD', true);
    if (m.isValid() == false) {
      return null;
    }
    return m.toDate();
  },

  serialize(deserialized) {
    return DS.DateTransform.create().serialize(deserialized);
  },
});
