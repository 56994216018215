type RemapResourceTypesOptions = {
  data?: {
    type?: string;
    relationships?: {
      [key: string]: string;
    };
  };
  included?: {
    [key: string]: {
      type?: string;
      relationships?: {
        [key: string]: string;
      };
    };
  };
};

export function remapResourceTypes(payload: JSONAPIPayload, options: RemapResourceTypesOptions) {
  const primaryResourceType = options.data?.type;
  const primaryResourceRelationshipMap = options.data?.relationships;
  const includedResourceOptions = options.included;

  if (primaryResourceType) {
    // data.type
    forEachDataObject(payload.data, (dataObject) => {
      dataObject.type = primaryResourceType;
    });
  }

  if (primaryResourceRelationshipMap) {
    // data.relationships
    forEachDataObject(payload.data, (dataObject) => {
      const { relationships } = dataObject;
      if (!relationships) {
        return;
      }

      const primaryResourceRelationshipNames = Object.keys(primaryResourceRelationshipMap);
      const relationshipNames = Object.keys(relationships).filter((relationshipName) =>
        primaryResourceRelationshipNames.includes(relationshipName),
      );

      // data.relationships.*
      relationshipNames.forEach((relationshipName) => {
        // data.relationships.*.data.type
        forEachDataObject(
          relationships[relationshipName]?.data as JSONAPIResourceData | JSONAPIResourceData[],
          (relationshipData) => {
            relationshipData.type = primaryResourceRelationshipMap[relationshipName] as string;
          },
        );
      });
    });
  }

  if (includedResourceOptions) {
    // included.*
    payload.included?.forEach((includedResource) => {
      const resourceOptions = includedResourceOptions[includedResource.type];
      if (!resourceOptions) {
        return;
      }

      // included.*.type
      const targetType = resourceOptions.type;
      if (targetType) {
        includedResource.type = targetType;
      }

      // included.*.relationships
      const { relationships } = includedResource;
      const includedRelationshipMap = resourceOptions.relationships;
      if (relationships && includedRelationshipMap) {
        const includedRelationshipMapNames = Object.keys(includedRelationshipMap);
        const relationshipNames = Object.keys(relationships).filter((relationshipName) =>
          includedRelationshipMapNames.includes(relationshipName),
        );

        // included.*.data.relationships.*
        relationshipNames.forEach((relationshipName) => {
          // data.relationships.*.data.type
          forEachDataObject(
            relationships[relationshipName]!.data as JSONAPIResourceData | JSONAPIResourceData[],
            (relationshipData) => {
              relationshipData.type = includedRelationshipMap[relationshipName] as string;
            },
          );
        });
      }
    });
  }

  return payload;
}

function forEachDataObject(
  dataObject: null | JSONAPIResourceData | JSONAPIResourceData[],
  callback: (dataObject: JSONAPIResourceData) => void,
) {
  if (!dataObject) {
    return; // Data object is `null`
  }

  if (Array.isArray(dataObject)) {
    dataObject.forEach(callback);
  } else {
    callback(dataObject);
  }
}

type JSONAPIPayload = {
  data: JSONAPIResourceData | JSONAPIResourceData[];
  included?: JSONAPIResourceData[];
  [key: string]: unknown;
};

type JSONAPIResourceData = {
  type: string;
  relationships?: {
    [key: string]: {
      data: null | JSONAPIRelationshipData | JSONAPIRelationshipData[];
    };
  };
  [key: string]: unknown;
};

type JSONAPIRelationshipData = {
  type: string;
  [key: string]: unknown;
};
