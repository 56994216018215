/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/no-classic-components, ember/no-computed-properties-in-native-classes, ember/require-tagless-components */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { equal, or, reads } from '@ember/object/computed';
import currentSession from 'tnt-ui/decorators/current-session';
import {
  Available,
  Delivered,
  Dropped,
  Loaded,
  New,
  NotAvailable,
  OffDock,
  OnShip,
  PickedUp,
} from 'tnt-ui/models/cargo';

export const STATUS_TEXTS = {
  [New]: 'No Status',
  [OnShip]: 'On Ship',
  [OffDock]: 'Available at Shippers',
  [NotAvailable]: 'Not available',
  [Available]: 'Available',
  [PickedUp]: 'Picked up',
  [Delivered]: 'Delivered',
  [Dropped]: 'Dropped',
  [Loaded]: 'Loaded',
};

export const STATUS_LABEL_CLASS = {
  [New]: 'badge-default',
  [OnShip]: 'badge-warning',
  [NotAvailable]: 'badge-danger',
  [OffDock]: 'badge-primary',
  [Available]: 'badge-primary',
  [PickedUp]: 'badge-primary',
  [Delivered]: 'badge-success',
  [Dropped]: 'badge-success',
  [Loaded]: 'badge-primary',
};

export const SHOW_LFD_STATES = [New, OnShip, Available, NotAvailable, OffDock, PickedUp];

@currentSession
export default class BookingCargoListItem extends Component {
  @service session;
  @service fetch;
  @service notify;

  classNameBindings = ['editMode:editing', 'cssNames'];
  isEditing = false;
  showDetails = false;

  @reads('cargo.isNew') isNewCargo;
  @reads('cargo.currentStatus') currentStatus;
  @equal('cargo.bookingType', 'export') isExportBooking;
  @equal('cargo.bookingType', 'import') isImportBooking;
  @or('isEditing', 'isNewCargo') editMode;
  // @or('showPickUpAppointment', 'showDeliveryAppointment') showAppointments

  @computed('currentStatus')
  get showLFD() {
    return SHOW_LFD_STATES.includes(this.currentStatus);
  }

  @computed('currentStatus')
  get statusClass() {
    return STATUS_LABEL_CLASS[this.currentStatus] || 'badge-default';
  }

  @computed('currentStatus')
  get statusText() {
    return STATUS_TEXTS[this.currentStatus] || this.currentStatus;
  }

  @computed('currentStatus', 'isImportBooking')
  get showPickUpAppointment() {
    const cargoStates = this.isImportBooking ? [New, OnShip, Available, NotAvailable, OffDock] : [New];
    return cargoStates.includes(this.currentStatus);
  }

  @computed('currentStatus', 'isImportBooking')
  get showDeliveryAppointment() {
    let cargoStates;
    if (this.isImportBooking) {
      cargoStates = [New, OnShip, Available, NotAvailable, OffDock, PickedUp];
    } else {
      cargoStates = [New, PickedUp, Loaded, Delivered];
    }
    return cargoStates.includes(this.currentStatus);
  }
}
