/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { run } from '@ember/runloop';
import ENV from 'tnt-ui/config/environment';

export default class ChangelogWidgetButtonComponent extends Component {
  @action
  onDidInsert() {
    run(() => {
      if (ENV.APP.CANNY_APP_ID && window.Canny) {
        window.Canny('initChangelog', {
          appID: ENV.APP.CANNY_APP_ID,
          position: 'bottom',
          align: 'right',
        });
      }
    });
  }
}
