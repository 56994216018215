import { buildValidations, validator } from 'ember-cp-validations';

export default buildValidations({
  refNumber: {
    validators: [
      validator('presence', {
        presence: true,
        description: 'This field',
      }),
      validator('ds-error'),
    ],
  },
  origin: {
    validators: [
      validator('presence', {
        presence: true,
        dependentKeys: ['model.isImport'],
        message: 'Please select an origin',
      }),
      validator('ds-error'), // TODO seems useless
    ],
  },
  destination: {
    validators: [
      validator('presence', {
        presence: true,
        message: 'Please select a destination',
      }),
      validator('ds-error'), // TODO seems useless
    ],
  },
});
