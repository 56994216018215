/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable ember/no-computed-properties-in-native-classes */

import { computed } from '@ember/object';
import TextInput from 'tnt-ui/components/forms/text-input';

const Icons = { phone: 'fa-phone', email: 'fa-envelope-o' };

export default class IconInput extends TextInput {
  @computed('prop')
  get faClassName() {
    return Icons[this.prop];
  }
}
