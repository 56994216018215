/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';
import { FileUploadFailedError } from 'tnt-ui/utils/custom-errors';
import { uploadFileToS3 } from 'tnt-ui/utils/upload-to-s3';

export default class NewDocumentComponent extends Component {
  @service notify;
  @service store;

  relationName = 'documents';
  @tracked fileQueue = null;

  get category() {
    return this.args.category ?? null;
  }

  get packingListFileTypes() {
    return this.args.packingListFileTypes ?? '';
  }

  get showNotification() {
    return this.args.showNotification ?? true;
  }

  get percentUploaded() {
    return this.fileQueue ? this.fileQueue.progress : 0;
  }

  get progressBarStyle() {
    return htmlSafe('width: ' + this.percentUploaded + '%');
  }

  get acceptAttribute() {
    let baseType = 'image/*,application/pdf';
    if (this.category == 'packing_list') {
      return baseType + this.packingListFileTypes;
    }
    return baseType;
  }

  async saveDocument(name, size, type, path) {
    let document = this.store.createRecord('document', {
      category: this.category,
      attachable: this.args.attachable,
      fileName: name,
      fileSize: size,
      path: path,
      fileType: type,
    });

    let model = await document.save();
    this.args.attachable.get(this.relationName).pushObject(model);
    if (this.showNotification) {
      this.notify.success('Document successfully uploaded!');
    }
    if (this.args.afterUpload) {
      this.args.afterUpload(model);
    }
  }
  @action
  showAlert() {
    window.alert('Please select a category before uploading a document!');
  }

  @action
  async upload(file) {
    try {
      this.fileQueue = file;
      let responseXML = await uploadFileToS3(file, { folder: 'documents' });
      const s3Path = responseXML.querySelector('Key').textContent;
      const { size, name, type } = file;
      await this.saveDocument(name, size, type, s3Path);
    } catch (error) {
      if (error instanceof FileUploadFailedError) {
        this.notify.error('There was an issue while uploading your document. Please check your internet connection.');
      } else {
        this.notify.error('Unable to save document.');
      }
    } finally {
      this.fileQueue = null;
    }
  }
}
