import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import currentSession from 'tnt-ui/decorators/current-session';

@currentSession
export default class ActivityRoute extends Route {
  @service store;

  model() {
    return this.store.findAll('activity');
  }

  @action
  updateUserActivityFlag() {
    let user = this.session.currentUser;
    user.set('activitiesLastSeenAt', new Date());
    user.save();
  }
}
