import Model, { attr, belongsTo } from '@ember-data/model';
import type UserModel from 'tnt-ui/models/user';

export default class UserCargoMappingModel extends Model {
  @belongsTo('user') declare user: UserModel;

  @attr declare customerIds: string[];
  @attr declare tagsAnd: string | boolean;
  @attr declare tagIds: string[];
  @attr declare portOfDischargeIds: string[];
  @attr declare containersDashboardFilter: string | undefined;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-cargo-mapping': UserCargoMappingModel;
  }
}
