import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import Validations from '../utils/validations/contract';

export default class ContractModel extends Model.extend(Validations) {
  @belongsTo('billing-account') billingAccount;
  @hasMany('contract-item', { async: false }) contractItems;

  @attr('string') subscriptionPlan;
  @attr('string') licensedContainers;
  @attr('string') status;

  @attr('string') contractLength;
  @attr('string') billingPeriod;

  @attr('date') startDate;
  @attr('date') endDate;

  @attr('number') expectedContainersAnnual;
  @attr('number') expectedDashboardSeats;

  @attr('boolean') autoRenew;

  @attr('date') createdAt;
  @attr('date') finalizedAat;

  //Mandatory Contract Items:

  get containerPrebilledContractItem() {
    return this.contractItems.findBy('namespace', 'container_prebilled');
  }

  get containerOverageContractItem() {
    return this.contractItems.findBy('namespace', 'container_overage');
  }

  get dashboardUsersContractItem() {
    return this.contractItems.findBy('namespace', 'dashboard_users');
  }

  //Optional Contract Items:

  get widgetContractItem() {
    return this.contractItems.findBy('namespace', 'widget');
  }

  get premiumSupportContractItem() {
    return this.contractItems.findBy('namespace', 'premium_support');
  }
}
