import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import type FeatureFlagsService from 'tnt-ui/services/feature-flags';

type Positional = [string];

export interface IsFeatureEnabledSignature {
  Args: {
    Positional: Positional;
  };
  Return: boolean;
}

export default class IsFeatureEnabledHelper extends Helper<IsFeatureEnabledSignature> {
  @service declare featureFlags: FeatureFlagsService;

  public compute([feature]: Positional) {
    return this.featureFlags.isEnabled(feature);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'is-feature-enabled': typeof IsFeatureEnabledHelper;
  }
}
