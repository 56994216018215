import Model, { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';

export default class VesselV2Model extends Model {
  @attr('string') declare name: string;
  @attr('string') declare imo: string;
  @attr('string') declare mmsi: string;
  @attr('string') declare positionTimestamp: string;
  @attr('number') declare latitude: number;
  @attr('number') declare longitude: number;
  @attr('number') declare nauticalSpeedKnots: number;
  @attr('number') declare navigationalHeadingDegrees: number;

  get hasCoordinates() {
    return isPresent(this.latitude) && isPresent(this.longitude);
  }

  get coordinates(): [number, number] {
    return [this.latitude, this.longitude];
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'vessel-v2': VesselV2Model;
  }
}
