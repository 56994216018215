import { buildValidations, validator } from 'ember-cp-validations';

const userExistsMessage = 'EMAIL already joined your account!';
const userInvitedError = 'EMAIL was already invited to your account!';

export default buildValidations({
  name: validator('presence', {
    presence: true,
    ignoreBlank: true,
    description: 'Name',
  }),
  email: {
    validators: [
      validator('presence', { presence: true, description: 'Email' }),
      validator('format', { type: 'email' }),
      validator('account-user-exists', {
        listType: 'users',
        errorMessage: userExistsMessage,
      }),
      validator('account-user-exists', {
        listType: 'invites',
        errorMessage: userInvitedError,
      }),
    ],
  },
});
