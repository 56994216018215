/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-computed-properties-in-native-classes, ember/no-get */

import Service, { inject as service } from '@ember/service';
import { debug } from '@ember/debug';
import { oneWay } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import ENV from 'tnt-ui/config/environment';

export default class AppSocketService extends Service {
  @service('cable') cableService;

  @oneWay('socket.connection.connected') isConnected;

  socket = null;

  setupSocket(authToken = null, reconnect = false) {
    if (!ENV.APP.initWebSocket) {
      debug('Web sockets are disabled. \nSet ENV.APP.initWebSocket to true to enable sockets');
      return false;
    }
    if (isEmpty(this.socket)) {
      let newSocket = this.cableService.createConsumer(this._connectionUrl(authToken));
      this.set('socket', newSocket);
    } else {
      if (reconnect) {
        this._reconnect(authToken);
      }
    }
    return this.socket;
  }

  _reconnect(authToken) {
    let connection = this.get('socket.connection');
    if (connection) {
      connection.set('consumer.url', this._connectionUrl(authToken));
      connection.reopen();
    }
  }

  _connectionUrl(token = null) {
    let url = ENV.APP.SOCKET_HOST;
    if (!isEmpty(token)) {
      url = `${url}?token=${token}`;
    }
    return url;
  }
}
