/* eslint-disable ember/use-ember-data-rfc-395-imports */
import DS from 'ember-data';
import { underscore } from '@ember/string';

export default class V2ApiSerializer extends DS.JSONAPISerializer {
  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(key) {
    return underscore(key);
  }

  normalizeResponseV2(_store, _primaryModelClass, payload, _id, _requestType) {
    let dataPayload = Array.isArray(payload.data) ? payload.data : [payload.data];

    if (!dataPayload?.[0]?.type) {
      // return early without any transformations if the shape isn't as expected
      return super.normalizeResponse(...arguments);
    }

    dataPayload.forEach((model) => {
      if (!model.type.includes('-v2')) {
        // ember-data client expects the type to match a model nam e.g. 'shipment-v2'
        model.type = `${model.type}-v2`;
      }
    });

    return super.normalizeResponse(...arguments);
  }
}
