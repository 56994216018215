/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable prettier/prettier */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { isEqual, isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
// @ts-ignore
import { CONTAINER_LENGTHS, CONTAINER_TYPES, IMPORT_CONTAINER_HEIGHTS } from 'tnt-ui/models/cntnr';

import type MetricsService from 'ember-metrics/services/metrics';
import type NotifyService from 'ember-notify/services/notify';
import type ShipmentModel from 'tnt-ui/models/shipment';
import type CargoModel from 'tnt-ui/models/cargo';
import type BookingOrderModel from 'tnt-ui/models/booking-order';

interface CargoFormSignature {
  Element: HTMLDivElement;
  Args: {
    model: CargoModel;
    shipment?: ShipmentModel;
    bookingOrder?: BookingOrderModel;
    showNumberField?: boolean;
    isNewCargo?: boolean;
    bookingType?: string;
    showAppointments?: boolean;
    canChangeStatus?: boolean;
    canDeleteCargo?: boolean;
    closeEditingMode: () => void;
  };
}

export default class CargoFormComponent extends Component<CargoFormSignature> {
  @service declare metrics: MetricsService;
  @service declare crud: any;
  @service declare notify: NotifyService;

  @tracked isValid = false;
  @tracked showAlert = false;
  @tracked showDeleteModal = false;

  get showNumberField() {
    return this.args.showNumberField ?? true;
  }

  get bookingType() {
    return this.args.bookingType ?? 'import';
  }

  get showAppointments() {
    return this.args.showAppointments ?? false;
  }

  get canChangeStatus() {
    return this.args.canChangeStatus ?? false;
  }

  get canDeleteCargo() {
    return this.args.canDeleteCargo ?? false;
  }

  get isNewCargo() {
    return this.args.isNewCargo ?? false;
  }

  CONTAINER_LENGTHS = CONTAINER_LENGTHS;
  CONTAINER_TYPES = CONTAINER_TYPES;
  IMPORT_CONTAINER_HEIGHTS = IMPORT_CONTAINER_HEIGHTS;

  EXPORT_CONTAINER_HEIGHTS = [
    { v: 'Standard', k: 'standard' },
    { v: 'High Cube', k: 'high_cube' },
    { v: 'Flex', k: 'flexible' },
  ];

  IMPORT_CONTAINER_STATUS = [
    { v: 'On Ship', k: 'on_ship' },
    { v: 'Not Available', k: 'not_available' },
    { v: 'Available', k: 'available' },
    { v: 'Available at Shippers', k: 'off_dock' },
    { v: 'Picked Up', k: 'picked_up' },
    { v: 'Dropped', k: 'dropped' },
    { v: 'Delivered', k: 'delivered' },
  ];

  EXPORT_CONTAINER_STATUS = [
    { v: 'Picked Up', k: 'picked_up' },
    { v: 'Loaded', k: 'loaded' },
    { v: 'Delivered', k: 'delivered' },
  ];

  get isExportBooking() {
    return isEqual(this.bookingType, 'export');
  }

  get isImportBooking() {
    return isEqual(this.bookingType, 'import');
  }

  get newRecord() {
    return this.args.model.isNew;
  }

  get productNames() {
    // @ts-ignore
    return (this.args.model.get('bookingOrder.creator.products') || []).map(({ name }) => name);
  }

  get selectedProducts() {
    // @ts-ignore
    return this.args.model.get('cargoProducts').map(({ product }) => product);
  }

  get showLabels() {
    // @ts-ignore
    return !(this.args.showOtherCargoFields === false && this.args.index > 0);
  }

  get pickupAppointmentLabel() {
    return this.isImportBooking ? 'Terminal Appointment' : 'Pick-up Appointment';
  }

  get dropoffAppointmentLabel() {
    return this.isExportBooking ? 'Terminal Appointment' : 'Delivery Appointment';
  }

  get selectableTerminals() {
    // @ts-ignore
    return (this.args.model.get('shipment.portOfDischarge.terminals') || []).slice();
  }

  @task
  *saveChangesTask() {
    const { model } = this.args;
    try {
      yield this._validateCargo();
      // @ts-ignore
      yield model.validate();

      // @ts-ignore
      if (model.get('validations.isValid') && this._isUniqueNumber()) {
        if (isPresent(model.number)) {
          this.args.model.set('number', model.get('number').toUpperCase());
        }
        yield model.save();
        this.args.closeEditingMode();
        this.metrics.trackEvent({
          event: 'Cargo Edited',
          // @ts-ignore
          booking: model.get('shipment.number'),
        });
      } else {
        return false;
      }
    } catch (e) {
      this.notify.error(
        'Woops! Something went wrong while saving the information. Our support team has been notified.',
      );
    }
  }

  async _validateCargo() {
    const { model } = this.args;
    this.isValid = false;

    // @ts-ignore
    await model.validate();
    // @ts-ignore
    model.set('didValidate', true);

    // @ts-ignore
    if (model.get('validations.isValid')) {
      if (this._isUniqueNumber()) {
        this.showAlert = false;
        this.isValid = true;
        return true;
      } else {
        this.showAlert = true;
        this.notify.error('The container number you entered already exists in your order!');
        return false;
      }
    } else {
      this.showAlert = true;
      return false;
    }
  }

  _isUniqueNumber() {
    if (isPresent(this.args.model.number)) {
      const cargosWithNumber = this.args.shipment?.get('cargos').filterBy('number', this.args.model.number);
      return cargosWithNumber?.get('length') === 1;
    } else {
      return true;
    }
  }

  async _deleteCargo() {
    if (this.args.bookingOrder) {
      // @ts-ignore
      this.args.bookingOrder.get('cargos').removeObject(this.args.model);
    }
    this.args.shipment?.get('cargos').removeObject(this.args.model);
    await this.args.model.destroyRecord();
  }

  _setAppointmentAtDate(timeAttr: keyof CargoModel, onDate: Date) {
    const atDate = this.args.model.get(timeAttr);
    if (atDate) {
      // Setting the time on the onDate so javascript datetime object
      // does not get confused setting an invalid month or days
      onDate.setHours(atDate.getHours());
      onDate.setMinutes(atDate.getMinutes());
      this.args.model.set(timeAttr, onDate);
    }
  }

  @action
  addProducts(newSelectedProducts: any[]) {
    const addProducts = newSelectedProducts.filter((product) => !this.selectedProducts.includes(product));
    addProducts.forEach((product) => {
      this.crud
        .newRecord('cargo-product', {
          cargo: this.args.model,
          product: product,
        })
        .save();
    });

    const removeProducts = this.selectedProducts.filter((product: any) => !newSelectedProducts.includes(product));
    this.args.model.cargoProducts
      .filter((selected: any) => removeProducts.includes(selected.product))
      .forEach((cargoProduct: any) => cargoProduct.destroyRecord());
  }

  @action
  removeCargo() {
    this._deleteCargo();
  }

  @action
  cancel() {
    this.args.model.rollbackAttributes();
    this.args.closeEditingMode();
  }

  @action
  toggleDeleteModal() {
    this.showDeleteModal = !this.showDeleteModal;
  }

  @action
  clearAppointment(appointmentAttr: keyof CargoModel, timeAttr = null) {
    this.args.model.set(appointmentAttr, null);
    if (timeAttr != null) {
      this.args.model.set(timeAttr, null);
    }
  }

  @action
  updateAppointmentOn(dateAttr: keyof CargoModel, value: Date) {
    const onDate = value;

    this.args.model.set(dateAttr, onDate);

    const timeAttr: keyof CargoModel = dateAttr.includes('pickup') ? 'pickupAppointmentAt' : 'deliveryAppointmentAt';
    if (onDate == undefined) {
      this.args.model.set(dateAttr, null);
      this.args.model.set(timeAttr, null);
      return false;
    }
    // we have changed the date, so update appointmentAt if it is present
    this._setAppointmentAtDate(timeAttr, onDate);
  }

  // Convert appointment time to correct time zone
  @action
  updateAppointmentAt(timeAttr: keyof CargoModel, value: Date) {
    const model = this.args.model;
    const dateAttr: keyof CargoModel = timeAttr.includes('pickup') ? 'pickupAppointmentOn' : 'deliveryAppointmentOn';
    const onDate = model.get(dateAttr);

    model.set(timeAttr, value);

    // make sure the date matches appointmentOn (appointmentAt datepickr will
    // default to today's date before a time is set)
    this._setAppointmentAtDate(timeAttr, onDate);
  }
}
