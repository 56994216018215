/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { filter, notEmpty } from '@ember/object/computed';
import { usedAs } from 'tnt-ui/utils/decorators';

@usedAs(['notable'])
export default class ShipmentModelV2 extends Model {
  @belongsTo('port', { async: true }) portOfLading;
  @belongsTo('port', { async: true }) portOfDischarge;
  @belongsTo('port', { async: false }) destination;
  @belongsTo('terminal', { async: false }) destinationTerminal;
  @belongsTo('terminal', { async: false }) podTerminal;
  @belongsTo('shipping-line', { async: false }) shippingLine;
  @hasMany('container', { async: true }) containers;
  @hasMany('booking-order') bookingOrders;
  @belongsTo('voyage', { async: false }) voyage;
  @belongsTo('user', { async: false }) lineTrackingStoppedByUser;

  @attr('string') normalizedNumber;
  @attr('string') billOfLadingNumber;
  @attr('date') createdAt;
  @attr('string') podTimezone;
  @attr('string') polTimezone;
  @attr('string') destinationName;
  @attr('string') destinationLocode;
  @attr('string') destinationTimezone;
  @attr('string') portOfDischargeName;
  @attr('string') portOfLadingName;
  @attr('string') portOfLadingLocode;
  @attr('string') portOfDischargeLocode;
  @attr('array') refNumbers;
  @attr('string') shippingLineName;
  @attr('string') shippingLineShortName;
  @attr('string') shippingLineScac;
  @attr('string') podVesselName;
  @attr('string') podVesselImo;
  @attr('string') podVoyageNumber;
  @attr('string') customerName;
  @attr('date') podAtaAt;
  @attr('date') podEtaAt;
  @attr('date') polAtdAt;
  @attr('date') polEtdAt;
  @attr('date') podOriginalEtaAt;
  @attr('date') destinationAtaAt;
  @attr('date') destinationEtaAt;
  @attr('array') tags;

  @attr('date') lineTrackingStoppedAt;
  @attr('string') lineTrackingStoppedReason;
  @attr('string') lineTrackingLastSucceededAt;

  @filter('containers', (c) => c.hasActualContainerEvents) containerWithActualContainerEvents;

  @notEmpty('containerWithActualContainerEvents') hasContainerWithActualContainerEvents;

  get vesselAndVoyage() {
    return `${this.podVesselName} / ${this.podVoyageNumber}`;
  }

  get ref() {
    if (this.refNumbers) {
      return `${this.refNumbers} (${this.normalizedNumber})`;
    }

    return this.normalizedNumber;
  }

  get hasInlandDestination() {
    return Boolean(this.destination);
  }
}
