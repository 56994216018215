/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-computed-properties-in-native-classes, ember/no-get */
import EmberObject, { computed } from '@ember/object';

export default class TerminalPresenter extends EmberObject {
  subtitle = 'Oakland, CA';

  @computed('model.nickname')
  get title() {
    return `${this.get('model.nickname')} Terminal`;
  }
}
