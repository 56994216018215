/* eslint-disable ember/classic-decorator-no-classic-methods, ember/no-get */
import { inject as service } from '@ember/service';
import BaseValidator from 'ember-cp-validations/validators/base';

export default class IsAdmin extends BaseValidator {
  @service session;

  validate(value, options) {
    const isAdmin = this.get('session.currentUser.admin');
    if (isAdmin) {
      return true;
    } else {
      if (options.description === 'Zip') {
        if (value) {
          return value.toString().length === 5 || 'Zip code must be 5 digits';
        }
      }
      if (value === null) {
        return `${options.description} can't be blank`;
      }
      return true;
    }
  }
}
