import { classNames, tagName } from '@ember-decorators/component';
import hbs from 'htmlbars-inline-precompile';
import BaseInput from 'tnt-ui/components/forms/base-input';

@tagName('button')
@classNames('btn', 'cancel', 'btn-outline-danger')
export default class CancelButton extends BaseInput {
  classNameBindings = ['buttonClass'];
  layout = hbs`Cancel`;

  click() {
    this.cancel();
  }
}
