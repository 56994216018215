/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
/* eslint-disable prettier/prettier */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from 'tnt-ui/config/environment';

import type RouteModel from 'tnt-ui/models/route';
import type SessionService from 'tnt-ui/services/session';
import type { MapDataLoader, Universe, Shipment } from 'tnt-map';

interface ContainersMapDebugInfoSignature {
  Element: null;
  Args: {
    shipment: Shipment;
    containerId: string;
    universe?: Universe | undefined;
    route: RouteModel;
    mapDataLoader: MapDataLoader;
    isShown: boolean;
  };
}

export default class ContainersMapDebugInfoComponent extends Component<ContainersMapDebugInfoSignature> {
  @service declare session: SessionService;
  @service declare log: any;

  @tracked recentIssuesDashboard: string | undefined;
  @tracked isShown = this.args.isShown ?? false;

  toggleVisibility = () => {
    this.isShown = !this.isShown;
  };

  getDashboards = async (containerId: string) => {
    if (!containerId) {
      return;
    }

    try {
      const { token } = await this.getSignedJwt({
        container_id: containerId,
        number: 316,
      });
      if (token) {
        this.recentIssuesDashboard = `https://bi.t49.co/embed/dashboard/${token}#titled=true`;
      } else {
        throw new Error("Couldn't get metabase info.");
      }
    } catch (error) {
      this.log.error(error);
    }
  };

  printDebugInfo = () => {
    console.log('MapDataLoader Data');
    console.log(this.args.mapDataLoader.data);
    if (this.args.universe) {
      console.log('Worlds');
      console.table({
        eastWorld: this.args.universe.eastWorld,
        mainWorld: this.args.universe.mainWorld,
        westWorld: this.args.universe.westWorld,
      });
      console.log('Universe');
      console.log(this.args.universe);
    }
  };

  private async getSignedJwt(body: any): Promise<{ token: string | null }> {
    const signedInAs = localStorage.getItem('signedInAs');
    let signedInAsJson = null;

    if (signedInAs) {
      signedInAsJson = JSON.parse(signedInAs);
    }

    const access_token = signedInAsJson?.adminAccessToken || this.session.data.authenticated.access_token;
    const accountId = signedInAsJson?.adminAccountId || this.session.data.current_account_id;

    const response = await fetch(`${ENV.APP.apiHost}/v2/metabase/sign_jwt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${access_token}`,
        'X-Account-ID': accountId,
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const json = response.json();

      return json;
    } else {
      return { token: null };
    }
  }
}
